import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
};

export type AuthSession = {
  __typename?: 'AuthSession';
  User: User;
  accessToken: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type Author = {
  __typename?: 'Author';
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
};

export type Character = {
  __typename?: 'Character';
  ImageS3File?: Maybe<S3File>;
  User?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  gender: GenderEnum;
  id: Scalars['ID'];
  isPublic: Scalars['Boolean'];
  metaData: Scalars['String'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CharacterEmbed = {
  __typename?: 'CharacterEmbed';
  Character: Character;
  User?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  metaData: Scalars['String'];
  numOfConversations: Scalars['Int'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CharacterEmbedInput = {
  Character: CharacterInput;
  metaData: Scalars['String'];
  openAIToken?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  userId: Scalars['ID'];
};

export type CharacterEmbedsFilterInput = {
  userId: Scalars['ID'];
};

export type CharacterEmbedsWithPagination = {
  __typename?: 'CharacterEmbedsWithPagination';
  characterEmbeds: Array<CharacterEmbed>;
  pagination: Pagination;
};

export type CharacterImageInput = {
  isPublic: Scalars['Boolean'];
  s3FileKey: Scalars['String'];
  userId: Scalars['ID'];
};

export type CharacterImagesFilterInput = {
  userIds: Array<InputMaybe<Scalars['ID']>>;
};

export type CharacterImagesWithPagination = {
  __typename?: 'CharacterImagesWithPagination';
  characterImages: Array<S3File>;
  pagination: Pagination;
};

export type CharacterInput = {
  gender: GenderEnum;
  imageS3FileKey?: InputMaybe<Scalars['String']>;
  isPublic: Scalars['Boolean'];
  isVisible: Scalars['Boolean'];
  metaData: Scalars['String'];
  title: Scalars['String'];
  userId?: InputMaybe<Scalars['ID']>;
};

export type CharactersFilterInput = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  search?: InputMaybe<Scalars['String']>;
  userIds: Array<InputMaybe<Scalars['ID']>>;
};

export type CharactersWithPagination = {
  __typename?: 'CharactersWithPagination';
  characters: Array<Character>;
  pagination: Pagination;
};

export type CheckoutSession = {
  __typename?: 'CheckoutSession';
  id: Scalars['ID'];
};

export type CheckoutSessionInput = {
  priceId: Scalars['String'];
  userId: Scalars['ID'];
};

export type ClaimConversationsInput = {
  conversationIds: Array<Scalars['ID']>;
  userId: Scalars['ID'];
};

export type CommunityPost = {
  __typename?: 'CommunityPost';
  Author: Author;
  commentCount: Scalars['Int'];
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  hasCurrentUserLiked: Scalars['Boolean'];
  id: Scalars['ID'];
  likeCount: Scalars['Int'];
  title: Scalars['String'];
  type: CommunityPostType;
  updatedAt: Scalars['DateTime'];
};

export type CommunityPostComment = {
  __typename?: 'CommunityPostComment';
  Author: Author;
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  hasCurrentUserLiked: Scalars['Boolean'];
  id: Scalars['ID'];
  likeCount: Scalars['Int'];
  parentCommunityPostCommentId?: Maybe<Scalars['String']>;
  replies: Array<CommunityPostComment>;
  updatedAt: Scalars['DateTime'];
};

export type CommunityPostCommentInput = {
  communityPostId: Scalars['ID'];
  content: Scalars['String'];
  parentCommunityPostCommentId?: InputMaybe<Scalars['ID']>;
};

export type CommunityPostCommentsWithPagination = {
  __typename?: 'CommunityPostCommentsWithPagination';
  communityPostComments: Array<CommunityPostComment>;
  pagination: Pagination;
};

export type CommunityPostInput = {
  content: Scalars['String'];
  title: Scalars['String'];
  type: CommunityPostType;
};

export enum CommunityPostType {
  BugReport = 'BugReport',
  FeatureRequest = 'FeatureRequest',
  GeneralDiscussion = 'GeneralDiscussion'
}

export type CommunityPostsWithPagination = {
  __typename?: 'CommunityPostsWithPagination';
  communityPosts: Array<CommunityPost>;
  pagination: Pagination;
};

export type Confirmation = {
  __typename?: 'Confirmation';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  token: Scalars['ID'];
  type: ConfirmationTypeEnum;
  updatedAt: Scalars['DateTime'];
};

export enum ConfirmationTypeEnum {
  Onboarding = 'Onboarding',
  ResetPassword = 'ResetPassword',
  SignIn = 'SignIn'
}

export type Conversation = {
  __typename?: 'Conversation';
  Character: Character;
  ConversationMessages: Array<ConversationMessage>;
  SourceDocument?: Maybe<SourceDocument>;
  SurveySubmit?: Maybe<SurveySubmit>;
  User: Array<User>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  lastMessage?: Maybe<Scalars['String']>;
  metaData: Scalars['String'];
  title: Scalars['String'];
  type: ConversationTypeEnum;
  updatedAt: Scalars['DateTime'];
};

export type ConversationInput = {
  characterEmbedId?: InputMaybe<Scalars['ID']>;
  characterId: Scalars['ID'];
  documentId?: InputMaybe<Scalars['ID']>;
  initialMessage?: InputMaybe<Scalars['String']>;
  metaData?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ConversationTypeEnum>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type ConversationMessage = {
  __typename?: 'ConversationMessage';
  Conversation: Conversation;
  author: ConversationMessageAuthorEnum;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isBookmarked: Scalars['Boolean'];
  text: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum ConversationMessageAuthorEnum {
  Ai = 'AI',
  System = 'System',
  User = 'User'
}

export type ConversationMessageInput = {
  conversationId: Scalars['ID'];
  text: Scalars['String'];
};

export type ConversationMessagesFilterInput = {
  conversationId?: InputMaybe<Scalars['ID']>;
  dateGt?: InputMaybe<Scalars['DateTime']>;
  dateLt?: InputMaybe<Scalars['DateTime']>;
  isBookmarked?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type ConversationMessagesWithPagination = {
  __typename?: 'ConversationMessagesWithPagination';
  conversationMessages: Array<ConversationMessage>;
  pagination: Pagination;
};

export enum ConversationTypeEnum {
  Dating = 'Dating',
  Default = 'Default',
  Embed = 'Embed'
}

export type ConversationsFilterInput = {
  characterEmbedId?: InputMaybe<Scalars['ID']>;
  documentId?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<ConversationTypeEnum>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type ConversationsWithPagination = {
  __typename?: 'ConversationsWithPagination';
  conversations: Array<Conversation>;
  pagination: Pagination;
};

export type Document = {
  __typename?: 'Document';
  User: User;
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type DocumentConversationInput = {
  characterId: Scalars['ID'];
  documentType?: InputMaybe<DocumentTypeEnum>;
  sourceDocumentId: Scalars['ID'];
  title: Scalars['String'];
  userId: Scalars['ID'];
};

export type DocumentFilterInput = {
  search?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};

export type DocumentInput = {
  content: Scalars['String'];
  title: Scalars['String'];
  userId?: InputMaybe<Scalars['ID']>;
};

export enum DocumentTypeEnum {
  Excel = 'Excel',
  Pdf = 'Pdf',
  UrlArticle = 'UrlArticle',
  Word = 'Word'
}

export type DocumentsWithPagination = {
  __typename?: 'DocumentsWithPagination';
  documents: Array<Document>;
  pagination: Pagination;
};

export type EditConversationInput = {
  metaData: Scalars['String'];
  title: Scalars['String'];
};

export type EditConversationMessageInput = {
  isBookmarked?: InputMaybe<Scalars['Boolean']>;
  text?: InputMaybe<Scalars['String']>;
};

export enum ExtraCreditPlan {
  Large = 'Large',
  Medium = 'Medium',
  Small = 'Small'
}

export enum FileTypeEnum {
  Document = 'Document',
  Image = 'Image',
  Video = 'Video'
}

export enum GenderEnum {
  Female = 'Female',
  Male = 'Male'
}

export type Hashtag = {
  __typename?: 'Hashtag';
  ChildHashtags: Array<Hashtag>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  parentHashtagId?: Maybe<Scalars['ID']>;
  updatedAt: Scalars['DateTime'];
};

export type HashtagFilterInput = {
  parentHashtagId?: InputMaybe<Scalars['ID']>;
  search?: InputMaybe<Scalars['String']>;
};

export type HashtagInput = {
  parentHashtagId?: InputMaybe<Scalars['ID']>;
  tag: Scalars['String'];
};

export type HashtagsWithPagination = {
  __typename?: 'HashtagsWithPagination';
  hashtags: Array<Hashtag>;
  pagination: Pagination;
};

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['Boolean']>;
  cancelStripeSubscription: Scalars['Boolean'];
  claimConversations: Scalars['Boolean'];
  createCharacter?: Maybe<Character>;
  createCharacterEmbed?: Maybe<CharacterEmbed>;
  createCharacterImage: S3File;
  createCommunityPost?: Maybe<CommunityPost>;
  createCommunityPostComment: CommunityPostComment;
  createConversation?: Maybe<Conversation>;
  createDocument?: Maybe<Document>;
  createDocumentConversation?: Maybe<Conversation>;
  createHashtag: Scalars['Boolean'];
  createSourceDocument?: Maybe<SourceDocument>;
  createSourceDocumentProcessedContent: Scalars['Boolean'];
  createStripeCheckoutSession: Scalars['String'];
  createSurvey?: Maybe<Survey>;
  createSurveyConversation?: Maybe<Conversation>;
  deleteCommunityPost: Scalars['Boolean'];
  deleteCommunityPostComment: Scalars['Boolean'];
  deleteConfirmation: Scalars['Boolean'];
  deleteConversation: Scalars['Boolean'];
  deleteDocument?: Maybe<Document>;
  deleteHashtag: Scalars['Boolean'];
  editCharacter?: Maybe<Character>;
  editCharacterEmbed?: Maybe<CharacterEmbed>;
  editConversation?: Maybe<Conversation>;
  editConversationMessage?: Maybe<ConversationMessage>;
  editSurvey?: Maybe<Survey>;
  fileUploadS3Presign: S3Payload;
  finishSurveySubmit: SurveySubmit;
  likeCommunityPost?: Maybe<CommunityPost>;
  likeCommunityPostComment?: Maybe<CommunityPostComment>;
  passwordSignUp: AuthSession;
  requestMagicLink: Scalars['Boolean'];
  requestPasswordReset: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  sendMessage?: Maybe<Scalars['String']>;
  setUserOpenAIToken?: Maybe<Scalars['Boolean']>;
  signInWithMagicLink: AuthSession;
  signInWithPassword: AuthSession;
  signOut: Scalars['Boolean'];
  signUp: Scalars['Boolean'];
  turnConversationIntoDocument: Scalars['String'];
  unlikeCommunityPost?: Maybe<CommunityPost>;
  unlikeCommunityPostComment?: Maybe<CommunityPostComment>;
  updateCommunityPost?: Maybe<CommunityPost>;
  updateCommunityPostComment?: Maybe<CommunityPostComment>;
  updateDocument: Document;
  updateHashtag: Scalars['Boolean'];
  updatePassword: Scalars['Boolean'];
  updateUser?: Maybe<User>;
  upgradeStripeSubscription: Scalars['Boolean'];
};


export type MutationCancelStripeSubscriptionArgs = {
  userId: Scalars['ID'];
};


export type MutationClaimConversationsArgs = {
  inputData: ClaimConversationsInput;
};


export type MutationCreateCharacterArgs = {
  inputData: CharacterInput;
};


export type MutationCreateCharacterEmbedArgs = {
  inputData: CharacterEmbedInput;
};


export type MutationCreateCharacterImageArgs = {
  inputData: CharacterImageInput;
};


export type MutationCreateCommunityPostArgs = {
  inputData: CommunityPostInput;
};


export type MutationCreateCommunityPostCommentArgs = {
  inputData: CommunityPostCommentInput;
};


export type MutationCreateConversationArgs = {
  inputData: ConversationInput;
};


export type MutationCreateDocumentArgs = {
  inputData: DocumentInput;
};


export type MutationCreateDocumentConversationArgs = {
  inputData: DocumentConversationInput;
};


export type MutationCreateHashtagArgs = {
  inputData: HashtagInput;
};


export type MutationCreateSourceDocumentArgs = {
  inputData: SourceDocumentInput;
};


export type MutationCreateSourceDocumentProcessedContentArgs = {
  inputData: SourceDocumentProcessedContentInput;
};


export type MutationCreateStripeCheckoutSessionArgs = {
  priceId: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationCreateSurveyArgs = {
  inputData: SurveyInput;
};


export type MutationCreateSurveyConversationArgs = {
  inputData: SurveyConversationInput;
};


export type MutationDeleteCommunityPostArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCommunityPostCommentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteConfirmationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteConversationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteDocumentArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteHashtagArgs = {
  id: Scalars['ID'];
};


export type MutationEditCharacterArgs = {
  id: Scalars['ID'];
  inputData: CharacterInput;
};


export type MutationEditCharacterEmbedArgs = {
  id: Scalars['ID'];
  inputData: CharacterEmbedInput;
};


export type MutationEditConversationArgs = {
  id: Scalars['ID'];
  inputData: EditConversationInput;
};


export type MutationEditConversationMessageArgs = {
  id: Scalars['ID'];
  inputData: EditConversationMessageInput;
};


export type MutationEditSurveyArgs = {
  id: Scalars['ID'];
  inputData: SurveyInput;
};


export type MutationFileUploadS3PresignArgs = {
  contentType: Scalars['String'];
  fileName: Scalars['String'];
  fileSize: Scalars['Int'];
  fileType: FileTypeEnum;
  isPublic?: InputMaybe<Scalars['Boolean']>;
};


export type MutationFinishSurveySubmitArgs = {
  id: Scalars['ID'];
};


export type MutationLikeCommunityPostArgs = {
  id: Scalars['ID'];
};


export type MutationLikeCommunityPostCommentArgs = {
  id: Scalars['ID'];
};


export type MutationPasswordSignUpArgs = {
  inputData: PasswordSignUpInput;
};


export type MutationRequestMagicLinkArgs = {
  email: Scalars['String'];
};


export type MutationRequestPasswordResetArgs = {
  email: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
};


export type MutationSendMessageArgs = {
  inputData: ConversationMessageInput;
};


export type MutationSetUserOpenAiTokenArgs = {
  id: Scalars['ID'];
  token: Scalars['String'];
};


export type MutationSignInWithMagicLinkArgs = {
  token: Scalars['String'];
};


export type MutationSignInWithPasswordArgs = {
  inputData: SignInWithPasswordInput;
};


export type MutationSignOutArgs = {
  accessToken?: InputMaybe<Scalars['String']>;
};


export type MutationSignUpArgs = {
  inputData: SignUpInput;
};


export type MutationTurnConversationIntoDocumentArgs = {
  id: Scalars['ID'];
};


export type MutationUnlikeCommunityPostArgs = {
  id: Scalars['ID'];
};


export type MutationUnlikeCommunityPostCommentArgs = {
  id: Scalars['ID'];
};


export type MutationUpdateCommunityPostArgs = {
  id: Scalars['ID'];
  inputData: CommunityPostInput;
};


export type MutationUpdateCommunityPostCommentArgs = {
  id: Scalars['ID'];
  inputData: CommunityPostCommentInput;
};


export type MutationUpdateDocumentArgs = {
  id: Scalars['ID'];
  inputData: DocumentInput;
};


export type MutationUpdateHashtagArgs = {
  id: Scalars['ID'];
  inputData: HashtagInput;
};


export type MutationUpdatePasswordArgs = {
  inputData: UpdatePasswordInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID'];
  inputData: UpdateUserInput;
};


export type MutationUpgradeStripeSubscriptionArgs = {
  priceId: Scalars['String'];
  userId: Scalars['ID'];
};

export enum OrderByDirectionEnum {
  Asc = 'Asc',
  Desc = 'Desc'
}

export type Pagination = {
  __typename?: 'Pagination';
  from: Scalars['Int'];
  length: Scalars['Int'];
  total: Scalars['Int'];
};

export type PaginationInput = {
  from: Scalars['Int'];
  length: Scalars['Int'];
  orderBy?: InputMaybe<Scalars['String']>;
  orderByDirection?: InputMaybe<OrderByDirectionEnum>;
};

export type PasswordSignUpInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['Boolean']>;
  character?: Maybe<Character>;
  characterEmbed?: Maybe<CharacterEmbed>;
  characterEmbeds: CharacterEmbedsWithPagination;
  characterImages: CharacterImagesWithPagination;
  characters: CharactersWithPagination;
  communityPost?: Maybe<CommunityPost>;
  communityPostComment?: Maybe<CommunityPostComment>;
  communityPostComments: CommunityPostCommentsWithPagination;
  communityPosts: CommunityPostsWithPagination;
  conversation?: Maybe<Conversation>;
  conversationMessages: ConversationMessagesWithPagination;
  conversations: ConversationsWithPagination;
  document?: Maybe<Document>;
  documents: DocumentsWithPagination;
  generateStripePortalLink?: Maybe<Scalars['String']>;
  hashtag?: Maybe<Hashtag>;
  hashtags: HashtagsWithPagination;
  me?: Maybe<AuthSession>;
  sourceDocument?: Maybe<SourceDocument>;
  stripeProducts: Array<StripeProduct>;
  survey?: Maybe<Survey>;
  surveySubmits: SurveySubmitsWithPagination;
  surveys: SurveysWithPagination;
  user?: Maybe<User>;
  users: UsersWithPagination;
};


export type QueryCharacterArgs = {
  id: Scalars['ID'];
};


export type QueryCharacterEmbedArgs = {
  id: Scalars['ID'];
};


export type QueryCharacterEmbedsArgs = {
  filterData: CharacterEmbedsFilterInput;
  pagination: PaginationInput;
};


export type QueryCharacterImagesArgs = {
  filterData: CharacterImagesFilterInput;
  pagination: PaginationInput;
};


export type QueryCharactersArgs = {
  filterData: CharactersFilterInput;
  pagination: PaginationInput;
};


export type QueryCommunityPostArgs = {
  id: Scalars['ID'];
};


export type QueryCommunityPostCommentArgs = {
  id: Scalars['ID'];
};


export type QueryCommunityPostCommentsArgs = {
  communityPostId: Scalars['String'];
  pagination: PaginationInput;
};


export type QueryCommunityPostsArgs = {
  pagination: PaginationInput;
};


export type QueryConversationArgs = {
  id: Scalars['ID'];
};


export type QueryConversationMessagesArgs = {
  filterData: ConversationMessagesFilterInput;
  pagination: PaginationInput;
};


export type QueryConversationsArgs = {
  filterData: ConversationsFilterInput;
  pagination: PaginationInput;
};


export type QueryDocumentArgs = {
  id: Scalars['ID'];
};


export type QueryDocumentsArgs = {
  filterData: DocumentFilterInput;
  pagination: PaginationInput;
};


export type QueryHashtagArgs = {
  id: Scalars['ID'];
};


export type QueryHashtagsArgs = {
  filterData: HashtagFilterInput;
  pagination: PaginationInput;
};


export type QuerySourceDocumentArgs = {
  id: Scalars['ID'];
};


export type QuerySurveyArgs = {
  id: Scalars['ID'];
};


export type QuerySurveySubmitsArgs = {
  filterData: SurveySubmitsFilterInput;
  pagination: PaginationInput;
};


export type QuerySurveysArgs = {
  filterData: SurveysFilterInput;
  pagination: PaginationInput;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  filterData?: InputMaybe<UsersFilterInput>;
  pagination: PaginationInput;
};

export type S3File = {
  __typename?: 'S3File';
  Uploader?: Maybe<Uploader>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isPublic: Scalars['Boolean'];
  key: Scalars['String'];
  name: Scalars['String'];
  signExpiresAt?: Maybe<Scalars['DateTime']>;
  signedUrl?: Maybe<Scalars['String']>;
  size: Scalars['Int'];
  thumbnailSignedUrl?: Maybe<Scalars['String']>;
  type: FileTypeEnum;
  updatedAt: Scalars['DateTime'];
};


export type S3FileThumbnailSignedUrlArgs = {
  size: ThumbnailSizeEnum;
};

export type S3Payload = {
  __typename?: 'S3Payload';
  key: Scalars['String'];
  signedRequest: Scalars['String'];
};

export type SignInWithPasswordInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type SignUpInput = {
  email: Scalars['String'];
  id: Scalars['String'];
};

export type SourceDocument = {
  __typename?: 'SourceDocument';
  S3File?: Maybe<S3File>;
  SourceDocumentContents: Array<SourceDocumentContent>;
  SourceDocumentProcessedContents: Array<SourceDocumentProcessedContent>;
  documentType: DocumentTypeEnum;
  id: Scalars['ID'];
};

export type SourceDocumentContent = {
  __typename?: 'SourceDocumentContent';
  documentType: DocumentTypeEnum;
  id: Scalars['ID'];
  index: Scalars['Int'];
  key: Scalars['String'];
  value: Scalars['String'];
};

export enum SourceDocumentContentKeyEnum {
  Content = 'content'
}

export type SourceDocumentInput = {
  documentType: DocumentTypeEnum;
  fileUrl?: InputMaybe<Scalars['String']>;
  s3FileKey?: InputMaybe<Scalars['String']>;
};

export type SourceDocumentProcessedContent = {
  __typename?: 'SourceDocumentProcessedContent';
  documentType: DocumentTypeEnum;
  id: Scalars['ID'];
  index: Scalars['Int'];
  key: Scalars['String'];
  type: SourceDocumentProcessedContentTypeEnum;
  value: Scalars['String'];
};

export type SourceDocumentProcessedContentInput = {
  sourceDocumentId: Scalars['ID'];
  type: SourceDocumentProcessedContentTypeEnum;
};

export enum SourceDocumentProcessedContentTypeEnum {
  KeyNotes = 'KeyNotes',
  Summary = 'Summary'
}

export type StripePrice = {
  __typename?: 'StripePrice';
  StripeProduct?: Maybe<StripeProduct>;
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  currency: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  interval?: Maybe<Scalars['String']>;
  intervalCount?: Maybe<Scalars['Int']>;
  metadata: Scalars['String'];
  trialPeriodDays?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  unitAmount?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
};

export type StripeProduct = {
  __typename?: 'StripeProduct';
  StripePrices: Array<StripePrice>;
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  images: Array<Scalars['String']>;
  metadata: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Subscription = {
  __typename?: 'Subscription';
  StripePrice?: Maybe<StripePrice>;
  StripeProduct?: Maybe<StripeProduct>;
  User?: Maybe<User>;
  cancelAt?: Maybe<Scalars['DateTime']>;
  canceledAt?: Maybe<Scalars['DateTime']>;
  created?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  credits: Scalars['Int'];
  creditsAtPeriodStart: Scalars['Int'];
  currentPeriodEnd?: Maybe<Scalars['DateTime']>;
  currentPeriodStart?: Maybe<Scalars['DateTime']>;
  endedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  plan?: Maybe<SubscriptionPlan>;
  status?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  userId?: Maybe<Scalars['String']>;
};

export enum SubscriptionPlan {
  Basic = 'Basic',
  Premium = 'Premium',
  Pro = 'Pro'
}

export type Survey = {
  __typename?: 'Survey';
  Character: Character;
  User?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  metaData: Scalars['String'];
  numOfSurveySubmits: Scalars['Int'];
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type SurveyConversationInput = {
  initialMessage?: InputMaybe<Scalars['String']>;
  surveyId: Scalars['ID'];
  userId?: InputMaybe<Scalars['ID']>;
};

export type SurveyInput = {
  Character: CharacterInput;
  metaData: Scalars['String'];
  openAIToken?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
  userId: Scalars['ID'];
};

export type SurveySubmit = {
  __typename?: 'SurveySubmit';
  Conversation: Conversation;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isFinished: Scalars['Boolean'];
  metaData: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type SurveySubmitsFilterInput = {
  surveyId: Scalars['ID'];
};

export type SurveySubmitsWithPagination = {
  __typename?: 'SurveySubmitsWithPagination';
  pagination: Pagination;
  surveySubmits: Array<SurveySubmit>;
};

export type SurveysFilterInput = {
  userId: Scalars['ID'];
};

export type SurveysWithPagination = {
  __typename?: 'SurveysWithPagination';
  pagination: Pagination;
  surveys: Array<Survey>;
};

export enum ThumbnailSizeEnum {
  Large = 'Large',
  Medium = 'Medium',
  Small = 'Small'
}

export type UpdatePasswordInput = {
  newPassword: Scalars['String'];
  oldPassword?: InputMaybe<Scalars['String']>;
};

export type UpdateUserInput = {
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<GenderEnum>;
  lastName?: InputMaybe<Scalars['String']>;
  openAIToken?: InputMaybe<Scalars['String']>;
};

export type Uploader = {
  __typename?: 'Uploader';
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderEnum>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  Confirmations: Array<Confirmation>;
  Subscription: Subscription;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  gender?: Maybe<GenderEnum>;
  hasPassword: Scalars['Boolean'];
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  openAIToken?: Maybe<Scalars['String']>;
  role: UserRoleEnum;
  updatedAt: Scalars['DateTime'];
};


export type UserConfirmationsArgs = {
  type?: InputMaybe<ConfirmationTypeEnum>;
};

export enum UserRoleEnum {
  SuperAdmin = 'SuperAdmin',
  User = 'User'
}

export type UsersFilterInput = {
  excludeUserIds?: InputMaybe<Array<Scalars['ID']>>;
  search?: InputMaybe<Scalars['String']>;
};

export type UsersWithPagination = {
  __typename?: 'UsersWithPagination';
  pagination: Pagination;
  users: Array<User>;
};

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = T | Promise<T | null> | null;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  AuthSession: ResolverTypeWrapper<Partial<AuthSession>>;
  Author: ResolverTypeWrapper<Partial<Author>>;
  Boolean: ResolverTypeWrapper<Partial<Scalars['Boolean']>>;
  Character: ResolverTypeWrapper<Partial<Character>>;
  CharacterEmbed: ResolverTypeWrapper<Partial<CharacterEmbed>>;
  CharacterEmbedInput: ResolverTypeWrapper<Partial<CharacterEmbedInput>>;
  CharacterEmbedsFilterInput: ResolverTypeWrapper<Partial<CharacterEmbedsFilterInput>>;
  CharacterEmbedsWithPagination: ResolverTypeWrapper<Partial<CharacterEmbedsWithPagination>>;
  CharacterImageInput: ResolverTypeWrapper<Partial<CharacterImageInput>>;
  CharacterImagesFilterInput: ResolverTypeWrapper<Partial<CharacterImagesFilterInput>>;
  CharacterImagesWithPagination: ResolverTypeWrapper<Partial<CharacterImagesWithPagination>>;
  CharacterInput: ResolverTypeWrapper<Partial<CharacterInput>>;
  CharactersFilterInput: ResolverTypeWrapper<Partial<CharactersFilterInput>>;
  CharactersWithPagination: ResolverTypeWrapper<Partial<CharactersWithPagination>>;
  CheckoutSession: ResolverTypeWrapper<Partial<CheckoutSession>>;
  CheckoutSessionInput: ResolverTypeWrapper<Partial<CheckoutSessionInput>>;
  ClaimConversationsInput: ResolverTypeWrapper<Partial<ClaimConversationsInput>>;
  CommunityPost: ResolverTypeWrapper<Partial<CommunityPost>>;
  CommunityPostComment: ResolverTypeWrapper<Partial<CommunityPostComment>>;
  CommunityPostCommentInput: ResolverTypeWrapper<Partial<CommunityPostCommentInput>>;
  CommunityPostCommentsWithPagination: ResolverTypeWrapper<Partial<CommunityPostCommentsWithPagination>>;
  CommunityPostInput: ResolverTypeWrapper<Partial<CommunityPostInput>>;
  CommunityPostType: ResolverTypeWrapper<Partial<CommunityPostType>>;
  CommunityPostsWithPagination: ResolverTypeWrapper<Partial<CommunityPostsWithPagination>>;
  Confirmation: ResolverTypeWrapper<Partial<Confirmation>>;
  ConfirmationTypeEnum: ResolverTypeWrapper<Partial<ConfirmationTypeEnum>>;
  Conversation: ResolverTypeWrapper<Partial<Conversation>>;
  ConversationInput: ResolverTypeWrapper<Partial<ConversationInput>>;
  ConversationMessage: ResolverTypeWrapper<Partial<ConversationMessage>>;
  ConversationMessageAuthorEnum: ResolverTypeWrapper<Partial<ConversationMessageAuthorEnum>>;
  ConversationMessageInput: ResolverTypeWrapper<Partial<ConversationMessageInput>>;
  ConversationMessagesFilterInput: ResolverTypeWrapper<Partial<ConversationMessagesFilterInput>>;
  ConversationMessagesWithPagination: ResolverTypeWrapper<Partial<ConversationMessagesWithPagination>>;
  ConversationTypeEnum: ResolverTypeWrapper<Partial<ConversationTypeEnum>>;
  ConversationsFilterInput: ResolverTypeWrapper<Partial<ConversationsFilterInput>>;
  ConversationsWithPagination: ResolverTypeWrapper<Partial<ConversationsWithPagination>>;
  DateTime: ResolverTypeWrapper<Partial<Scalars['DateTime']>>;
  Document: ResolverTypeWrapper<Partial<Document>>;
  DocumentConversationInput: ResolverTypeWrapper<Partial<DocumentConversationInput>>;
  DocumentFilterInput: ResolverTypeWrapper<Partial<DocumentFilterInput>>;
  DocumentInput: ResolverTypeWrapper<Partial<DocumentInput>>;
  DocumentTypeEnum: ResolverTypeWrapper<Partial<DocumentTypeEnum>>;
  DocumentsWithPagination: ResolverTypeWrapper<Partial<DocumentsWithPagination>>;
  EditConversationInput: ResolverTypeWrapper<Partial<EditConversationInput>>;
  EditConversationMessageInput: ResolverTypeWrapper<Partial<EditConversationMessageInput>>;
  ExtraCreditPlan: ResolverTypeWrapper<Partial<ExtraCreditPlan>>;
  FileTypeEnum: ResolverTypeWrapper<Partial<FileTypeEnum>>;
  GenderEnum: ResolverTypeWrapper<Partial<GenderEnum>>;
  Hashtag: ResolverTypeWrapper<Partial<Hashtag>>;
  HashtagFilterInput: ResolverTypeWrapper<Partial<HashtagFilterInput>>;
  HashtagInput: ResolverTypeWrapper<Partial<HashtagInput>>;
  HashtagsWithPagination: ResolverTypeWrapper<Partial<HashtagsWithPagination>>;
  ID: ResolverTypeWrapper<Partial<Scalars['ID']>>;
  Int: ResolverTypeWrapper<Partial<Scalars['Int']>>;
  Mutation: ResolverTypeWrapper<{}>;
  OrderByDirectionEnum: ResolverTypeWrapper<Partial<OrderByDirectionEnum>>;
  Pagination: ResolverTypeWrapper<Partial<Pagination>>;
  PaginationInput: ResolverTypeWrapper<Partial<PaginationInput>>;
  PasswordSignUpInput: ResolverTypeWrapper<Partial<PasswordSignUpInput>>;
  Query: ResolverTypeWrapper<{}>;
  S3File: ResolverTypeWrapper<Partial<S3File>>;
  S3Payload: ResolverTypeWrapper<Partial<S3Payload>>;
  SignInWithPasswordInput: ResolverTypeWrapper<Partial<SignInWithPasswordInput>>;
  SignUpInput: ResolverTypeWrapper<Partial<SignUpInput>>;
  SourceDocument: ResolverTypeWrapper<Partial<SourceDocument>>;
  SourceDocumentContent: ResolverTypeWrapper<Partial<SourceDocumentContent>>;
  SourceDocumentContentKeyEnum: ResolverTypeWrapper<Partial<SourceDocumentContentKeyEnum>>;
  SourceDocumentInput: ResolverTypeWrapper<Partial<SourceDocumentInput>>;
  SourceDocumentProcessedContent: ResolverTypeWrapper<Partial<SourceDocumentProcessedContent>>;
  SourceDocumentProcessedContentInput: ResolverTypeWrapper<Partial<SourceDocumentProcessedContentInput>>;
  SourceDocumentProcessedContentTypeEnum: ResolverTypeWrapper<Partial<SourceDocumentProcessedContentTypeEnum>>;
  String: ResolverTypeWrapper<Partial<Scalars['String']>>;
  StripePrice: ResolverTypeWrapper<Partial<StripePrice>>;
  StripeProduct: ResolverTypeWrapper<Partial<StripeProduct>>;
  Subscription: ResolverTypeWrapper<{}>;
  SubscriptionPlan: ResolverTypeWrapper<Partial<SubscriptionPlan>>;
  Survey: ResolverTypeWrapper<Partial<Survey>>;
  SurveyConversationInput: ResolverTypeWrapper<Partial<SurveyConversationInput>>;
  SurveyInput: ResolverTypeWrapper<Partial<SurveyInput>>;
  SurveySubmit: ResolverTypeWrapper<Partial<SurveySubmit>>;
  SurveySubmitsFilterInput: ResolverTypeWrapper<Partial<SurveySubmitsFilterInput>>;
  SurveySubmitsWithPagination: ResolverTypeWrapper<Partial<SurveySubmitsWithPagination>>;
  SurveysFilterInput: ResolverTypeWrapper<Partial<SurveysFilterInput>>;
  SurveysWithPagination: ResolverTypeWrapper<Partial<SurveysWithPagination>>;
  ThumbnailSizeEnum: ResolverTypeWrapper<Partial<ThumbnailSizeEnum>>;
  UpdatePasswordInput: ResolverTypeWrapper<Partial<UpdatePasswordInput>>;
  UpdateUserInput: ResolverTypeWrapper<Partial<UpdateUserInput>>;
  Uploader: ResolverTypeWrapper<Partial<Uploader>>;
  User: ResolverTypeWrapper<Partial<User>>;
  UserRoleEnum: ResolverTypeWrapper<Partial<UserRoleEnum>>;
  UsersFilterInput: ResolverTypeWrapper<Partial<UsersFilterInput>>;
  UsersWithPagination: ResolverTypeWrapper<Partial<UsersWithPagination>>;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  AuthSession: Partial<AuthSession>;
  Author: Partial<Author>;
  Boolean: Partial<Scalars['Boolean']>;
  Character: Partial<Character>;
  CharacterEmbed: Partial<CharacterEmbed>;
  CharacterEmbedInput: Partial<CharacterEmbedInput>;
  CharacterEmbedsFilterInput: Partial<CharacterEmbedsFilterInput>;
  CharacterEmbedsWithPagination: Partial<CharacterEmbedsWithPagination>;
  CharacterImageInput: Partial<CharacterImageInput>;
  CharacterImagesFilterInput: Partial<CharacterImagesFilterInput>;
  CharacterImagesWithPagination: Partial<CharacterImagesWithPagination>;
  CharacterInput: Partial<CharacterInput>;
  CharactersFilterInput: Partial<CharactersFilterInput>;
  CharactersWithPagination: Partial<CharactersWithPagination>;
  CheckoutSession: Partial<CheckoutSession>;
  CheckoutSessionInput: Partial<CheckoutSessionInput>;
  ClaimConversationsInput: Partial<ClaimConversationsInput>;
  CommunityPost: Partial<CommunityPost>;
  CommunityPostComment: Partial<CommunityPostComment>;
  CommunityPostCommentInput: Partial<CommunityPostCommentInput>;
  CommunityPostCommentsWithPagination: Partial<CommunityPostCommentsWithPagination>;
  CommunityPostInput: Partial<CommunityPostInput>;
  CommunityPostsWithPagination: Partial<CommunityPostsWithPagination>;
  Confirmation: Partial<Confirmation>;
  Conversation: Partial<Conversation>;
  ConversationInput: Partial<ConversationInput>;
  ConversationMessage: Partial<ConversationMessage>;
  ConversationMessageInput: Partial<ConversationMessageInput>;
  ConversationMessagesFilterInput: Partial<ConversationMessagesFilterInput>;
  ConversationMessagesWithPagination: Partial<ConversationMessagesWithPagination>;
  ConversationsFilterInput: Partial<ConversationsFilterInput>;
  ConversationsWithPagination: Partial<ConversationsWithPagination>;
  DateTime: Partial<Scalars['DateTime']>;
  Document: Partial<Document>;
  DocumentConversationInput: Partial<DocumentConversationInput>;
  DocumentFilterInput: Partial<DocumentFilterInput>;
  DocumentInput: Partial<DocumentInput>;
  DocumentsWithPagination: Partial<DocumentsWithPagination>;
  EditConversationInput: Partial<EditConversationInput>;
  EditConversationMessageInput: Partial<EditConversationMessageInput>;
  Hashtag: Partial<Hashtag>;
  HashtagFilterInput: Partial<HashtagFilterInput>;
  HashtagInput: Partial<HashtagInput>;
  HashtagsWithPagination: Partial<HashtagsWithPagination>;
  ID: Partial<Scalars['ID']>;
  Int: Partial<Scalars['Int']>;
  Mutation: {};
  Pagination: Partial<Pagination>;
  PaginationInput: Partial<PaginationInput>;
  PasswordSignUpInput: Partial<PasswordSignUpInput>;
  Query: {};
  S3File: Partial<S3File>;
  S3Payload: Partial<S3Payload>;
  SignInWithPasswordInput: Partial<SignInWithPasswordInput>;
  SignUpInput: Partial<SignUpInput>;
  SourceDocument: Partial<SourceDocument>;
  SourceDocumentContent: Partial<SourceDocumentContent>;
  SourceDocumentInput: Partial<SourceDocumentInput>;
  SourceDocumentProcessedContent: Partial<SourceDocumentProcessedContent>;
  SourceDocumentProcessedContentInput: Partial<SourceDocumentProcessedContentInput>;
  String: Partial<Scalars['String']>;
  StripePrice: Partial<StripePrice>;
  StripeProduct: Partial<StripeProduct>;
  Subscription: {};
  Survey: Partial<Survey>;
  SurveyConversationInput: Partial<SurveyConversationInput>;
  SurveyInput: Partial<SurveyInput>;
  SurveySubmit: Partial<SurveySubmit>;
  SurveySubmitsFilterInput: Partial<SurveySubmitsFilterInput>;
  SurveySubmitsWithPagination: Partial<SurveySubmitsWithPagination>;
  SurveysFilterInput: Partial<SurveysFilterInput>;
  SurveysWithPagination: Partial<SurveysWithPagination>;
  UpdatePasswordInput: Partial<UpdatePasswordInput>;
  UpdateUserInput: Partial<UpdateUserInput>;
  Uploader: Partial<Uploader>;
  User: Partial<User>;
  UsersFilterInput: Partial<UsersFilterInput>;
  UsersWithPagination: Partial<UsersWithPagination>;
}>;

export type AuthSessionResolvers<ContextType = any, ParentType extends ResolversParentTypes['AuthSession'] = ResolversParentTypes['AuthSession']> = ResolversObject<{
  User?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  accessToken?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type AuthorResolvers<ContextType = any, ParentType extends ResolversParentTypes['Author'] = ResolversParentTypes['Author']> = ResolversObject<{
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CharacterResolvers<ContextType = any, ParentType extends ResolversParentTypes['Character'] = ResolversParentTypes['Character']> = ResolversObject<{
  ImageS3File?: Resolver<Maybe<ResolversTypes['S3File']>, ParentType, ContextType>;
  User?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  gender?: Resolver<ResolversTypes['GenderEnum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isPublic?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  metaData?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CharacterEmbedResolvers<ContextType = any, ParentType extends ResolversParentTypes['CharacterEmbed'] = ResolversParentTypes['CharacterEmbed']> = ResolversObject<{
  Character?: Resolver<ResolversTypes['Character'], ParentType, ContextType>;
  User?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  metaData?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numOfConversations?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CharacterEmbedsWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CharacterEmbedsWithPagination'] = ResolversParentTypes['CharacterEmbedsWithPagination']> = ResolversObject<{
  characterEmbeds?: Resolver<Array<ResolversTypes['CharacterEmbed']>, ParentType, ContextType>;
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CharacterImagesWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CharacterImagesWithPagination'] = ResolversParentTypes['CharacterImagesWithPagination']> = ResolversObject<{
  characterImages?: Resolver<Array<ResolversTypes['S3File']>, ParentType, ContextType>;
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CharactersWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CharactersWithPagination'] = ResolversParentTypes['CharactersWithPagination']> = ResolversObject<{
  characters?: Resolver<Array<ResolversTypes['Character']>, ParentType, ContextType>;
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CheckoutSessionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CheckoutSession'] = ResolversParentTypes['CheckoutSession']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CommunityPostResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommunityPost'] = ResolversParentTypes['CommunityPost']> = ResolversObject<{
  Author?: Resolver<ResolversTypes['Author'], ParentType, ContextType>;
  commentCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  hasCurrentUserLiked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  likeCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['CommunityPostType'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CommunityPostCommentResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommunityPostComment'] = ResolversParentTypes['CommunityPostComment']> = ResolversObject<{
  Author?: Resolver<ResolversTypes['Author'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  hasCurrentUserLiked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  likeCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  parentCommunityPostCommentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  replies?: Resolver<Array<ResolversTypes['CommunityPostComment']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CommunityPostCommentsWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommunityPostCommentsWithPagination'] = ResolversParentTypes['CommunityPostCommentsWithPagination']> = ResolversObject<{
  communityPostComments?: Resolver<Array<ResolversTypes['CommunityPostComment']>, ParentType, ContextType>;
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CommunityPostsWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommunityPostsWithPagination'] = ResolversParentTypes['CommunityPostsWithPagination']> = ResolversObject<{
  communityPosts?: Resolver<Array<ResolversTypes['CommunityPost']>, ParentType, ContextType>;
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConfirmationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Confirmation'] = ResolversParentTypes['Confirmation']> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  token?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ConfirmationTypeEnum'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConversationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Conversation'] = ResolversParentTypes['Conversation']> = ResolversObject<{
  Character?: Resolver<ResolversTypes['Character'], ParentType, ContextType>;
  ConversationMessages?: Resolver<Array<ResolversTypes['ConversationMessage']>, ParentType, ContextType>;
  SourceDocument?: Resolver<Maybe<ResolversTypes['SourceDocument']>, ParentType, ContextType>;
  SurveySubmit?: Resolver<Maybe<ResolversTypes['SurveySubmit']>, ParentType, ContextType>;
  User?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  metaData?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ConversationTypeEnum'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConversationMessageResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConversationMessage'] = ResolversParentTypes['ConversationMessage']> = ResolversObject<{
  Conversation?: Resolver<ResolversTypes['Conversation'], ParentType, ContextType>;
  author?: Resolver<ResolversTypes['ConversationMessageAuthorEnum'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isBookmarked?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConversationMessagesWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConversationMessagesWithPagination'] = ResolversParentTypes['ConversationMessagesWithPagination']> = ResolversObject<{
  conversationMessages?: Resolver<Array<ResolversTypes['ConversationMessage']>, ParentType, ContextType>;
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ConversationsWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConversationsWithPagination'] = ResolversParentTypes['ConversationsWithPagination']> = ResolversObject<{
  conversations?: Resolver<Array<ResolversTypes['Conversation']>, ParentType, ContextType>;
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Document'] = ResolversParentTypes['Document']> = ResolversObject<{
  User?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type DocumentsWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentsWithPagination'] = ResolversParentTypes['DocumentsWithPagination']> = ResolversObject<{
  documents?: Resolver<Array<ResolversTypes['Document']>, ParentType, ContextType>;
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HashtagResolvers<ContextType = any, ParentType extends ResolversParentTypes['Hashtag'] = ResolversParentTypes['Hashtag']> = ResolversObject<{
  ChildHashtags?: Resolver<Array<ResolversTypes['Hashtag']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  parentHashtagId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type HashtagsWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['HashtagsWithPagination'] = ResolversParentTypes['HashtagsWithPagination']> = ResolversObject<{
  hashtags?: Resolver<Array<ResolversTypes['Hashtag']>, ParentType, ContextType>;
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = ResolversObject<{
  _?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  cancelStripeSubscription?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCancelStripeSubscriptionArgs, 'userId'>>;
  claimConversations?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationClaimConversationsArgs, 'inputData'>>;
  createCharacter?: Resolver<Maybe<ResolversTypes['Character']>, ParentType, ContextType, RequireFields<MutationCreateCharacterArgs, 'inputData'>>;
  createCharacterEmbed?: Resolver<Maybe<ResolversTypes['CharacterEmbed']>, ParentType, ContextType, RequireFields<MutationCreateCharacterEmbedArgs, 'inputData'>>;
  createCharacterImage?: Resolver<ResolversTypes['S3File'], ParentType, ContextType, RequireFields<MutationCreateCharacterImageArgs, 'inputData'>>;
  createCommunityPost?: Resolver<Maybe<ResolversTypes['CommunityPost']>, ParentType, ContextType, RequireFields<MutationCreateCommunityPostArgs, 'inputData'>>;
  createCommunityPostComment?: Resolver<ResolversTypes['CommunityPostComment'], ParentType, ContextType, RequireFields<MutationCreateCommunityPostCommentArgs, 'inputData'>>;
  createConversation?: Resolver<Maybe<ResolversTypes['Conversation']>, ParentType, ContextType, RequireFields<MutationCreateConversationArgs, 'inputData'>>;
  createDocument?: Resolver<Maybe<ResolversTypes['Document']>, ParentType, ContextType, RequireFields<MutationCreateDocumentArgs, 'inputData'>>;
  createDocumentConversation?: Resolver<Maybe<ResolversTypes['Conversation']>, ParentType, ContextType, RequireFields<MutationCreateDocumentConversationArgs, 'inputData'>>;
  createHashtag?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCreateHashtagArgs, 'inputData'>>;
  createSourceDocument?: Resolver<Maybe<ResolversTypes['SourceDocument']>, ParentType, ContextType, RequireFields<MutationCreateSourceDocumentArgs, 'inputData'>>;
  createSourceDocumentProcessedContent?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationCreateSourceDocumentProcessedContentArgs, 'inputData'>>;
  createStripeCheckoutSession?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationCreateStripeCheckoutSessionArgs, 'priceId' | 'userId'>>;
  createSurvey?: Resolver<Maybe<ResolversTypes['Survey']>, ParentType, ContextType, RequireFields<MutationCreateSurveyArgs, 'inputData'>>;
  createSurveyConversation?: Resolver<Maybe<ResolversTypes['Conversation']>, ParentType, ContextType, RequireFields<MutationCreateSurveyConversationArgs, 'inputData'>>;
  deleteCommunityPost?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteCommunityPostArgs, 'id'>>;
  deleteCommunityPostComment?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteCommunityPostCommentArgs, 'id'>>;
  deleteConfirmation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteConfirmationArgs, 'id'>>;
  deleteConversation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteConversationArgs, 'id'>>;
  deleteDocument?: Resolver<Maybe<ResolversTypes['Document']>, ParentType, ContextType, RequireFields<MutationDeleteDocumentArgs, 'id'>>;
  deleteHashtag?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationDeleteHashtagArgs, 'id'>>;
  editCharacter?: Resolver<Maybe<ResolversTypes['Character']>, ParentType, ContextType, RequireFields<MutationEditCharacterArgs, 'id' | 'inputData'>>;
  editCharacterEmbed?: Resolver<Maybe<ResolversTypes['CharacterEmbed']>, ParentType, ContextType, RequireFields<MutationEditCharacterEmbedArgs, 'id' | 'inputData'>>;
  editConversation?: Resolver<Maybe<ResolversTypes['Conversation']>, ParentType, ContextType, RequireFields<MutationEditConversationArgs, 'id' | 'inputData'>>;
  editConversationMessage?: Resolver<Maybe<ResolversTypes['ConversationMessage']>, ParentType, ContextType, RequireFields<MutationEditConversationMessageArgs, 'id' | 'inputData'>>;
  editSurvey?: Resolver<Maybe<ResolversTypes['Survey']>, ParentType, ContextType, RequireFields<MutationEditSurveyArgs, 'id' | 'inputData'>>;
  fileUploadS3Presign?: Resolver<ResolversTypes['S3Payload'], ParentType, ContextType, RequireFields<MutationFileUploadS3PresignArgs, 'contentType' | 'fileName' | 'fileSize' | 'fileType'>>;
  finishSurveySubmit?: Resolver<ResolversTypes['SurveySubmit'], ParentType, ContextType, RequireFields<MutationFinishSurveySubmitArgs, 'id'>>;
  likeCommunityPost?: Resolver<Maybe<ResolversTypes['CommunityPost']>, ParentType, ContextType, RequireFields<MutationLikeCommunityPostArgs, 'id'>>;
  likeCommunityPostComment?: Resolver<Maybe<ResolversTypes['CommunityPostComment']>, ParentType, ContextType, RequireFields<MutationLikeCommunityPostCommentArgs, 'id'>>;
  passwordSignUp?: Resolver<ResolversTypes['AuthSession'], ParentType, ContextType, RequireFields<MutationPasswordSignUpArgs, 'inputData'>>;
  requestMagicLink?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRequestMagicLinkArgs, 'email'>>;
  requestPasswordReset?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationRequestPasswordResetArgs, 'email'>>;
  resetPassword?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationResetPasswordArgs, 'newPassword' | 'token'>>;
  sendMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<MutationSendMessageArgs, 'inputData'>>;
  setUserOpenAIToken?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, RequireFields<MutationSetUserOpenAiTokenArgs, 'id' | 'token'>>;
  signInWithMagicLink?: Resolver<ResolversTypes['AuthSession'], ParentType, ContextType, RequireFields<MutationSignInWithMagicLinkArgs, 'token'>>;
  signInWithPassword?: Resolver<ResolversTypes['AuthSession'], ParentType, ContextType, RequireFields<MutationSignInWithPasswordArgs, 'inputData'>>;
  signOut?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, Partial<MutationSignOutArgs>>;
  signUp?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationSignUpArgs, 'inputData'>>;
  turnConversationIntoDocument?: Resolver<ResolversTypes['String'], ParentType, ContextType, RequireFields<MutationTurnConversationIntoDocumentArgs, 'id'>>;
  unlikeCommunityPost?: Resolver<Maybe<ResolversTypes['CommunityPost']>, ParentType, ContextType, RequireFields<MutationUnlikeCommunityPostArgs, 'id'>>;
  unlikeCommunityPostComment?: Resolver<Maybe<ResolversTypes['CommunityPostComment']>, ParentType, ContextType, RequireFields<MutationUnlikeCommunityPostCommentArgs, 'id'>>;
  updateCommunityPost?: Resolver<Maybe<ResolversTypes['CommunityPost']>, ParentType, ContextType, RequireFields<MutationUpdateCommunityPostArgs, 'id' | 'inputData'>>;
  updateCommunityPostComment?: Resolver<Maybe<ResolversTypes['CommunityPostComment']>, ParentType, ContextType, RequireFields<MutationUpdateCommunityPostCommentArgs, 'id' | 'inputData'>>;
  updateDocument?: Resolver<ResolversTypes['Document'], ParentType, ContextType, RequireFields<MutationUpdateDocumentArgs, 'id' | 'inputData'>>;
  updateHashtag?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdateHashtagArgs, 'id' | 'inputData'>>;
  updatePassword?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpdatePasswordArgs, 'inputData'>>;
  updateUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationUpdateUserArgs, 'id' | 'inputData'>>;
  upgradeStripeSubscription?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<MutationUpgradeStripeSubscriptionArgs, 'priceId' | 'userId'>>;
}>;

export type PaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Pagination'] = ResolversParentTypes['Pagination']> = ResolversObject<{
  from?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  length?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = ResolversObject<{
  _?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  character?: Resolver<Maybe<ResolversTypes['Character']>, ParentType, ContextType, RequireFields<QueryCharacterArgs, 'id'>>;
  characterEmbed?: Resolver<Maybe<ResolversTypes['CharacterEmbed']>, ParentType, ContextType, RequireFields<QueryCharacterEmbedArgs, 'id'>>;
  characterEmbeds?: Resolver<ResolversTypes['CharacterEmbedsWithPagination'], ParentType, ContextType, RequireFields<QueryCharacterEmbedsArgs, 'filterData' | 'pagination'>>;
  characterImages?: Resolver<ResolversTypes['CharacterImagesWithPagination'], ParentType, ContextType, RequireFields<QueryCharacterImagesArgs, 'filterData' | 'pagination'>>;
  characters?: Resolver<ResolversTypes['CharactersWithPagination'], ParentType, ContextType, RequireFields<QueryCharactersArgs, 'filterData' | 'pagination'>>;
  communityPost?: Resolver<Maybe<ResolversTypes['CommunityPost']>, ParentType, ContextType, RequireFields<QueryCommunityPostArgs, 'id'>>;
  communityPostComment?: Resolver<Maybe<ResolversTypes['CommunityPostComment']>, ParentType, ContextType, RequireFields<QueryCommunityPostCommentArgs, 'id'>>;
  communityPostComments?: Resolver<ResolversTypes['CommunityPostCommentsWithPagination'], ParentType, ContextType, RequireFields<QueryCommunityPostCommentsArgs, 'communityPostId' | 'pagination'>>;
  communityPosts?: Resolver<ResolversTypes['CommunityPostsWithPagination'], ParentType, ContextType, RequireFields<QueryCommunityPostsArgs, 'pagination'>>;
  conversation?: Resolver<Maybe<ResolversTypes['Conversation']>, ParentType, ContextType, RequireFields<QueryConversationArgs, 'id'>>;
  conversationMessages?: Resolver<ResolversTypes['ConversationMessagesWithPagination'], ParentType, ContextType, RequireFields<QueryConversationMessagesArgs, 'filterData' | 'pagination'>>;
  conversations?: Resolver<ResolversTypes['ConversationsWithPagination'], ParentType, ContextType, RequireFields<QueryConversationsArgs, 'filterData' | 'pagination'>>;
  document?: Resolver<Maybe<ResolversTypes['Document']>, ParentType, ContextType, RequireFields<QueryDocumentArgs, 'id'>>;
  documents?: Resolver<ResolversTypes['DocumentsWithPagination'], ParentType, ContextType, RequireFields<QueryDocumentsArgs, 'filterData' | 'pagination'>>;
  generateStripePortalLink?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hashtag?: Resolver<Maybe<ResolversTypes['Hashtag']>, ParentType, ContextType, RequireFields<QueryHashtagArgs, 'id'>>;
  hashtags?: Resolver<ResolversTypes['HashtagsWithPagination'], ParentType, ContextType, RequireFields<QueryHashtagsArgs, 'filterData' | 'pagination'>>;
  me?: Resolver<Maybe<ResolversTypes['AuthSession']>, ParentType, ContextType>;
  sourceDocument?: Resolver<Maybe<ResolversTypes['SourceDocument']>, ParentType, ContextType, RequireFields<QuerySourceDocumentArgs, 'id'>>;
  stripeProducts?: Resolver<Array<ResolversTypes['StripeProduct']>, ParentType, ContextType>;
  survey?: Resolver<Maybe<ResolversTypes['Survey']>, ParentType, ContextType, RequireFields<QuerySurveyArgs, 'id'>>;
  surveySubmits?: Resolver<ResolversTypes['SurveySubmitsWithPagination'], ParentType, ContextType, RequireFields<QuerySurveySubmitsArgs, 'filterData' | 'pagination'>>;
  surveys?: Resolver<ResolversTypes['SurveysWithPagination'], ParentType, ContextType, RequireFields<QuerySurveysArgs, 'filterData' | 'pagination'>>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryUserArgs, 'id'>>;
  users?: Resolver<ResolversTypes['UsersWithPagination'], ParentType, ContextType, RequireFields<QueryUsersArgs, 'pagination'>>;
}>;

export type S3FileResolvers<ContextType = any, ParentType extends ResolversParentTypes['S3File'] = ResolversParentTypes['S3File']> = ResolversObject<{
  Uploader?: Resolver<Maybe<ResolversTypes['Uploader']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isPublic?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  signExpiresAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  signedUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  size?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  thumbnailSignedUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<S3FileThumbnailSignedUrlArgs, 'size'>>;
  type?: Resolver<ResolversTypes['FileTypeEnum'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type S3PayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['S3Payload'] = ResolversParentTypes['S3Payload']> = ResolversObject<{
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  signedRequest?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SourceDocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['SourceDocument'] = ResolversParentTypes['SourceDocument']> = ResolversObject<{
  S3File?: Resolver<Maybe<ResolversTypes['S3File']>, ParentType, ContextType>;
  SourceDocumentContents?: Resolver<Array<ResolversTypes['SourceDocumentContent']>, ParentType, ContextType>;
  SourceDocumentProcessedContents?: Resolver<Array<ResolversTypes['SourceDocumentProcessedContent']>, ParentType, ContextType>;
  documentType?: Resolver<ResolversTypes['DocumentTypeEnum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SourceDocumentContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['SourceDocumentContent'] = ResolversParentTypes['SourceDocumentContent']> = ResolversObject<{
  documentType?: Resolver<ResolversTypes['DocumentTypeEnum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SourceDocumentProcessedContentResolvers<ContextType = any, ParentType extends ResolversParentTypes['SourceDocumentProcessedContent'] = ResolversParentTypes['SourceDocumentProcessedContent']> = ResolversObject<{
  documentType?: Resolver<ResolversTypes['DocumentTypeEnum'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  index?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  key?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['SourceDocumentProcessedContentTypeEnum'], ParentType, ContextType>;
  value?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StripePriceResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripePrice'] = ResolversParentTypes['StripePrice']> = ResolversObject<{
  StripeProduct?: Resolver<Maybe<ResolversTypes['StripeProduct']>, ParentType, ContextType>;
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  currency?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  interval?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  intervalCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  trialPeriodDays?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  unitAmount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type StripeProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['StripeProduct'] = ResolversParentTypes['StripeProduct']> = ResolversObject<{
  StripePrices?: Resolver<Array<ResolversTypes['StripePrice']>, ParentType, ContextType>;
  active?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  images?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  metadata?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SubscriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Subscription'] = ResolversParentTypes['Subscription']> = ResolversObject<{
  StripePrice?: SubscriptionResolver<Maybe<ResolversTypes['StripePrice']>, "StripePrice", ParentType, ContextType>;
  StripeProduct?: SubscriptionResolver<Maybe<ResolversTypes['StripeProduct']>, "StripeProduct", ParentType, ContextType>;
  User?: SubscriptionResolver<Maybe<ResolversTypes['User']>, "User", ParentType, ContextType>;
  cancelAt?: SubscriptionResolver<Maybe<ResolversTypes['DateTime']>, "cancelAt", ParentType, ContextType>;
  canceledAt?: SubscriptionResolver<Maybe<ResolversTypes['DateTime']>, "canceledAt", ParentType, ContextType>;
  created?: SubscriptionResolver<Maybe<ResolversTypes['DateTime']>, "created", ParentType, ContextType>;
  createdAt?: SubscriptionResolver<ResolversTypes['DateTime'], "createdAt", ParentType, ContextType>;
  credits?: SubscriptionResolver<ResolversTypes['Int'], "credits", ParentType, ContextType>;
  creditsAtPeriodStart?: SubscriptionResolver<ResolversTypes['Int'], "creditsAtPeriodStart", ParentType, ContextType>;
  currentPeriodEnd?: SubscriptionResolver<Maybe<ResolversTypes['DateTime']>, "currentPeriodEnd", ParentType, ContextType>;
  currentPeriodStart?: SubscriptionResolver<Maybe<ResolversTypes['DateTime']>, "currentPeriodStart", ParentType, ContextType>;
  endedAt?: SubscriptionResolver<Maybe<ResolversTypes['DateTime']>, "endedAt", ParentType, ContextType>;
  id?: SubscriptionResolver<ResolversTypes['ID'], "id", ParentType, ContextType>;
  plan?: SubscriptionResolver<Maybe<ResolversTypes['SubscriptionPlan']>, "plan", ParentType, ContextType>;
  status?: SubscriptionResolver<Maybe<ResolversTypes['String']>, "status", ParentType, ContextType>;
  updatedAt?: SubscriptionResolver<ResolversTypes['DateTime'], "updatedAt", ParentType, ContextType>;
  userId?: SubscriptionResolver<Maybe<ResolversTypes['String']>, "userId", ParentType, ContextType>;
}>;

export type SurveyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Survey'] = ResolversParentTypes['Survey']> = ResolversObject<{
  Character?: Resolver<ResolversTypes['Character'], ParentType, ContextType>;
  User?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  metaData?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  numOfSurveySubmits?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  title?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SurveySubmitResolvers<ContextType = any, ParentType extends ResolversParentTypes['SurveySubmit'] = ResolversParentTypes['SurveySubmit']> = ResolversObject<{
  Conversation?: Resolver<ResolversTypes['Conversation'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isFinished?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  metaData?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SurveySubmitsWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['SurveySubmitsWithPagination'] = ResolversParentTypes['SurveySubmitsWithPagination']> = ResolversObject<{
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  surveySubmits?: Resolver<Array<ResolversTypes['SurveySubmit']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type SurveysWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['SurveysWithPagination'] = ResolversParentTypes['SurveysWithPagination']> = ResolversObject<{
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  surveys?: Resolver<Array<ResolversTypes['Survey']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UploaderResolvers<ContextType = any, ParentType extends ResolversParentTypes['Uploader'] = ResolversParentTypes['Uploader']> = ResolversObject<{
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['GenderEnum']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = ResolversObject<{
  Confirmations?: Resolver<Array<ResolversTypes['Confirmation']>, ParentType, ContextType, Partial<UserConfirmationsArgs>>;
  Subscription?: Resolver<ResolversTypes['Subscription'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['GenderEnum']>, ParentType, ContextType>;
  hasPassword?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  openAIToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<ResolversTypes['UserRoleEnum'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UsersWithPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['UsersWithPagination'] = ResolversParentTypes['UsersWithPagination']> = ResolversObject<{
  pagination?: Resolver<ResolversTypes['Pagination'], ParentType, ContextType>;
  users?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = any> = ResolversObject<{
  AuthSession?: AuthSessionResolvers<ContextType>;
  Author?: AuthorResolvers<ContextType>;
  Character?: CharacterResolvers<ContextType>;
  CharacterEmbed?: CharacterEmbedResolvers<ContextType>;
  CharacterEmbedsWithPagination?: CharacterEmbedsWithPaginationResolvers<ContextType>;
  CharacterImagesWithPagination?: CharacterImagesWithPaginationResolvers<ContextType>;
  CharactersWithPagination?: CharactersWithPaginationResolvers<ContextType>;
  CheckoutSession?: CheckoutSessionResolvers<ContextType>;
  CommunityPost?: CommunityPostResolvers<ContextType>;
  CommunityPostComment?: CommunityPostCommentResolvers<ContextType>;
  CommunityPostCommentsWithPagination?: CommunityPostCommentsWithPaginationResolvers<ContextType>;
  CommunityPostsWithPagination?: CommunityPostsWithPaginationResolvers<ContextType>;
  Confirmation?: ConfirmationResolvers<ContextType>;
  Conversation?: ConversationResolvers<ContextType>;
  ConversationMessage?: ConversationMessageResolvers<ContextType>;
  ConversationMessagesWithPagination?: ConversationMessagesWithPaginationResolvers<ContextType>;
  ConversationsWithPagination?: ConversationsWithPaginationResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  Document?: DocumentResolvers<ContextType>;
  DocumentsWithPagination?: DocumentsWithPaginationResolvers<ContextType>;
  Hashtag?: HashtagResolvers<ContextType>;
  HashtagsWithPagination?: HashtagsWithPaginationResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Pagination?: PaginationResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  S3File?: S3FileResolvers<ContextType>;
  S3Payload?: S3PayloadResolvers<ContextType>;
  SourceDocument?: SourceDocumentResolvers<ContextType>;
  SourceDocumentContent?: SourceDocumentContentResolvers<ContextType>;
  SourceDocumentProcessedContent?: SourceDocumentProcessedContentResolvers<ContextType>;
  StripePrice?: StripePriceResolvers<ContextType>;
  StripeProduct?: StripeProductResolvers<ContextType>;
  Subscription?: SubscriptionResolvers<ContextType>;
  Survey?: SurveyResolvers<ContextType>;
  SurveySubmit?: SurveySubmitResolvers<ContextType>;
  SurveySubmitsWithPagination?: SurveySubmitsWithPaginationResolvers<ContextType>;
  SurveysWithPagination?: SurveysWithPaginationResolvers<ContextType>;
  Uploader?: UploaderResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UsersWithPagination?: UsersWithPaginationResolvers<ContextType>;
}>;


export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'AuthSession', accessToken: string, User: { __typename?: 'User', id: string, email: string, role: UserRoleEnum, hasPassword: boolean, firstName?: string | null, lastName?: string | null, gender?: GenderEnum | null, openAIToken?: string | null, Confirmations: Array<{ __typename?: 'Confirmation', id: string, type: ConfirmationTypeEnum }>, Subscription: { __typename?: 'Subscription', id: string, currentPeriodStart?: string | null, currentPeriodEnd?: string | null, cancelAt?: string | null, canceledAt?: string | null, endedAt?: string | null, status?: string | null, credits: number, creditsAtPeriodStart: number, plan?: SubscriptionPlan | null, StripeProduct?: { __typename?: 'StripeProduct', id: string, name: string } | null } } } | null };

export type SignOutMutationVariables = Exact<{
  accessToken?: InputMaybe<Scalars['String']>;
}>;


export type SignOutMutation = { __typename?: 'Mutation', signOut: boolean };

export type SignUpMutationVariables = Exact<{
  inputData: SignUpInput;
}>;


export type SignUpMutation = { __typename?: 'Mutation', signUp: boolean };

export type SignInWithPasswordMutationVariables = Exact<{
  inputData: SignInWithPasswordInput;
}>;


export type SignInWithPasswordMutation = { __typename?: 'Mutation', signInWithPassword: { __typename?: 'AuthSession', accessToken: string, User: { __typename?: 'User', id: string, email: string, role: UserRoleEnum, hasPassword: boolean, firstName?: string | null, lastName?: string | null, gender?: GenderEnum | null, openAIToken?: string | null, Confirmations: Array<{ __typename?: 'Confirmation', id: string, type: ConfirmationTypeEnum }>, Subscription: { __typename?: 'Subscription', id: string, currentPeriodStart?: string | null, currentPeriodEnd?: string | null, cancelAt?: string | null, canceledAt?: string | null, endedAt?: string | null, status?: string | null, credits: number, creditsAtPeriodStart: number, plan?: SubscriptionPlan | null, StripeProduct?: { __typename?: 'StripeProduct', id: string, name: string } | null } } } };

export type SignInWithMagicLinkMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type SignInWithMagicLinkMutation = { __typename?: 'Mutation', signInWithMagicLink: { __typename?: 'AuthSession', accessToken: string, User: { __typename?: 'User', id: string, email: string, role: UserRoleEnum, hasPassword: boolean, firstName?: string | null, lastName?: string | null, gender?: GenderEnum | null, openAIToken?: string | null, Confirmations: Array<{ __typename?: 'Confirmation', id: string, type: ConfirmationTypeEnum }>, Subscription: { __typename?: 'Subscription', id: string, currentPeriodStart?: string | null, currentPeriodEnd?: string | null, cancelAt?: string | null, canceledAt?: string | null, endedAt?: string | null, status?: string | null, credits: number, creditsAtPeriodStart: number, plan?: SubscriptionPlan | null, StripeProduct?: { __typename?: 'StripeProduct', id: string, name: string } | null } } } };

export type RequestMagicLinkMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RequestMagicLinkMutation = { __typename?: 'Mutation', requestMagicLink: boolean };

export type UpdatePasswordMutationVariables = Exact<{
  inputData: UpdatePasswordInput;
}>;


export type UpdatePasswordMutation = { __typename?: 'Mutation', updatePassword: boolean };

export type RequestPasswordResetMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RequestPasswordResetMutation = { __typename?: 'Mutation', requestPasswordReset: boolean };

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: boolean };

export type PasswordSignUpMutationVariables = Exact<{
  inputData: PasswordSignUpInput;
}>;


export type PasswordSignUpMutation = { __typename?: 'Mutation', passwordSignUp: { __typename?: 'AuthSession', accessToken: string, User: { __typename?: 'User', id: string, email: string, role: UserRoleEnum, hasPassword: boolean, firstName?: string | null, lastName?: string | null, gender?: GenderEnum | null, openAIToken?: string | null, Confirmations: Array<{ __typename?: 'Confirmation', id: string, type: ConfirmationTypeEnum }>, Subscription: { __typename?: 'Subscription', id: string, currentPeriodStart?: string | null, currentPeriodEnd?: string | null, cancelAt?: string | null, canceledAt?: string | null, endedAt?: string | null, status?: string | null, credits: number, creditsAtPeriodStart: number, plan?: SubscriptionPlan | null, StripeProduct?: { __typename?: 'StripeProduct', id: string, name: string } | null } } } };

export type CharacterQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CharacterQuery = { __typename?: 'Query', character?: { __typename?: 'Character', id: string, title: string, metaData: string, gender: GenderEnum, isPublic: boolean, ImageS3File?: { __typename?: 'S3File', key: string, thumbnailSignedUrl?: string | null } | null } | null };

export type CharactersQueryVariables = Exact<{
  filterData: CharactersFilterInput;
  pagination: PaginationInput;
}>;


export type CharactersQuery = { __typename?: 'Query', characters: { __typename?: 'CharactersWithPagination', characters: Array<{ __typename?: 'Character', id: string, title: string, metaData: string, gender: GenderEnum, ImageS3File?: { __typename?: 'S3File', thumbnailSignedUrl?: string | null } | null }>, pagination: { __typename?: 'Pagination', total: number } } };

export type CreateCharacterMutationVariables = Exact<{
  inputData: CharacterInput;
}>;


export type CreateCharacterMutation = { __typename?: 'Mutation', createCharacter?: { __typename?: 'Character', id: string, title: string, metaData: string } | null };

export type EditCharacterMutationVariables = Exact<{
  id: Scalars['ID'];
  inputData: CharacterInput;
}>;


export type EditCharacterMutation = { __typename?: 'Mutation', editCharacter?: { __typename?: 'Character', id: string, title: string, metaData: string } | null };

export type CreateCharacterImageMutationVariables = Exact<{
  inputData: CharacterImageInput;
}>;


export type CreateCharacterImageMutation = { __typename?: 'Mutation', createCharacterImage: { __typename?: 'S3File', key: string } };

export type CharacterImagesQueryVariables = Exact<{
  filterData: CharacterImagesFilterInput;
  pagination: PaginationInput;
}>;


export type CharacterImagesQuery = { __typename?: 'Query', characterImages: { __typename?: 'CharacterImagesWithPagination', characterImages: Array<{ __typename?: 'S3File', key: string, thumbnailSignedUrl?: string | null }>, pagination: { __typename?: 'Pagination', length: number, total: number } } };

export type CharacterEmbedQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CharacterEmbedQuery = { __typename?: 'Query', characterEmbed?: { __typename?: 'CharacterEmbed', id: string, title: string, metaData: string, numOfConversations: number, Character: { __typename?: 'Character', id: string, title: string, metaData: string, gender: GenderEnum, ImageS3File?: { __typename?: 'S3File', key: string, thumbnailSignedUrl?: string | null } | null } } | null };

export type CharacterEmbedsQueryVariables = Exact<{
  filterData: CharacterEmbedsFilterInput;
  pagination: PaginationInput;
}>;


export type CharacterEmbedsQuery = { __typename?: 'Query', characterEmbeds: { __typename?: 'CharacterEmbedsWithPagination', characterEmbeds: Array<{ __typename?: 'CharacterEmbed', id: string, title: string, Character: { __typename?: 'Character', id: string, title: string, metaData: string, gender: GenderEnum, ImageS3File?: { __typename?: 'S3File', thumbnailSignedUrl?: string | null } | null } }>, pagination: { __typename?: 'Pagination', total: number } } };

export type CreateCharacterEmbedMutationVariables = Exact<{
  inputData: CharacterEmbedInput;
}>;


export type CreateCharacterEmbedMutation = { __typename?: 'Mutation', createCharacterEmbed?: { __typename?: 'CharacterEmbed', id: string, title: string } | null };

export type EditCharacterEmbedMutationVariables = Exact<{
  id: Scalars['ID'];
  inputData: CharacterEmbedInput;
}>;


export type EditCharacterEmbedMutation = { __typename?: 'Mutation', editCharacterEmbed?: { __typename?: 'CharacterEmbed', id: string, title: string } | null };

export type CommunityPostQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CommunityPostQuery = { __typename?: 'Query', communityPost?: { __typename?: 'CommunityPost', id: string, createdAt: string, updatedAt: string, title: string, type: CommunityPostType, content: string, commentCount: number, likeCount: number, hasCurrentUserLiked: boolean, Author: { __typename?: 'Author', id: string, firstName?: string | null, lastName?: string | null } } | null };

export type CommunityPostsQueryVariables = Exact<{
  pagination: PaginationInput;
}>;


export type CommunityPostsQuery = { __typename?: 'Query', communityPosts: { __typename?: 'CommunityPostsWithPagination', communityPosts: Array<{ __typename?: 'CommunityPost', id: string, createdAt: string, updatedAt: string, title: string, type: CommunityPostType, content: string, commentCount: number, likeCount: number, hasCurrentUserLiked: boolean, Author: { __typename?: 'Author', id: string, firstName?: string | null, lastName?: string | null } }>, pagination: { __typename?: 'Pagination', from: number, length: number, total: number } } };

export type CreateCommunityPostMutationVariables = Exact<{
  inputData: CommunityPostInput;
}>;


export type CreateCommunityPostMutation = { __typename?: 'Mutation', createCommunityPost?: { __typename?: 'CommunityPost', id: string } | null };

export type UpdateCommunityPostMutationVariables = Exact<{
  inputData: CommunityPostInput;
  id: Scalars['ID'];
}>;


export type UpdateCommunityPostMutation = { __typename?: 'Mutation', updateCommunityPost?: { __typename?: 'CommunityPost', id: string } | null };

export type DeleteCommunityPostMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCommunityPostMutation = { __typename?: 'Mutation', deleteCommunityPost: boolean };

export type LikeCommunityPostMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LikeCommunityPostMutation = { __typename?: 'Mutation', likeCommunityPost?: { __typename?: 'CommunityPost', id: string } | null };

export type UnlikeCommunityPostMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnlikeCommunityPostMutation = { __typename?: 'Mutation', unlikeCommunityPost?: { __typename?: 'CommunityPost', id: string } | null };

export type CommunityPostCommentsQueryVariables = Exact<{
  pagination: PaginationInput;
  communityPostId: Scalars['String'];
}>;


export type CommunityPostCommentsQuery = { __typename?: 'Query', communityPostComments: { __typename?: 'CommunityPostCommentsWithPagination', communityPostComments: Array<{ __typename?: 'CommunityPostComment', id: string, createdAt: string, updatedAt: string, content: string, likeCount: number, hasCurrentUserLiked: boolean, parentCommunityPostCommentId?: string | null, replies: Array<{ __typename?: 'CommunityPostComment', id: string, createdAt: string, updatedAt: string, content: string, likeCount: number, hasCurrentUserLiked: boolean, Author: { __typename?: 'Author', id: string, firstName?: string | null, lastName?: string | null } }>, Author: { __typename?: 'Author', id: string, firstName?: string | null, lastName?: string | null } }>, pagination: { __typename?: 'Pagination', from: number, length: number, total: number } } };

export type CommunityPostCommentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CommunityPostCommentQuery = { __typename?: 'Query', communityPostComment?: { __typename?: 'CommunityPostComment', id: string, createdAt: string, updatedAt: string, content: string, likeCount: number, hasCurrentUserLiked: boolean, parentCommunityPostCommentId?: string | null, replies: Array<{ __typename?: 'CommunityPostComment', id: string, createdAt: string, updatedAt: string, content: string, likeCount: number, hasCurrentUserLiked: boolean, Author: { __typename?: 'Author', id: string, firstName?: string | null, lastName?: string | null } }>, Author: { __typename?: 'Author', id: string, firstName?: string | null, lastName?: string | null } } | null };

export type CreateCommunityPostCommentMutationVariables = Exact<{
  inputData: CommunityPostCommentInput;
}>;


export type CreateCommunityPostCommentMutation = { __typename?: 'Mutation', createCommunityPostComment: { __typename?: 'CommunityPostComment', id: string } };

export type UpdateCommunityPostCommentMutationVariables = Exact<{
  inputData: CommunityPostCommentInput;
  id: Scalars['ID'];
}>;


export type UpdateCommunityPostCommentMutation = { __typename?: 'Mutation', updateCommunityPostComment?: { __typename?: 'CommunityPostComment', id: string } | null };

export type LikeCommunityPostCommentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LikeCommunityPostCommentMutation = { __typename?: 'Mutation', likeCommunityPostComment?: { __typename?: 'CommunityPostComment', id: string } | null };

export type UnlikeCommunityPostCommentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnlikeCommunityPostCommentMutation = { __typename?: 'Mutation', unlikeCommunityPostComment?: { __typename?: 'CommunityPostComment', id: string } | null };

export type DeleteCommunityPostCommentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCommunityPostCommentMutation = { __typename?: 'Mutation', deleteCommunityPostComment: boolean };

export type DeleteConfirmationMutationVariables = Exact<{
  deleteConfirmationId: Scalars['ID'];
}>;


export type DeleteConfirmationMutation = { __typename?: 'Mutation', deleteConfirmation: boolean };

export type CreateConversationMutationVariables = Exact<{
  inputData: ConversationInput;
}>;


export type CreateConversationMutation = { __typename?: 'Mutation', createConversation?: { __typename?: 'Conversation', id: string, title: string } | null };

export type CreateDocumentConversationMutationVariables = Exact<{
  inputData: DocumentConversationInput;
}>;


export type CreateDocumentConversationMutation = { __typename?: 'Mutation', createDocumentConversation?: { __typename?: 'Conversation', id: string, title: string } | null };

export type EditConversationMutationVariables = Exact<{
  id: Scalars['ID'];
  inputData: EditConversationInput;
}>;


export type EditConversationMutation = { __typename?: 'Mutation', editConversation?: { __typename?: 'Conversation', id: string, title: string } | null };

export type DeleteConversationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteConversationMutation = { __typename?: 'Mutation', deleteConversation: boolean };

export type ConversationsQueryVariables = Exact<{
  filterData: ConversationsFilterInput;
  pagination: PaginationInput;
}>;


export type ConversationsQuery = { __typename?: 'Query', conversations: { __typename?: 'ConversationsWithPagination', conversations: Array<{ __typename?: 'Conversation', id: string, title: string, type: ConversationTypeEnum, updatedAt: string, lastMessage?: string | null, Character: { __typename?: 'Character', id: string, title: string, ImageS3File?: { __typename?: 'S3File', thumbnailSignedUrl?: string | null } | null } }>, pagination: { __typename?: 'Pagination', from: number, total: number } } };

export type ConversationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ConversationQuery = { __typename?: 'Query', conversation?: { __typename?: 'Conversation', id: string, title: string, metaData: string, type: ConversationTypeEnum, SourceDocument?: { __typename?: 'SourceDocument', id: string } | null, Character: { __typename?: 'Character', id: string, title: string, gender: GenderEnum, ImageS3File?: { __typename?: 'S3File', thumbnailSignedUrl?: string | null } | null }, SurveySubmit?: { __typename?: 'SurveySubmit', id: string, isFinished: boolean } | null } | null };

export type ConversationMessagesQueryVariables = Exact<{
  filterData: ConversationMessagesFilterInput;
  pagination: PaginationInput;
  includeConversation?: InputMaybe<Scalars['Boolean']>;
}>;


export type ConversationMessagesQuery = { __typename?: 'Query', conversationMessages: { __typename?: 'ConversationMessagesWithPagination', conversationMessages: Array<{ __typename?: 'ConversationMessage', id: string, createdAt: string, text: string, author: ConversationMessageAuthorEnum, isBookmarked: boolean, Conversation?: { __typename?: 'Conversation', id: string, title: string, Character: { __typename?: 'Character', id: string, title: string, ImageS3File?: { __typename?: 'S3File', thumbnailSignedUrl?: string | null } | null } } }>, pagination: { __typename?: 'Pagination', total: number, from: number, length: number } } };

export type SendMessageMutationVariables = Exact<{
  inputData: ConversationMessageInput;
}>;


export type SendMessageMutation = { __typename?: 'Mutation', sendMessage?: string | null };

export type EditConversationMessageMutationVariables = Exact<{
  id: Scalars['ID'];
  inputData: EditConversationMessageInput;
}>;


export type EditConversationMessageMutation = { __typename?: 'Mutation', editConversationMessage?: { __typename?: 'ConversationMessage', id: string, createdAt: string, text: string, author: ConversationMessageAuthorEnum, isBookmarked: boolean } | null };

export type ClaimConversationsMutationVariables = Exact<{
  inputData: ClaimConversationsInput;
}>;


export type ClaimConversationsMutation = { __typename?: 'Mutation', claimConversations: boolean };

export type TurnConversationIntoDocumentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TurnConversationIntoDocumentMutation = { __typename?: 'Mutation', turnConversationIntoDocument: string };

export type DocumentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DocumentQuery = { __typename?: 'Query', document?: { __typename?: 'Document', id: string, createdAt: string, updatedAt: string, title: string, content: string } | null };

export type DocumentsQueryVariables = Exact<{
  filterData: DocumentFilterInput;
  pagination: PaginationInput;
}>;


export type DocumentsQuery = { __typename?: 'Query', documents: { __typename?: 'DocumentsWithPagination', documents: Array<{ __typename?: 'Document', id: string, createdAt: string, updatedAt: string, title: string }>, pagination: { __typename?: 'Pagination', from: number, length: number, total: number } } };

export type CreateDocumentMutationVariables = Exact<{
  inputData: DocumentInput;
}>;


export type CreateDocumentMutation = { __typename?: 'Mutation', createDocument?: { __typename?: 'Document', id: string } | null };

export type UpdateDocumentMutationVariables = Exact<{
  id: Scalars['ID'];
  inputData: DocumentInput;
}>;


export type UpdateDocumentMutation = { __typename?: 'Mutation', updateDocument: { __typename?: 'Document', id: string } };

export type DeleteDocumentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDocumentMutation = { __typename?: 'Mutation', deleteDocument?: { __typename?: 'Document', id: string } | null };

export type HashtagQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type HashtagQuery = { __typename?: 'Query', hashtag?: { __typename?: 'Hashtag', id: string, createdAt: string, updatedAt: string, parentHashtagId?: string | null } | null };

export type HashtagsQueryVariables = Exact<{
  filterData: HashtagFilterInput;
  pagination: PaginationInput;
}>;


export type HashtagsQuery = { __typename?: 'Query', hashtags: { __typename?: 'HashtagsWithPagination', hashtags: Array<{ __typename?: 'Hashtag', id: string, createdAt: string, updatedAt: string, parentHashtagId?: string | null }>, pagination: { __typename?: 'Pagination', from: number, length: number, total: number } } };

export type CreateHashtagMutationVariables = Exact<{
  inputData: HashtagInput;
}>;


export type CreateHashtagMutation = { __typename?: 'Mutation', createHashtag: boolean };

export type UpdateHashtagMutationVariables = Exact<{
  id: Scalars['ID'];
  inputData: HashtagInput;
}>;


export type UpdateHashtagMutation = { __typename?: 'Mutation', updateHashtag: boolean };

export type DeleteHashtagMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteHashtagMutation = { __typename?: 'Mutation', deleteHashtag: boolean };

export type FileUploadS3PresignMutationVariables = Exact<{
  fileName: Scalars['String'];
  fileType: FileTypeEnum;
  contentType: Scalars['String'];
  fileSize: Scalars['Int'];
  isPublic?: InputMaybe<Scalars['Boolean']>;
}>;


export type FileUploadS3PresignMutation = { __typename?: 'Mutation', fileUploadS3Presign: { __typename?: 'S3Payload', signedRequest: string, key: string } };

export type SourceDocumentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SourceDocumentQuery = { __typename?: 'Query', sourceDocument?: { __typename?: 'SourceDocument', id: string, SourceDocumentContents: Array<{ __typename?: 'SourceDocumentContent', id: string, index: number, key: string, value: string }>, SourceDocumentProcessedContents: Array<{ __typename?: 'SourceDocumentProcessedContent', id: string, index: number, key: string, value: string, type: SourceDocumentProcessedContentTypeEnum }> } | null };

export type CreateSourceDocumentMutationVariables = Exact<{
  inputData: SourceDocumentInput;
}>;


export type CreateSourceDocumentMutation = { __typename?: 'Mutation', createSourceDocument?: { __typename?: 'SourceDocument', id: string } | null };

export type CreateSourceDocumentProcessedContentMutationVariables = Exact<{
  inputData: SourceDocumentProcessedContentInput;
}>;


export type CreateSourceDocumentProcessedContentMutation = { __typename?: 'Mutation', createSourceDocumentProcessedContent: boolean };

export type StripeProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type StripeProductsQuery = { __typename?: 'Query', stripeProducts: Array<{ __typename?: 'StripeProduct', id: string, createdAt: string, updatedAt: string, active: boolean, name: string, metadata: string, description: string, images: Array<string>, StripePrices: Array<{ __typename?: 'StripePrice', id: string, unitAmount?: number | null, currency: string, type: string }> }> };

export type CreateStripeCheckoutSessionMutationVariables = Exact<{
  userId: Scalars['ID'];
  priceId: Scalars['String'];
}>;


export type CreateStripeCheckoutSessionMutation = { __typename?: 'Mutation', createStripeCheckoutSession: string };

export type UpgradeStripeSubscriptionMutationVariables = Exact<{
  userId: Scalars['ID'];
  priceId: Scalars['String'];
}>;


export type UpgradeStripeSubscriptionMutation = { __typename?: 'Mutation', upgradeStripeSubscription: boolean };

export type CancelStripeSubscriptionMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type CancelStripeSubscriptionMutation = { __typename?: 'Mutation', cancelStripeSubscription: boolean };

export type GenerateStripePortalLinkQueryVariables = Exact<{ [key: string]: never; }>;


export type GenerateStripePortalLinkQuery = { __typename?: 'Query', generateStripePortalLink?: string | null };

export type SurveyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SurveyQuery = { __typename?: 'Query', survey?: { __typename?: 'Survey', id: string, title: string, metaData: string, numOfSurveySubmits: number, Character: { __typename?: 'Character', id: string, title: string, metaData: string, gender: GenderEnum, ImageS3File?: { __typename?: 'S3File', key: string, thumbnailSignedUrl?: string | null } | null } } | null };

export type SurveysQueryVariables = Exact<{
  filterData: SurveysFilterInput;
  pagination: PaginationInput;
}>;


export type SurveysQuery = { __typename?: 'Query', surveys: { __typename?: 'SurveysWithPagination', surveys: Array<{ __typename?: 'Survey', id: string, title: string, metaData: string, Character: { __typename?: 'Character', id: string, title: string, metaData: string, gender: GenderEnum, ImageS3File?: { __typename?: 'S3File', thumbnailSignedUrl?: string | null } | null } }>, pagination: { __typename?: 'Pagination', total: number } } };

export type SurveySubmitsQueryVariables = Exact<{
  filterData: SurveySubmitsFilterInput;
  pagination: PaginationInput;
}>;


export type SurveySubmitsQuery = { __typename?: 'Query', surveySubmits: { __typename?: 'SurveySubmitsWithPagination', surveySubmits: Array<{ __typename?: 'SurveySubmit', id: string, metaData: string, isFinished: boolean, Conversation: { __typename?: 'Conversation', id: string } }>, pagination: { __typename?: 'Pagination', total: number } } };

export type CreateSurveyMutationVariables = Exact<{
  inputData: SurveyInput;
}>;


export type CreateSurveyMutation = { __typename?: 'Mutation', createSurvey?: { __typename?: 'Survey', id: string, title: string } | null };

export type CreateSurveyConversationMutationVariables = Exact<{
  inputData: SurveyConversationInput;
}>;


export type CreateSurveyConversationMutation = { __typename?: 'Mutation', createSurveyConversation?: { __typename?: 'Conversation', id: string, title: string } | null };

export type EditSurveyMutationVariables = Exact<{
  id: Scalars['ID'];
  inputData: SurveyInput;
}>;


export type EditSurveyMutation = { __typename?: 'Mutation', editSurvey?: { __typename?: 'Survey', id: string, title: string } | null };

export type FinishSurveySubmitMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FinishSurveySubmitMutation = { __typename?: 'Mutation', finishSurveySubmit: { __typename?: 'SurveySubmit', id: string } };

export type UserFragmentFragment = { __typename?: 'User', id: string, email: string, role: UserRoleEnum, hasPassword: boolean, firstName?: string | null, lastName?: string | null, gender?: GenderEnum | null, openAIToken?: string | null, Confirmations: Array<{ __typename?: 'Confirmation', id: string, type: ConfirmationTypeEnum }>, Subscription: { __typename?: 'Subscription', id: string, currentPeriodStart?: string | null, currentPeriodEnd?: string | null, cancelAt?: string | null, canceledAt?: string | null, endedAt?: string | null, status?: string | null, credits: number, creditsAtPeriodStart: number, plan?: SubscriptionPlan | null, StripeProduct?: { __typename?: 'StripeProduct', id: string, name: string } | null } };

export type UserQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type UserQuery = { __typename?: 'Query', user?: { __typename?: 'User', id: string, email: string, role: UserRoleEnum, hasPassword: boolean, firstName?: string | null, lastName?: string | null, gender?: GenderEnum | null, openAIToken?: string | null, Confirmations: Array<{ __typename?: 'Confirmation', id: string, type: ConfirmationTypeEnum }>, Subscription: { __typename?: 'Subscription', id: string, currentPeriodStart?: string | null, currentPeriodEnd?: string | null, cancelAt?: string | null, canceledAt?: string | null, endedAt?: string | null, status?: string | null, credits: number, creditsAtPeriodStart: number, plan?: SubscriptionPlan | null, StripeProduct?: { __typename?: 'StripeProduct', id: string, name: string } | null } } | null };

export type UsersQueryVariables = Exact<{
  filterData?: InputMaybe<UsersFilterInput>;
  pagination: PaginationInput;
}>;


export type UsersQuery = { __typename?: 'Query', users: { __typename?: 'UsersWithPagination', users: Array<{ __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null }>, pagination: { __typename?: 'Pagination', from: number, length: number, total: number } } };

export type UpdateUserMutationVariables = Exact<{
  updateUserId: Scalars['ID'];
  inputData: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'User', id: string, email: string, role: UserRoleEnum, hasPassword: boolean, firstName?: string | null, lastName?: string | null, gender?: GenderEnum | null, openAIToken?: string | null, Confirmations: Array<{ __typename?: 'Confirmation', id: string, type: ConfirmationTypeEnum }>, Subscription: { __typename?: 'Subscription', id: string, currentPeriodStart?: string | null, currentPeriodEnd?: string | null, cancelAt?: string | null, canceledAt?: string | null, endedAt?: string | null, status?: string | null, credits: number, creditsAtPeriodStart: number, plan?: SubscriptionPlan | null, StripeProduct?: { __typename?: 'StripeProduct', id: string, name: string } | null } } | null };

export type SetUserOpenAiTokenMutationVariables = Exact<{
  id: Scalars['ID'];
  token: Scalars['String'];
}>;


export type SetUserOpenAiTokenMutation = { __typename?: 'Mutation', setUserOpenAIToken?: boolean | null };

export const UserFragmentFragmentDoc = gql`
    fragment UserFragment on User {
  id
  email
  role
  hasPassword
  firstName
  lastName
  gender
  openAIToken
  Confirmations {
    id
    type
  }
  Subscription {
    id
    currentPeriodStart
    currentPeriodEnd
    cancelAt
    canceledAt
    endedAt
    status
    credits
    creditsAtPeriodStart
    plan
    StripeProduct {
      id
      name
    }
  }
}
    `;
export const MeDocument = gql`
    query Me {
  me {
    accessToken
    User {
      ...UserFragment
    }
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const SignOutDocument = gql`
    mutation SignOut($accessToken: String) {
  signOut(accessToken: $accessToken)
}
    `;
export type SignOutMutationFn = Apollo.MutationFunction<SignOutMutation, SignOutMutationVariables>;

/**
 * __useSignOutMutation__
 *
 * To run a mutation, you first call `useSignOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signOutMutation, { data, loading, error }] = useSignOutMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useSignOutMutation(baseOptions?: Apollo.MutationHookOptions<SignOutMutation, SignOutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignOutMutation, SignOutMutationVariables>(SignOutDocument, options);
      }
export type SignOutMutationHookResult = ReturnType<typeof useSignOutMutation>;
export type SignOutMutationResult = Apollo.MutationResult<SignOutMutation>;
export type SignOutMutationOptions = Apollo.BaseMutationOptions<SignOutMutation, SignOutMutationVariables>;
export const SignUpDocument = gql`
    mutation SignUp($inputData: SignUpInput!) {
  signUp(inputData: $inputData)
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const SignInWithPasswordDocument = gql`
    mutation SignInWithPassword($inputData: SignInWithPasswordInput!) {
  signInWithPassword(inputData: $inputData) {
    accessToken
    User {
      ...UserFragment
    }
  }
}
    ${UserFragmentFragmentDoc}`;
export type SignInWithPasswordMutationFn = Apollo.MutationFunction<SignInWithPasswordMutation, SignInWithPasswordMutationVariables>;

/**
 * __useSignInWithPasswordMutation__
 *
 * To run a mutation, you first call `useSignInWithPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInWithPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInWithPasswordMutation, { data, loading, error }] = useSignInWithPasswordMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useSignInWithPasswordMutation(baseOptions?: Apollo.MutationHookOptions<SignInWithPasswordMutation, SignInWithPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInWithPasswordMutation, SignInWithPasswordMutationVariables>(SignInWithPasswordDocument, options);
      }
export type SignInWithPasswordMutationHookResult = ReturnType<typeof useSignInWithPasswordMutation>;
export type SignInWithPasswordMutationResult = Apollo.MutationResult<SignInWithPasswordMutation>;
export type SignInWithPasswordMutationOptions = Apollo.BaseMutationOptions<SignInWithPasswordMutation, SignInWithPasswordMutationVariables>;
export const SignInWithMagicLinkDocument = gql`
    mutation SignInWithMagicLink($token: String!) {
  signInWithMagicLink(token: $token) {
    accessToken
    User {
      ...UserFragment
    }
  }
}
    ${UserFragmentFragmentDoc}`;
export type SignInWithMagicLinkMutationFn = Apollo.MutationFunction<SignInWithMagicLinkMutation, SignInWithMagicLinkMutationVariables>;

/**
 * __useSignInWithMagicLinkMutation__
 *
 * To run a mutation, you first call `useSignInWithMagicLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInWithMagicLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInWithMagicLinkMutation, { data, loading, error }] = useSignInWithMagicLinkMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSignInWithMagicLinkMutation(baseOptions?: Apollo.MutationHookOptions<SignInWithMagicLinkMutation, SignInWithMagicLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInWithMagicLinkMutation, SignInWithMagicLinkMutationVariables>(SignInWithMagicLinkDocument, options);
      }
export type SignInWithMagicLinkMutationHookResult = ReturnType<typeof useSignInWithMagicLinkMutation>;
export type SignInWithMagicLinkMutationResult = Apollo.MutationResult<SignInWithMagicLinkMutation>;
export type SignInWithMagicLinkMutationOptions = Apollo.BaseMutationOptions<SignInWithMagicLinkMutation, SignInWithMagicLinkMutationVariables>;
export const RequestMagicLinkDocument = gql`
    mutation RequestMagicLink($email: String!) {
  requestMagicLink(email: $email)
}
    `;
export type RequestMagicLinkMutationFn = Apollo.MutationFunction<RequestMagicLinkMutation, RequestMagicLinkMutationVariables>;

/**
 * __useRequestMagicLinkMutation__
 *
 * To run a mutation, you first call `useRequestMagicLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestMagicLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestMagicLinkMutation, { data, loading, error }] = useRequestMagicLinkMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestMagicLinkMutation(baseOptions?: Apollo.MutationHookOptions<RequestMagicLinkMutation, RequestMagicLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestMagicLinkMutation, RequestMagicLinkMutationVariables>(RequestMagicLinkDocument, options);
      }
export type RequestMagicLinkMutationHookResult = ReturnType<typeof useRequestMagicLinkMutation>;
export type RequestMagicLinkMutationResult = Apollo.MutationResult<RequestMagicLinkMutation>;
export type RequestMagicLinkMutationOptions = Apollo.BaseMutationOptions<RequestMagicLinkMutation, RequestMagicLinkMutationVariables>;
export const UpdatePasswordDocument = gql`
    mutation UpdatePassword($inputData: UpdatePasswordInput!) {
  updatePassword(inputData: $inputData)
}
    `;
export type UpdatePasswordMutationFn = Apollo.MutationFunction<UpdatePasswordMutation, UpdatePasswordMutationVariables>;

/**
 * __useUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePasswordMutation, { data, loading, error }] = useUpdatePasswordMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePasswordMutation, UpdatePasswordMutationVariables>(UpdatePasswordDocument, options);
      }
export type UpdatePasswordMutationHookResult = ReturnType<typeof useUpdatePasswordMutation>;
export type UpdatePasswordMutationResult = Apollo.MutationResult<UpdatePasswordMutation>;
export type UpdatePasswordMutationOptions = Apollo.BaseMutationOptions<UpdatePasswordMutation, UpdatePasswordMutationVariables>;
export const RequestPasswordResetDocument = gql`
    mutation RequestPasswordReset($email: String!) {
  requestPasswordReset(email: $email)
}
    `;
export type RequestPasswordResetMutationFn = Apollo.MutationFunction<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;

/**
 * __useRequestPasswordResetMutation__
 *
 * To run a mutation, you first call `useRequestPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordResetMutation, { data, loading, error }] = useRequestPasswordResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestPasswordResetMutation(baseOptions?: Apollo.MutationHookOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>(RequestPasswordResetDocument, options);
      }
export type RequestPasswordResetMutationHookResult = ReturnType<typeof useRequestPasswordResetMutation>;
export type RequestPasswordResetMutationResult = Apollo.MutationResult<RequestPasswordResetMutation>;
export type RequestPasswordResetMutationOptions = Apollo.BaseMutationOptions<RequestPasswordResetMutation, RequestPasswordResetMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($token: String!, $newPassword: String!) {
  resetPassword(token: $token, newPassword: $newPassword)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const PasswordSignUpDocument = gql`
    mutation PasswordSignUp($inputData: PasswordSignUpInput!) {
  passwordSignUp(inputData: $inputData) {
    accessToken
    User {
      ...UserFragment
    }
  }
}
    ${UserFragmentFragmentDoc}`;
export type PasswordSignUpMutationFn = Apollo.MutationFunction<PasswordSignUpMutation, PasswordSignUpMutationVariables>;

/**
 * __usePasswordSignUpMutation__
 *
 * To run a mutation, you first call `usePasswordSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePasswordSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [passwordSignUpMutation, { data, loading, error }] = usePasswordSignUpMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function usePasswordSignUpMutation(baseOptions?: Apollo.MutationHookOptions<PasswordSignUpMutation, PasswordSignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PasswordSignUpMutation, PasswordSignUpMutationVariables>(PasswordSignUpDocument, options);
      }
export type PasswordSignUpMutationHookResult = ReturnType<typeof usePasswordSignUpMutation>;
export type PasswordSignUpMutationResult = Apollo.MutationResult<PasswordSignUpMutation>;
export type PasswordSignUpMutationOptions = Apollo.BaseMutationOptions<PasswordSignUpMutation, PasswordSignUpMutationVariables>;
export const CharacterDocument = gql`
    query Character($id: ID!) {
  character(id: $id) {
    id
    title
    metaData
    gender
    isPublic
    ImageS3File {
      key
      thumbnailSignedUrl(size: Medium)
    }
  }
}
    `;

/**
 * __useCharacterQuery__
 *
 * To run a query within a React component, call `useCharacterQuery` and pass it any options that fit your needs.
 * When your component renders, `useCharacterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCharacterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCharacterQuery(baseOptions: Apollo.QueryHookOptions<CharacterQuery, CharacterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CharacterQuery, CharacterQueryVariables>(CharacterDocument, options);
      }
export function useCharacterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CharacterQuery, CharacterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CharacterQuery, CharacterQueryVariables>(CharacterDocument, options);
        }
export type CharacterQueryHookResult = ReturnType<typeof useCharacterQuery>;
export type CharacterLazyQueryHookResult = ReturnType<typeof useCharacterLazyQuery>;
export type CharacterQueryResult = Apollo.QueryResult<CharacterQuery, CharacterQueryVariables>;
export const CharactersDocument = gql`
    query Characters($filterData: CharactersFilterInput!, $pagination: PaginationInput!) {
  characters(filterData: $filterData, pagination: $pagination) {
    characters {
      id
      title
      metaData
      gender
      ImageS3File {
        thumbnailSignedUrl(size: Medium)
      }
    }
    pagination {
      total
    }
  }
}
    `;

/**
 * __useCharactersQuery__
 *
 * To run a query within a React component, call `useCharactersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCharactersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCharactersQuery({
 *   variables: {
 *      filterData: // value for 'filterData'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCharactersQuery(baseOptions: Apollo.QueryHookOptions<CharactersQuery, CharactersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CharactersQuery, CharactersQueryVariables>(CharactersDocument, options);
      }
export function useCharactersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CharactersQuery, CharactersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CharactersQuery, CharactersQueryVariables>(CharactersDocument, options);
        }
export type CharactersQueryHookResult = ReturnType<typeof useCharactersQuery>;
export type CharactersLazyQueryHookResult = ReturnType<typeof useCharactersLazyQuery>;
export type CharactersQueryResult = Apollo.QueryResult<CharactersQuery, CharactersQueryVariables>;
export const CreateCharacterDocument = gql`
    mutation CreateCharacter($inputData: CharacterInput!) {
  createCharacter(inputData: $inputData) {
    id
    title
    metaData
  }
}
    `;
export type CreateCharacterMutationFn = Apollo.MutationFunction<CreateCharacterMutation, CreateCharacterMutationVariables>;

/**
 * __useCreateCharacterMutation__
 *
 * To run a mutation, you first call `useCreateCharacterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCharacterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCharacterMutation, { data, loading, error }] = useCreateCharacterMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useCreateCharacterMutation(baseOptions?: Apollo.MutationHookOptions<CreateCharacterMutation, CreateCharacterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCharacterMutation, CreateCharacterMutationVariables>(CreateCharacterDocument, options);
      }
export type CreateCharacterMutationHookResult = ReturnType<typeof useCreateCharacterMutation>;
export type CreateCharacterMutationResult = Apollo.MutationResult<CreateCharacterMutation>;
export type CreateCharacterMutationOptions = Apollo.BaseMutationOptions<CreateCharacterMutation, CreateCharacterMutationVariables>;
export const EditCharacterDocument = gql`
    mutation EditCharacter($id: ID!, $inputData: CharacterInput!) {
  editCharacter(id: $id, inputData: $inputData) {
    id
    title
    metaData
  }
}
    `;
export type EditCharacterMutationFn = Apollo.MutationFunction<EditCharacterMutation, EditCharacterMutationVariables>;

/**
 * __useEditCharacterMutation__
 *
 * To run a mutation, you first call `useEditCharacterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCharacterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCharacterMutation, { data, loading, error }] = useEditCharacterMutation({
 *   variables: {
 *      id: // value for 'id'
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useEditCharacterMutation(baseOptions?: Apollo.MutationHookOptions<EditCharacterMutation, EditCharacterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCharacterMutation, EditCharacterMutationVariables>(EditCharacterDocument, options);
      }
export type EditCharacterMutationHookResult = ReturnType<typeof useEditCharacterMutation>;
export type EditCharacterMutationResult = Apollo.MutationResult<EditCharacterMutation>;
export type EditCharacterMutationOptions = Apollo.BaseMutationOptions<EditCharacterMutation, EditCharacterMutationVariables>;
export const CreateCharacterImageDocument = gql`
    mutation CreateCharacterImage($inputData: CharacterImageInput!) {
  createCharacterImage(inputData: $inputData) {
    key
  }
}
    `;
export type CreateCharacterImageMutationFn = Apollo.MutationFunction<CreateCharacterImageMutation, CreateCharacterImageMutationVariables>;

/**
 * __useCreateCharacterImageMutation__
 *
 * To run a mutation, you first call `useCreateCharacterImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCharacterImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCharacterImageMutation, { data, loading, error }] = useCreateCharacterImageMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useCreateCharacterImageMutation(baseOptions?: Apollo.MutationHookOptions<CreateCharacterImageMutation, CreateCharacterImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCharacterImageMutation, CreateCharacterImageMutationVariables>(CreateCharacterImageDocument, options);
      }
export type CreateCharacterImageMutationHookResult = ReturnType<typeof useCreateCharacterImageMutation>;
export type CreateCharacterImageMutationResult = Apollo.MutationResult<CreateCharacterImageMutation>;
export type CreateCharacterImageMutationOptions = Apollo.BaseMutationOptions<CreateCharacterImageMutation, CreateCharacterImageMutationVariables>;
export const CharacterImagesDocument = gql`
    query CharacterImages($filterData: CharacterImagesFilterInput!, $pagination: PaginationInput!) {
  characterImages(filterData: $filterData, pagination: $pagination) {
    characterImages {
      key
      thumbnailSignedUrl(size: Medium)
    }
    pagination {
      length
      total
    }
  }
}
    `;

/**
 * __useCharacterImagesQuery__
 *
 * To run a query within a React component, call `useCharacterImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCharacterImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCharacterImagesQuery({
 *   variables: {
 *      filterData: // value for 'filterData'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCharacterImagesQuery(baseOptions: Apollo.QueryHookOptions<CharacterImagesQuery, CharacterImagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CharacterImagesQuery, CharacterImagesQueryVariables>(CharacterImagesDocument, options);
      }
export function useCharacterImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CharacterImagesQuery, CharacterImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CharacterImagesQuery, CharacterImagesQueryVariables>(CharacterImagesDocument, options);
        }
export type CharacterImagesQueryHookResult = ReturnType<typeof useCharacterImagesQuery>;
export type CharacterImagesLazyQueryHookResult = ReturnType<typeof useCharacterImagesLazyQuery>;
export type CharacterImagesQueryResult = Apollo.QueryResult<CharacterImagesQuery, CharacterImagesQueryVariables>;
export const CharacterEmbedDocument = gql`
    query CharacterEmbed($id: ID!) {
  characterEmbed(id: $id) {
    id
    title
    metaData
    numOfConversations
    Character {
      id
      title
      metaData
      gender
      ImageS3File {
        key
        thumbnailSignedUrl(size: Medium)
      }
    }
  }
}
    `;

/**
 * __useCharacterEmbedQuery__
 *
 * To run a query within a React component, call `useCharacterEmbedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCharacterEmbedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCharacterEmbedQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCharacterEmbedQuery(baseOptions: Apollo.QueryHookOptions<CharacterEmbedQuery, CharacterEmbedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CharacterEmbedQuery, CharacterEmbedQueryVariables>(CharacterEmbedDocument, options);
      }
export function useCharacterEmbedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CharacterEmbedQuery, CharacterEmbedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CharacterEmbedQuery, CharacterEmbedQueryVariables>(CharacterEmbedDocument, options);
        }
export type CharacterEmbedQueryHookResult = ReturnType<typeof useCharacterEmbedQuery>;
export type CharacterEmbedLazyQueryHookResult = ReturnType<typeof useCharacterEmbedLazyQuery>;
export type CharacterEmbedQueryResult = Apollo.QueryResult<CharacterEmbedQuery, CharacterEmbedQueryVariables>;
export const CharacterEmbedsDocument = gql`
    query CharacterEmbeds($filterData: CharacterEmbedsFilterInput!, $pagination: PaginationInput!) {
  characterEmbeds(filterData: $filterData, pagination: $pagination) {
    characterEmbeds {
      id
      title
      Character {
        id
        title
        metaData
        gender
        ImageS3File {
          thumbnailSignedUrl(size: Medium)
        }
      }
    }
    pagination {
      total
    }
  }
}
    `;

/**
 * __useCharacterEmbedsQuery__
 *
 * To run a query within a React component, call `useCharacterEmbedsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCharacterEmbedsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCharacterEmbedsQuery({
 *   variables: {
 *      filterData: // value for 'filterData'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCharacterEmbedsQuery(baseOptions: Apollo.QueryHookOptions<CharacterEmbedsQuery, CharacterEmbedsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CharacterEmbedsQuery, CharacterEmbedsQueryVariables>(CharacterEmbedsDocument, options);
      }
export function useCharacterEmbedsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CharacterEmbedsQuery, CharacterEmbedsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CharacterEmbedsQuery, CharacterEmbedsQueryVariables>(CharacterEmbedsDocument, options);
        }
export type CharacterEmbedsQueryHookResult = ReturnType<typeof useCharacterEmbedsQuery>;
export type CharacterEmbedsLazyQueryHookResult = ReturnType<typeof useCharacterEmbedsLazyQuery>;
export type CharacterEmbedsQueryResult = Apollo.QueryResult<CharacterEmbedsQuery, CharacterEmbedsQueryVariables>;
export const CreateCharacterEmbedDocument = gql`
    mutation CreateCharacterEmbed($inputData: CharacterEmbedInput!) {
  createCharacterEmbed(inputData: $inputData) {
    id
    title
  }
}
    `;
export type CreateCharacterEmbedMutationFn = Apollo.MutationFunction<CreateCharacterEmbedMutation, CreateCharacterEmbedMutationVariables>;

/**
 * __useCreateCharacterEmbedMutation__
 *
 * To run a mutation, you first call `useCreateCharacterEmbedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCharacterEmbedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCharacterEmbedMutation, { data, loading, error }] = useCreateCharacterEmbedMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useCreateCharacterEmbedMutation(baseOptions?: Apollo.MutationHookOptions<CreateCharacterEmbedMutation, CreateCharacterEmbedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCharacterEmbedMutation, CreateCharacterEmbedMutationVariables>(CreateCharacterEmbedDocument, options);
      }
export type CreateCharacterEmbedMutationHookResult = ReturnType<typeof useCreateCharacterEmbedMutation>;
export type CreateCharacterEmbedMutationResult = Apollo.MutationResult<CreateCharacterEmbedMutation>;
export type CreateCharacterEmbedMutationOptions = Apollo.BaseMutationOptions<CreateCharacterEmbedMutation, CreateCharacterEmbedMutationVariables>;
export const EditCharacterEmbedDocument = gql`
    mutation EditCharacterEmbed($id: ID!, $inputData: CharacterEmbedInput!) {
  editCharacterEmbed(id: $id, inputData: $inputData) {
    id
    title
  }
}
    `;
export type EditCharacterEmbedMutationFn = Apollo.MutationFunction<EditCharacterEmbedMutation, EditCharacterEmbedMutationVariables>;

/**
 * __useEditCharacterEmbedMutation__
 *
 * To run a mutation, you first call `useEditCharacterEmbedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCharacterEmbedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCharacterEmbedMutation, { data, loading, error }] = useEditCharacterEmbedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useEditCharacterEmbedMutation(baseOptions?: Apollo.MutationHookOptions<EditCharacterEmbedMutation, EditCharacterEmbedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCharacterEmbedMutation, EditCharacterEmbedMutationVariables>(EditCharacterEmbedDocument, options);
      }
export type EditCharacterEmbedMutationHookResult = ReturnType<typeof useEditCharacterEmbedMutation>;
export type EditCharacterEmbedMutationResult = Apollo.MutationResult<EditCharacterEmbedMutation>;
export type EditCharacterEmbedMutationOptions = Apollo.BaseMutationOptions<EditCharacterEmbedMutation, EditCharacterEmbedMutationVariables>;
export const CommunityPostDocument = gql`
    query CommunityPost($id: ID!) {
  communityPost(id: $id) {
    id
    createdAt
    updatedAt
    title
    type
    content
    commentCount
    likeCount
    hasCurrentUserLiked
    Author {
      id
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useCommunityPostQuery__
 *
 * To run a query within a React component, call `useCommunityPostQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityPostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityPostQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommunityPostQuery(baseOptions: Apollo.QueryHookOptions<CommunityPostQuery, CommunityPostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommunityPostQuery, CommunityPostQueryVariables>(CommunityPostDocument, options);
      }
export function useCommunityPostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommunityPostQuery, CommunityPostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommunityPostQuery, CommunityPostQueryVariables>(CommunityPostDocument, options);
        }
export type CommunityPostQueryHookResult = ReturnType<typeof useCommunityPostQuery>;
export type CommunityPostLazyQueryHookResult = ReturnType<typeof useCommunityPostLazyQuery>;
export type CommunityPostQueryResult = Apollo.QueryResult<CommunityPostQuery, CommunityPostQueryVariables>;
export const CommunityPostsDocument = gql`
    query CommunityPosts($pagination: PaginationInput!) {
  communityPosts(pagination: $pagination) {
    communityPosts {
      id
      createdAt
      updatedAt
      title
      type
      content
      commentCount
      likeCount
      hasCurrentUserLiked
      Author {
        id
        firstName
        lastName
      }
    }
    pagination {
      from
      length
      total
    }
  }
}
    `;

/**
 * __useCommunityPostsQuery__
 *
 * To run a query within a React component, call `useCommunityPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityPostsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCommunityPostsQuery(baseOptions: Apollo.QueryHookOptions<CommunityPostsQuery, CommunityPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommunityPostsQuery, CommunityPostsQueryVariables>(CommunityPostsDocument, options);
      }
export function useCommunityPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommunityPostsQuery, CommunityPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommunityPostsQuery, CommunityPostsQueryVariables>(CommunityPostsDocument, options);
        }
export type CommunityPostsQueryHookResult = ReturnType<typeof useCommunityPostsQuery>;
export type CommunityPostsLazyQueryHookResult = ReturnType<typeof useCommunityPostsLazyQuery>;
export type CommunityPostsQueryResult = Apollo.QueryResult<CommunityPostsQuery, CommunityPostsQueryVariables>;
export const CreateCommunityPostDocument = gql`
    mutation CreateCommunityPost($inputData: CommunityPostInput!) {
  createCommunityPost(inputData: $inputData) {
    id
  }
}
    `;
export type CreateCommunityPostMutationFn = Apollo.MutationFunction<CreateCommunityPostMutation, CreateCommunityPostMutationVariables>;

/**
 * __useCreateCommunityPostMutation__
 *
 * To run a mutation, you first call `useCreateCommunityPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommunityPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommunityPostMutation, { data, loading, error }] = useCreateCommunityPostMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useCreateCommunityPostMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommunityPostMutation, CreateCommunityPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommunityPostMutation, CreateCommunityPostMutationVariables>(CreateCommunityPostDocument, options);
      }
export type CreateCommunityPostMutationHookResult = ReturnType<typeof useCreateCommunityPostMutation>;
export type CreateCommunityPostMutationResult = Apollo.MutationResult<CreateCommunityPostMutation>;
export type CreateCommunityPostMutationOptions = Apollo.BaseMutationOptions<CreateCommunityPostMutation, CreateCommunityPostMutationVariables>;
export const UpdateCommunityPostDocument = gql`
    mutation UpdateCommunityPost($inputData: CommunityPostInput!, $id: ID!) {
  updateCommunityPost(inputData: $inputData, id: $id) {
    id
  }
}
    `;
export type UpdateCommunityPostMutationFn = Apollo.MutationFunction<UpdateCommunityPostMutation, UpdateCommunityPostMutationVariables>;

/**
 * __useUpdateCommunityPostMutation__
 *
 * To run a mutation, you first call `useUpdateCommunityPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommunityPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommunityPostMutation, { data, loading, error }] = useUpdateCommunityPostMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateCommunityPostMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommunityPostMutation, UpdateCommunityPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommunityPostMutation, UpdateCommunityPostMutationVariables>(UpdateCommunityPostDocument, options);
      }
export type UpdateCommunityPostMutationHookResult = ReturnType<typeof useUpdateCommunityPostMutation>;
export type UpdateCommunityPostMutationResult = Apollo.MutationResult<UpdateCommunityPostMutation>;
export type UpdateCommunityPostMutationOptions = Apollo.BaseMutationOptions<UpdateCommunityPostMutation, UpdateCommunityPostMutationVariables>;
export const DeleteCommunityPostDocument = gql`
    mutation DeleteCommunityPost($id: ID!) {
  deleteCommunityPost(id: $id)
}
    `;
export type DeleteCommunityPostMutationFn = Apollo.MutationFunction<DeleteCommunityPostMutation, DeleteCommunityPostMutationVariables>;

/**
 * __useDeleteCommunityPostMutation__
 *
 * To run a mutation, you first call `useDeleteCommunityPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommunityPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommunityPostMutation, { data, loading, error }] = useDeleteCommunityPostMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommunityPostMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommunityPostMutation, DeleteCommunityPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommunityPostMutation, DeleteCommunityPostMutationVariables>(DeleteCommunityPostDocument, options);
      }
export type DeleteCommunityPostMutationHookResult = ReturnType<typeof useDeleteCommunityPostMutation>;
export type DeleteCommunityPostMutationResult = Apollo.MutationResult<DeleteCommunityPostMutation>;
export type DeleteCommunityPostMutationOptions = Apollo.BaseMutationOptions<DeleteCommunityPostMutation, DeleteCommunityPostMutationVariables>;
export const LikeCommunityPostDocument = gql`
    mutation LikeCommunityPost($id: ID!) {
  likeCommunityPost(id: $id) {
    id
  }
}
    `;
export type LikeCommunityPostMutationFn = Apollo.MutationFunction<LikeCommunityPostMutation, LikeCommunityPostMutationVariables>;

/**
 * __useLikeCommunityPostMutation__
 *
 * To run a mutation, you first call `useLikeCommunityPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeCommunityPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeCommunityPostMutation, { data, loading, error }] = useLikeCommunityPostMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLikeCommunityPostMutation(baseOptions?: Apollo.MutationHookOptions<LikeCommunityPostMutation, LikeCommunityPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LikeCommunityPostMutation, LikeCommunityPostMutationVariables>(LikeCommunityPostDocument, options);
      }
export type LikeCommunityPostMutationHookResult = ReturnType<typeof useLikeCommunityPostMutation>;
export type LikeCommunityPostMutationResult = Apollo.MutationResult<LikeCommunityPostMutation>;
export type LikeCommunityPostMutationOptions = Apollo.BaseMutationOptions<LikeCommunityPostMutation, LikeCommunityPostMutationVariables>;
export const UnlikeCommunityPostDocument = gql`
    mutation UnlikeCommunityPost($id: ID!) {
  unlikeCommunityPost(id: $id) {
    id
  }
}
    `;
export type UnlikeCommunityPostMutationFn = Apollo.MutationFunction<UnlikeCommunityPostMutation, UnlikeCommunityPostMutationVariables>;

/**
 * __useUnlikeCommunityPostMutation__
 *
 * To run a mutation, you first call `useUnlikeCommunityPostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlikeCommunityPostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlikeCommunityPostMutation, { data, loading, error }] = useUnlikeCommunityPostMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnlikeCommunityPostMutation(baseOptions?: Apollo.MutationHookOptions<UnlikeCommunityPostMutation, UnlikeCommunityPostMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlikeCommunityPostMutation, UnlikeCommunityPostMutationVariables>(UnlikeCommunityPostDocument, options);
      }
export type UnlikeCommunityPostMutationHookResult = ReturnType<typeof useUnlikeCommunityPostMutation>;
export type UnlikeCommunityPostMutationResult = Apollo.MutationResult<UnlikeCommunityPostMutation>;
export type UnlikeCommunityPostMutationOptions = Apollo.BaseMutationOptions<UnlikeCommunityPostMutation, UnlikeCommunityPostMutationVariables>;
export const CommunityPostCommentsDocument = gql`
    query CommunityPostComments($pagination: PaginationInput!, $communityPostId: String!) {
  communityPostComments(
    pagination: $pagination
    communityPostId: $communityPostId
  ) {
    communityPostComments {
      id
      createdAt
      updatedAt
      content
      likeCount
      hasCurrentUserLiked
      parentCommunityPostCommentId
      replies {
        id
        createdAt
        updatedAt
        content
        likeCount
        hasCurrentUserLiked
        Author {
          id
          firstName
          lastName
        }
      }
      Author {
        id
        firstName
        lastName
      }
    }
    pagination {
      from
      length
      total
    }
  }
}
    `;

/**
 * __useCommunityPostCommentsQuery__
 *
 * To run a query within a React component, call `useCommunityPostCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityPostCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityPostCommentsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      communityPostId: // value for 'communityPostId'
 *   },
 * });
 */
export function useCommunityPostCommentsQuery(baseOptions: Apollo.QueryHookOptions<CommunityPostCommentsQuery, CommunityPostCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommunityPostCommentsQuery, CommunityPostCommentsQueryVariables>(CommunityPostCommentsDocument, options);
      }
export function useCommunityPostCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommunityPostCommentsQuery, CommunityPostCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommunityPostCommentsQuery, CommunityPostCommentsQueryVariables>(CommunityPostCommentsDocument, options);
        }
export type CommunityPostCommentsQueryHookResult = ReturnType<typeof useCommunityPostCommentsQuery>;
export type CommunityPostCommentsLazyQueryHookResult = ReturnType<typeof useCommunityPostCommentsLazyQuery>;
export type CommunityPostCommentsQueryResult = Apollo.QueryResult<CommunityPostCommentsQuery, CommunityPostCommentsQueryVariables>;
export const CommunityPostCommentDocument = gql`
    query CommunityPostComment($id: ID!) {
  communityPostComment(id: $id) {
    id
    createdAt
    updatedAt
    content
    likeCount
    hasCurrentUserLiked
    parentCommunityPostCommentId
    replies {
      id
      createdAt
      updatedAt
      content
      likeCount
      hasCurrentUserLiked
      Author {
        id
        firstName
        lastName
      }
    }
    Author {
      id
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useCommunityPostCommentQuery__
 *
 * To run a query within a React component, call `useCommunityPostCommentQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommunityPostCommentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommunityPostCommentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCommunityPostCommentQuery(baseOptions: Apollo.QueryHookOptions<CommunityPostCommentQuery, CommunityPostCommentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommunityPostCommentQuery, CommunityPostCommentQueryVariables>(CommunityPostCommentDocument, options);
      }
export function useCommunityPostCommentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommunityPostCommentQuery, CommunityPostCommentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommunityPostCommentQuery, CommunityPostCommentQueryVariables>(CommunityPostCommentDocument, options);
        }
export type CommunityPostCommentQueryHookResult = ReturnType<typeof useCommunityPostCommentQuery>;
export type CommunityPostCommentLazyQueryHookResult = ReturnType<typeof useCommunityPostCommentLazyQuery>;
export type CommunityPostCommentQueryResult = Apollo.QueryResult<CommunityPostCommentQuery, CommunityPostCommentQueryVariables>;
export const CreateCommunityPostCommentDocument = gql`
    mutation CreateCommunityPostComment($inputData: CommunityPostCommentInput!) {
  createCommunityPostComment(inputData: $inputData) {
    id
  }
}
    `;
export type CreateCommunityPostCommentMutationFn = Apollo.MutationFunction<CreateCommunityPostCommentMutation, CreateCommunityPostCommentMutationVariables>;

/**
 * __useCreateCommunityPostCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommunityPostCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommunityPostCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommunityPostCommentMutation, { data, loading, error }] = useCreateCommunityPostCommentMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useCreateCommunityPostCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommunityPostCommentMutation, CreateCommunityPostCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommunityPostCommentMutation, CreateCommunityPostCommentMutationVariables>(CreateCommunityPostCommentDocument, options);
      }
export type CreateCommunityPostCommentMutationHookResult = ReturnType<typeof useCreateCommunityPostCommentMutation>;
export type CreateCommunityPostCommentMutationResult = Apollo.MutationResult<CreateCommunityPostCommentMutation>;
export type CreateCommunityPostCommentMutationOptions = Apollo.BaseMutationOptions<CreateCommunityPostCommentMutation, CreateCommunityPostCommentMutationVariables>;
export const UpdateCommunityPostCommentDocument = gql`
    mutation UpdateCommunityPostComment($inputData: CommunityPostCommentInput!, $id: ID!) {
  updateCommunityPostComment(inputData: $inputData, id: $id) {
    id
  }
}
    `;
export type UpdateCommunityPostCommentMutationFn = Apollo.MutationFunction<UpdateCommunityPostCommentMutation, UpdateCommunityPostCommentMutationVariables>;

/**
 * __useUpdateCommunityPostCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommunityPostCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommunityPostCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommunityPostCommentMutation, { data, loading, error }] = useUpdateCommunityPostCommentMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateCommunityPostCommentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommunityPostCommentMutation, UpdateCommunityPostCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommunityPostCommentMutation, UpdateCommunityPostCommentMutationVariables>(UpdateCommunityPostCommentDocument, options);
      }
export type UpdateCommunityPostCommentMutationHookResult = ReturnType<typeof useUpdateCommunityPostCommentMutation>;
export type UpdateCommunityPostCommentMutationResult = Apollo.MutationResult<UpdateCommunityPostCommentMutation>;
export type UpdateCommunityPostCommentMutationOptions = Apollo.BaseMutationOptions<UpdateCommunityPostCommentMutation, UpdateCommunityPostCommentMutationVariables>;
export const LikeCommunityPostCommentDocument = gql`
    mutation LikeCommunityPostComment($id: ID!) {
  likeCommunityPostComment(id: $id) {
    id
  }
}
    `;
export type LikeCommunityPostCommentMutationFn = Apollo.MutationFunction<LikeCommunityPostCommentMutation, LikeCommunityPostCommentMutationVariables>;

/**
 * __useLikeCommunityPostCommentMutation__
 *
 * To run a mutation, you first call `useLikeCommunityPostCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeCommunityPostCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeCommunityPostCommentMutation, { data, loading, error }] = useLikeCommunityPostCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLikeCommunityPostCommentMutation(baseOptions?: Apollo.MutationHookOptions<LikeCommunityPostCommentMutation, LikeCommunityPostCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LikeCommunityPostCommentMutation, LikeCommunityPostCommentMutationVariables>(LikeCommunityPostCommentDocument, options);
      }
export type LikeCommunityPostCommentMutationHookResult = ReturnType<typeof useLikeCommunityPostCommentMutation>;
export type LikeCommunityPostCommentMutationResult = Apollo.MutationResult<LikeCommunityPostCommentMutation>;
export type LikeCommunityPostCommentMutationOptions = Apollo.BaseMutationOptions<LikeCommunityPostCommentMutation, LikeCommunityPostCommentMutationVariables>;
export const UnlikeCommunityPostCommentDocument = gql`
    mutation UnlikeCommunityPostComment($id: ID!) {
  unlikeCommunityPostComment(id: $id) {
    id
  }
}
    `;
export type UnlikeCommunityPostCommentMutationFn = Apollo.MutationFunction<UnlikeCommunityPostCommentMutation, UnlikeCommunityPostCommentMutationVariables>;

/**
 * __useUnlikeCommunityPostCommentMutation__
 *
 * To run a mutation, you first call `useUnlikeCommunityPostCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlikeCommunityPostCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlikeCommunityPostCommentMutation, { data, loading, error }] = useUnlikeCommunityPostCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnlikeCommunityPostCommentMutation(baseOptions?: Apollo.MutationHookOptions<UnlikeCommunityPostCommentMutation, UnlikeCommunityPostCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UnlikeCommunityPostCommentMutation, UnlikeCommunityPostCommentMutationVariables>(UnlikeCommunityPostCommentDocument, options);
      }
export type UnlikeCommunityPostCommentMutationHookResult = ReturnType<typeof useUnlikeCommunityPostCommentMutation>;
export type UnlikeCommunityPostCommentMutationResult = Apollo.MutationResult<UnlikeCommunityPostCommentMutation>;
export type UnlikeCommunityPostCommentMutationOptions = Apollo.BaseMutationOptions<UnlikeCommunityPostCommentMutation, UnlikeCommunityPostCommentMutationVariables>;
export const DeleteCommunityPostCommentDocument = gql`
    mutation DeleteCommunityPostComment($id: ID!) {
  deleteCommunityPostComment(id: $id)
}
    `;
export type DeleteCommunityPostCommentMutationFn = Apollo.MutationFunction<DeleteCommunityPostCommentMutation, DeleteCommunityPostCommentMutationVariables>;

/**
 * __useDeleteCommunityPostCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommunityPostCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommunityPostCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommunityPostCommentMutation, { data, loading, error }] = useDeleteCommunityPostCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCommunityPostCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommunityPostCommentMutation, DeleteCommunityPostCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommunityPostCommentMutation, DeleteCommunityPostCommentMutationVariables>(DeleteCommunityPostCommentDocument, options);
      }
export type DeleteCommunityPostCommentMutationHookResult = ReturnType<typeof useDeleteCommunityPostCommentMutation>;
export type DeleteCommunityPostCommentMutationResult = Apollo.MutationResult<DeleteCommunityPostCommentMutation>;
export type DeleteCommunityPostCommentMutationOptions = Apollo.BaseMutationOptions<DeleteCommunityPostCommentMutation, DeleteCommunityPostCommentMutationVariables>;
export const DeleteConfirmationDocument = gql`
    mutation DeleteConfirmation($deleteConfirmationId: ID!) {
  deleteConfirmation(id: $deleteConfirmationId)
}
    `;
export type DeleteConfirmationMutationFn = Apollo.MutationFunction<DeleteConfirmationMutation, DeleteConfirmationMutationVariables>;

/**
 * __useDeleteConfirmationMutation__
 *
 * To run a mutation, you first call `useDeleteConfirmationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConfirmationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConfirmationMutation, { data, loading, error }] = useDeleteConfirmationMutation({
 *   variables: {
 *      deleteConfirmationId: // value for 'deleteConfirmationId'
 *   },
 * });
 */
export function useDeleteConfirmationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteConfirmationMutation, DeleteConfirmationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteConfirmationMutation, DeleteConfirmationMutationVariables>(DeleteConfirmationDocument, options);
      }
export type DeleteConfirmationMutationHookResult = ReturnType<typeof useDeleteConfirmationMutation>;
export type DeleteConfirmationMutationResult = Apollo.MutationResult<DeleteConfirmationMutation>;
export type DeleteConfirmationMutationOptions = Apollo.BaseMutationOptions<DeleteConfirmationMutation, DeleteConfirmationMutationVariables>;
export const CreateConversationDocument = gql`
    mutation CreateConversation($inputData: ConversationInput!) {
  createConversation(inputData: $inputData) {
    id
    title
  }
}
    `;
export type CreateConversationMutationFn = Apollo.MutationFunction<CreateConversationMutation, CreateConversationMutationVariables>;

/**
 * __useCreateConversationMutation__
 *
 * To run a mutation, you first call `useCreateConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createConversationMutation, { data, loading, error }] = useCreateConversationMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useCreateConversationMutation(baseOptions?: Apollo.MutationHookOptions<CreateConversationMutation, CreateConversationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateConversationMutation, CreateConversationMutationVariables>(CreateConversationDocument, options);
      }
export type CreateConversationMutationHookResult = ReturnType<typeof useCreateConversationMutation>;
export type CreateConversationMutationResult = Apollo.MutationResult<CreateConversationMutation>;
export type CreateConversationMutationOptions = Apollo.BaseMutationOptions<CreateConversationMutation, CreateConversationMutationVariables>;
export const CreateDocumentConversationDocument = gql`
    mutation CreateDocumentConversation($inputData: DocumentConversationInput!) {
  createDocumentConversation(inputData: $inputData) {
    id
    title
  }
}
    `;
export type CreateDocumentConversationMutationFn = Apollo.MutationFunction<CreateDocumentConversationMutation, CreateDocumentConversationMutationVariables>;

/**
 * __useCreateDocumentConversationMutation__
 *
 * To run a mutation, you first call `useCreateDocumentConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentConversationMutation, { data, loading, error }] = useCreateDocumentConversationMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useCreateDocumentConversationMutation(baseOptions?: Apollo.MutationHookOptions<CreateDocumentConversationMutation, CreateDocumentConversationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDocumentConversationMutation, CreateDocumentConversationMutationVariables>(CreateDocumentConversationDocument, options);
      }
export type CreateDocumentConversationMutationHookResult = ReturnType<typeof useCreateDocumentConversationMutation>;
export type CreateDocumentConversationMutationResult = Apollo.MutationResult<CreateDocumentConversationMutation>;
export type CreateDocumentConversationMutationOptions = Apollo.BaseMutationOptions<CreateDocumentConversationMutation, CreateDocumentConversationMutationVariables>;
export const EditConversationDocument = gql`
    mutation EditConversation($id: ID!, $inputData: EditConversationInput!) {
  editConversation(id: $id, inputData: $inputData) {
    id
    title
  }
}
    `;
export type EditConversationMutationFn = Apollo.MutationFunction<EditConversationMutation, EditConversationMutationVariables>;

/**
 * __useEditConversationMutation__
 *
 * To run a mutation, you first call `useEditConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editConversationMutation, { data, loading, error }] = useEditConversationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useEditConversationMutation(baseOptions?: Apollo.MutationHookOptions<EditConversationMutation, EditConversationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditConversationMutation, EditConversationMutationVariables>(EditConversationDocument, options);
      }
export type EditConversationMutationHookResult = ReturnType<typeof useEditConversationMutation>;
export type EditConversationMutationResult = Apollo.MutationResult<EditConversationMutation>;
export type EditConversationMutationOptions = Apollo.BaseMutationOptions<EditConversationMutation, EditConversationMutationVariables>;
export const DeleteConversationDocument = gql`
    mutation DeleteConversation($id: ID!) {
  deleteConversation(id: $id)
}
    `;
export type DeleteConversationMutationFn = Apollo.MutationFunction<DeleteConversationMutation, DeleteConversationMutationVariables>;

/**
 * __useDeleteConversationMutation__
 *
 * To run a mutation, you first call `useDeleteConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteConversationMutation, { data, loading, error }] = useDeleteConversationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteConversationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteConversationMutation, DeleteConversationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteConversationMutation, DeleteConversationMutationVariables>(DeleteConversationDocument, options);
      }
export type DeleteConversationMutationHookResult = ReturnType<typeof useDeleteConversationMutation>;
export type DeleteConversationMutationResult = Apollo.MutationResult<DeleteConversationMutation>;
export type DeleteConversationMutationOptions = Apollo.BaseMutationOptions<DeleteConversationMutation, DeleteConversationMutationVariables>;
export const ConversationsDocument = gql`
    query Conversations($filterData: ConversationsFilterInput!, $pagination: PaginationInput!) {
  conversations(filterData: $filterData, pagination: $pagination) {
    conversations {
      id
      title
      type
      updatedAt
      lastMessage
      Character {
        id
        title
        ImageS3File {
          thumbnailSignedUrl(size: Medium)
        }
      }
    }
    pagination {
      from
      total
    }
  }
}
    `;

/**
 * __useConversationsQuery__
 *
 * To run a query within a React component, call `useConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationsQuery({
 *   variables: {
 *      filterData: // value for 'filterData'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useConversationsQuery(baseOptions: Apollo.QueryHookOptions<ConversationsQuery, ConversationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConversationsQuery, ConversationsQueryVariables>(ConversationsDocument, options);
      }
export function useConversationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConversationsQuery, ConversationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConversationsQuery, ConversationsQueryVariables>(ConversationsDocument, options);
        }
export type ConversationsQueryHookResult = ReturnType<typeof useConversationsQuery>;
export type ConversationsLazyQueryHookResult = ReturnType<typeof useConversationsLazyQuery>;
export type ConversationsQueryResult = Apollo.QueryResult<ConversationsQuery, ConversationsQueryVariables>;
export const ConversationDocument = gql`
    query Conversation($id: ID!) {
  conversation(id: $id) {
    id
    title
    metaData
    type
    SourceDocument {
      id
    }
    Character {
      id
      title
      gender
      ImageS3File {
        thumbnailSignedUrl(size: Medium)
      }
    }
    SurveySubmit {
      id
      isFinished
    }
  }
}
    `;

/**
 * __useConversationQuery__
 *
 * To run a query within a React component, call `useConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConversationQuery(baseOptions: Apollo.QueryHookOptions<ConversationQuery, ConversationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConversationQuery, ConversationQueryVariables>(ConversationDocument, options);
      }
export function useConversationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConversationQuery, ConversationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConversationQuery, ConversationQueryVariables>(ConversationDocument, options);
        }
export type ConversationQueryHookResult = ReturnType<typeof useConversationQuery>;
export type ConversationLazyQueryHookResult = ReturnType<typeof useConversationLazyQuery>;
export type ConversationQueryResult = Apollo.QueryResult<ConversationQuery, ConversationQueryVariables>;
export const ConversationMessagesDocument = gql`
    query ConversationMessages($filterData: ConversationMessagesFilterInput!, $pagination: PaginationInput!, $includeConversation: Boolean = false) {
  conversationMessages(filterData: $filterData, pagination: $pagination) {
    conversationMessages {
      id
      createdAt
      text
      author
      isBookmarked
      Conversation @include(if: $includeConversation) {
        id
        title
        Character {
          id
          title
          ImageS3File {
            thumbnailSignedUrl(size: Medium)
          }
        }
      }
    }
    pagination {
      total
      from
      length
    }
  }
}
    `;

/**
 * __useConversationMessagesQuery__
 *
 * To run a query within a React component, call `useConversationMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationMessagesQuery({
 *   variables: {
 *      filterData: // value for 'filterData'
 *      pagination: // value for 'pagination'
 *      includeConversation: // value for 'includeConversation'
 *   },
 * });
 */
export function useConversationMessagesQuery(baseOptions: Apollo.QueryHookOptions<ConversationMessagesQuery, ConversationMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConversationMessagesQuery, ConversationMessagesQueryVariables>(ConversationMessagesDocument, options);
      }
export function useConversationMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConversationMessagesQuery, ConversationMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConversationMessagesQuery, ConversationMessagesQueryVariables>(ConversationMessagesDocument, options);
        }
export type ConversationMessagesQueryHookResult = ReturnType<typeof useConversationMessagesQuery>;
export type ConversationMessagesLazyQueryHookResult = ReturnType<typeof useConversationMessagesLazyQuery>;
export type ConversationMessagesQueryResult = Apollo.QueryResult<ConversationMessagesQuery, ConversationMessagesQueryVariables>;
export const SendMessageDocument = gql`
    mutation SendMessage($inputData: ConversationMessageInput!) {
  sendMessage(inputData: $inputData)
}
    `;
export type SendMessageMutationFn = Apollo.MutationFunction<SendMessageMutation, SendMessageMutationVariables>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useSendMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendMessageMutation, SendMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(SendMessageDocument, options);
      }
export type SendMessageMutationHookResult = ReturnType<typeof useSendMessageMutation>;
export type SendMessageMutationResult = Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<SendMessageMutation, SendMessageMutationVariables>;
export const EditConversationMessageDocument = gql`
    mutation EditConversationMessage($id: ID!, $inputData: EditConversationMessageInput!) {
  editConversationMessage(id: $id, inputData: $inputData) {
    id
    createdAt
    text
    author
    isBookmarked
  }
}
    `;
export type EditConversationMessageMutationFn = Apollo.MutationFunction<EditConversationMessageMutation, EditConversationMessageMutationVariables>;

/**
 * __useEditConversationMessageMutation__
 *
 * To run a mutation, you first call `useEditConversationMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditConversationMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editConversationMessageMutation, { data, loading, error }] = useEditConversationMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useEditConversationMessageMutation(baseOptions?: Apollo.MutationHookOptions<EditConversationMessageMutation, EditConversationMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditConversationMessageMutation, EditConversationMessageMutationVariables>(EditConversationMessageDocument, options);
      }
export type EditConversationMessageMutationHookResult = ReturnType<typeof useEditConversationMessageMutation>;
export type EditConversationMessageMutationResult = Apollo.MutationResult<EditConversationMessageMutation>;
export type EditConversationMessageMutationOptions = Apollo.BaseMutationOptions<EditConversationMessageMutation, EditConversationMessageMutationVariables>;
export const ClaimConversationsDocument = gql`
    mutation ClaimConversations($inputData: ClaimConversationsInput!) {
  claimConversations(inputData: $inputData)
}
    `;
export type ClaimConversationsMutationFn = Apollo.MutationFunction<ClaimConversationsMutation, ClaimConversationsMutationVariables>;

/**
 * __useClaimConversationsMutation__
 *
 * To run a mutation, you first call `useClaimConversationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimConversationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimConversationsMutation, { data, loading, error }] = useClaimConversationsMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useClaimConversationsMutation(baseOptions?: Apollo.MutationHookOptions<ClaimConversationsMutation, ClaimConversationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClaimConversationsMutation, ClaimConversationsMutationVariables>(ClaimConversationsDocument, options);
      }
export type ClaimConversationsMutationHookResult = ReturnType<typeof useClaimConversationsMutation>;
export type ClaimConversationsMutationResult = Apollo.MutationResult<ClaimConversationsMutation>;
export type ClaimConversationsMutationOptions = Apollo.BaseMutationOptions<ClaimConversationsMutation, ClaimConversationsMutationVariables>;
export const TurnConversationIntoDocumentDocument = gql`
    mutation TurnConversationIntoDocument($id: ID!) {
  turnConversationIntoDocument(id: $id)
}
    `;
export type TurnConversationIntoDocumentMutationFn = Apollo.MutationFunction<TurnConversationIntoDocumentMutation, TurnConversationIntoDocumentMutationVariables>;

/**
 * __useTurnConversationIntoDocumentMutation__
 *
 * To run a mutation, you first call `useTurnConversationIntoDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTurnConversationIntoDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [turnConversationIntoDocumentMutation, { data, loading, error }] = useTurnConversationIntoDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTurnConversationIntoDocumentMutation(baseOptions?: Apollo.MutationHookOptions<TurnConversationIntoDocumentMutation, TurnConversationIntoDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TurnConversationIntoDocumentMutation, TurnConversationIntoDocumentMutationVariables>(TurnConversationIntoDocumentDocument, options);
      }
export type TurnConversationIntoDocumentMutationHookResult = ReturnType<typeof useTurnConversationIntoDocumentMutation>;
export type TurnConversationIntoDocumentMutationResult = Apollo.MutationResult<TurnConversationIntoDocumentMutation>;
export type TurnConversationIntoDocumentMutationOptions = Apollo.BaseMutationOptions<TurnConversationIntoDocumentMutation, TurnConversationIntoDocumentMutationVariables>;
export const DocumentDocument = gql`
    query Document($id: ID!) {
  document(id: $id) {
    id
    createdAt
    updatedAt
    title
    content
  }
}
    `;

/**
 * __useDocumentQuery__
 *
 * To run a query within a React component, call `useDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDocumentQuery(baseOptions: Apollo.QueryHookOptions<DocumentQuery, DocumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentQuery, DocumentQueryVariables>(DocumentDocument, options);
      }
export function useDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentQuery, DocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentQuery, DocumentQueryVariables>(DocumentDocument, options);
        }
export type DocumentQueryHookResult = ReturnType<typeof useDocumentQuery>;
export type DocumentLazyQueryHookResult = ReturnType<typeof useDocumentLazyQuery>;
export type DocumentQueryResult = Apollo.QueryResult<DocumentQuery, DocumentQueryVariables>;
export const DocumentsDocument = gql`
    query Documents($filterData: DocumentFilterInput!, $pagination: PaginationInput!) {
  documents(filterData: $filterData, pagination: $pagination) {
    documents {
      id
      createdAt
      updatedAt
      title
    }
    pagination {
      from
      length
      total
    }
  }
}
    `;

/**
 * __useDocumentsQuery__
 *
 * To run a query within a React component, call `useDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentsQuery({
 *   variables: {
 *      filterData: // value for 'filterData'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useDocumentsQuery(baseOptions: Apollo.QueryHookOptions<DocumentsQuery, DocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentsQuery, DocumentsQueryVariables>(DocumentsDocument, options);
      }
export function useDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentsQuery, DocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentsQuery, DocumentsQueryVariables>(DocumentsDocument, options);
        }
export type DocumentsQueryHookResult = ReturnType<typeof useDocumentsQuery>;
export type DocumentsLazyQueryHookResult = ReturnType<typeof useDocumentsLazyQuery>;
export type DocumentsQueryResult = Apollo.QueryResult<DocumentsQuery, DocumentsQueryVariables>;
export const CreateDocumentDocument = gql`
    mutation CreateDocument($inputData: DocumentInput!) {
  createDocument(inputData: $inputData) {
    id
  }
}
    `;
export type CreateDocumentMutationFn = Apollo.MutationFunction<CreateDocumentMutation, CreateDocumentMutationVariables>;

/**
 * __useCreateDocumentMutation__
 *
 * To run a mutation, you first call `useCreateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentMutation, { data, loading, error }] = useCreateDocumentMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useCreateDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CreateDocumentMutation, CreateDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDocumentMutation, CreateDocumentMutationVariables>(CreateDocumentDocument, options);
      }
export type CreateDocumentMutationHookResult = ReturnType<typeof useCreateDocumentMutation>;
export type CreateDocumentMutationResult = Apollo.MutationResult<CreateDocumentMutation>;
export type CreateDocumentMutationOptions = Apollo.BaseMutationOptions<CreateDocumentMutation, CreateDocumentMutationVariables>;
export const UpdateDocumentDocument = gql`
    mutation UpdateDocument($id: ID!, $inputData: DocumentInput!) {
  updateDocument(id: $id, inputData: $inputData) {
    id
  }
}
    `;
export type UpdateDocumentMutationFn = Apollo.MutationFunction<UpdateDocumentMutation, UpdateDocumentMutationVariables>;

/**
 * __useUpdateDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentMutation, { data, loading, error }] = useUpdateDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useUpdateDocumentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocumentMutation, UpdateDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocumentMutation, UpdateDocumentMutationVariables>(UpdateDocumentDocument, options);
      }
export type UpdateDocumentMutationHookResult = ReturnType<typeof useUpdateDocumentMutation>;
export type UpdateDocumentMutationResult = Apollo.MutationResult<UpdateDocumentMutation>;
export type UpdateDocumentMutationOptions = Apollo.BaseMutationOptions<UpdateDocumentMutation, UpdateDocumentMutationVariables>;
export const DeleteDocumentDocument = gql`
    mutation DeleteDocument($id: ID!) {
  deleteDocument(id: $id) {
    id
  }
}
    `;
export type DeleteDocumentMutationFn = Apollo.MutationFunction<DeleteDocumentMutation, DeleteDocumentMutationVariables>;

/**
 * __useDeleteDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDocumentMutation, { data, loading, error }] = useDeleteDocumentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDocumentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDocumentMutation, DeleteDocumentMutationVariables>(DeleteDocumentDocument, options);
      }
export type DeleteDocumentMutationHookResult = ReturnType<typeof useDeleteDocumentMutation>;
export type DeleteDocumentMutationResult = Apollo.MutationResult<DeleteDocumentMutation>;
export type DeleteDocumentMutationOptions = Apollo.BaseMutationOptions<DeleteDocumentMutation, DeleteDocumentMutationVariables>;
export const HashtagDocument = gql`
    query Hashtag($id: ID!) {
  hashtag(id: $id) {
    id
    createdAt
    updatedAt
    parentHashtagId
  }
}
    `;

/**
 * __useHashtagQuery__
 *
 * To run a query within a React component, call `useHashtagQuery` and pass it any options that fit your needs.
 * When your component renders, `useHashtagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHashtagQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHashtagQuery(baseOptions: Apollo.QueryHookOptions<HashtagQuery, HashtagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HashtagQuery, HashtagQueryVariables>(HashtagDocument, options);
      }
export function useHashtagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HashtagQuery, HashtagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HashtagQuery, HashtagQueryVariables>(HashtagDocument, options);
        }
export type HashtagQueryHookResult = ReturnType<typeof useHashtagQuery>;
export type HashtagLazyQueryHookResult = ReturnType<typeof useHashtagLazyQuery>;
export type HashtagQueryResult = Apollo.QueryResult<HashtagQuery, HashtagQueryVariables>;
export const HashtagsDocument = gql`
    query Hashtags($filterData: HashtagFilterInput!, $pagination: PaginationInput!) {
  hashtags(filterData: $filterData, pagination: $pagination) {
    hashtags {
      id
      createdAt
      updatedAt
      parentHashtagId
    }
    pagination {
      from
      length
      total
    }
  }
}
    `;

/**
 * __useHashtagsQuery__
 *
 * To run a query within a React component, call `useHashtagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHashtagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHashtagsQuery({
 *   variables: {
 *      filterData: // value for 'filterData'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useHashtagsQuery(baseOptions: Apollo.QueryHookOptions<HashtagsQuery, HashtagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HashtagsQuery, HashtagsQueryVariables>(HashtagsDocument, options);
      }
export function useHashtagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HashtagsQuery, HashtagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HashtagsQuery, HashtagsQueryVariables>(HashtagsDocument, options);
        }
export type HashtagsQueryHookResult = ReturnType<typeof useHashtagsQuery>;
export type HashtagsLazyQueryHookResult = ReturnType<typeof useHashtagsLazyQuery>;
export type HashtagsQueryResult = Apollo.QueryResult<HashtagsQuery, HashtagsQueryVariables>;
export const CreateHashtagDocument = gql`
    mutation CreateHashtag($inputData: HashtagInput!) {
  createHashtag(inputData: $inputData)
}
    `;
export type CreateHashtagMutationFn = Apollo.MutationFunction<CreateHashtagMutation, CreateHashtagMutationVariables>;

/**
 * __useCreateHashtagMutation__
 *
 * To run a mutation, you first call `useCreateHashtagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHashtagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHashtagMutation, { data, loading, error }] = useCreateHashtagMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useCreateHashtagMutation(baseOptions?: Apollo.MutationHookOptions<CreateHashtagMutation, CreateHashtagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateHashtagMutation, CreateHashtagMutationVariables>(CreateHashtagDocument, options);
      }
export type CreateHashtagMutationHookResult = ReturnType<typeof useCreateHashtagMutation>;
export type CreateHashtagMutationResult = Apollo.MutationResult<CreateHashtagMutation>;
export type CreateHashtagMutationOptions = Apollo.BaseMutationOptions<CreateHashtagMutation, CreateHashtagMutationVariables>;
export const UpdateHashtagDocument = gql`
    mutation UpdateHashtag($id: ID!, $inputData: HashtagInput!) {
  updateHashtag(id: $id, inputData: $inputData)
}
    `;
export type UpdateHashtagMutationFn = Apollo.MutationFunction<UpdateHashtagMutation, UpdateHashtagMutationVariables>;

/**
 * __useUpdateHashtagMutation__
 *
 * To run a mutation, you first call `useUpdateHashtagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHashtagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHashtagMutation, { data, loading, error }] = useUpdateHashtagMutation({
 *   variables: {
 *      id: // value for 'id'
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useUpdateHashtagMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHashtagMutation, UpdateHashtagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHashtagMutation, UpdateHashtagMutationVariables>(UpdateHashtagDocument, options);
      }
export type UpdateHashtagMutationHookResult = ReturnType<typeof useUpdateHashtagMutation>;
export type UpdateHashtagMutationResult = Apollo.MutationResult<UpdateHashtagMutation>;
export type UpdateHashtagMutationOptions = Apollo.BaseMutationOptions<UpdateHashtagMutation, UpdateHashtagMutationVariables>;
export const DeleteHashtagDocument = gql`
    mutation DeleteHashtag($id: ID!) {
  deleteHashtag(id: $id)
}
    `;
export type DeleteHashtagMutationFn = Apollo.MutationFunction<DeleteHashtagMutation, DeleteHashtagMutationVariables>;

/**
 * __useDeleteHashtagMutation__
 *
 * To run a mutation, you first call `useDeleteHashtagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteHashtagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteHashtagMutation, { data, loading, error }] = useDeleteHashtagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteHashtagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteHashtagMutation, DeleteHashtagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteHashtagMutation, DeleteHashtagMutationVariables>(DeleteHashtagDocument, options);
      }
export type DeleteHashtagMutationHookResult = ReturnType<typeof useDeleteHashtagMutation>;
export type DeleteHashtagMutationResult = Apollo.MutationResult<DeleteHashtagMutation>;
export type DeleteHashtagMutationOptions = Apollo.BaseMutationOptions<DeleteHashtagMutation, DeleteHashtagMutationVariables>;
export const FileUploadS3PresignDocument = gql`
    mutation FileUploadS3Presign($fileName: String!, $fileType: FileTypeEnum!, $contentType: String!, $fileSize: Int!, $isPublic: Boolean) {
  fileUploadS3Presign(
    fileName: $fileName
    fileType: $fileType
    contentType: $contentType
    fileSize: $fileSize
    isPublic: $isPublic
  ) {
    signedRequest
    key
  }
}
    `;
export type FileUploadS3PresignMutationFn = Apollo.MutationFunction<FileUploadS3PresignMutation, FileUploadS3PresignMutationVariables>;

/**
 * __useFileUploadS3PresignMutation__
 *
 * To run a mutation, you first call `useFileUploadS3PresignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileUploadS3PresignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileUploadS3PresignMutation, { data, loading, error }] = useFileUploadS3PresignMutation({
 *   variables: {
 *      fileName: // value for 'fileName'
 *      fileType: // value for 'fileType'
 *      contentType: // value for 'contentType'
 *      fileSize: // value for 'fileSize'
 *      isPublic: // value for 'isPublic'
 *   },
 * });
 */
export function useFileUploadS3PresignMutation(baseOptions?: Apollo.MutationHookOptions<FileUploadS3PresignMutation, FileUploadS3PresignMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FileUploadS3PresignMutation, FileUploadS3PresignMutationVariables>(FileUploadS3PresignDocument, options);
      }
export type FileUploadS3PresignMutationHookResult = ReturnType<typeof useFileUploadS3PresignMutation>;
export type FileUploadS3PresignMutationResult = Apollo.MutationResult<FileUploadS3PresignMutation>;
export type FileUploadS3PresignMutationOptions = Apollo.BaseMutationOptions<FileUploadS3PresignMutation, FileUploadS3PresignMutationVariables>;
export const SourceDocumentDocument = gql`
    query SourceDocument($id: ID!) {
  sourceDocument(id: $id) {
    id
    SourceDocumentContents {
      id
      index
      key
      value
    }
    SourceDocumentProcessedContents {
      id
      index
      key
      value
      type
    }
  }
}
    `;

/**
 * __useSourceDocumentQuery__
 *
 * To run a query within a React component, call `useSourceDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useSourceDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSourceDocumentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSourceDocumentQuery(baseOptions: Apollo.QueryHookOptions<SourceDocumentQuery, SourceDocumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SourceDocumentQuery, SourceDocumentQueryVariables>(SourceDocumentDocument, options);
      }
export function useSourceDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SourceDocumentQuery, SourceDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SourceDocumentQuery, SourceDocumentQueryVariables>(SourceDocumentDocument, options);
        }
export type SourceDocumentQueryHookResult = ReturnType<typeof useSourceDocumentQuery>;
export type SourceDocumentLazyQueryHookResult = ReturnType<typeof useSourceDocumentLazyQuery>;
export type SourceDocumentQueryResult = Apollo.QueryResult<SourceDocumentQuery, SourceDocumentQueryVariables>;
export const CreateSourceDocumentDocument = gql`
    mutation CreateSourceDocument($inputData: SourceDocumentInput!) {
  createSourceDocument(inputData: $inputData) {
    id
  }
}
    `;
export type CreateSourceDocumentMutationFn = Apollo.MutationFunction<CreateSourceDocumentMutation, CreateSourceDocumentMutationVariables>;

/**
 * __useCreateSourceDocumentMutation__
 *
 * To run a mutation, you first call `useCreateSourceDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSourceDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSourceDocumentMutation, { data, loading, error }] = useCreateSourceDocumentMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useCreateSourceDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CreateSourceDocumentMutation, CreateSourceDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSourceDocumentMutation, CreateSourceDocumentMutationVariables>(CreateSourceDocumentDocument, options);
      }
export type CreateSourceDocumentMutationHookResult = ReturnType<typeof useCreateSourceDocumentMutation>;
export type CreateSourceDocumentMutationResult = Apollo.MutationResult<CreateSourceDocumentMutation>;
export type CreateSourceDocumentMutationOptions = Apollo.BaseMutationOptions<CreateSourceDocumentMutation, CreateSourceDocumentMutationVariables>;
export const CreateSourceDocumentProcessedContentDocument = gql`
    mutation CreateSourceDocumentProcessedContent($inputData: SourceDocumentProcessedContentInput!) {
  createSourceDocumentProcessedContent(inputData: $inputData)
}
    `;
export type CreateSourceDocumentProcessedContentMutationFn = Apollo.MutationFunction<CreateSourceDocumentProcessedContentMutation, CreateSourceDocumentProcessedContentMutationVariables>;

/**
 * __useCreateSourceDocumentProcessedContentMutation__
 *
 * To run a mutation, you first call `useCreateSourceDocumentProcessedContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSourceDocumentProcessedContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSourceDocumentProcessedContentMutation, { data, loading, error }] = useCreateSourceDocumentProcessedContentMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useCreateSourceDocumentProcessedContentMutation(baseOptions?: Apollo.MutationHookOptions<CreateSourceDocumentProcessedContentMutation, CreateSourceDocumentProcessedContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSourceDocumentProcessedContentMutation, CreateSourceDocumentProcessedContentMutationVariables>(CreateSourceDocumentProcessedContentDocument, options);
      }
export type CreateSourceDocumentProcessedContentMutationHookResult = ReturnType<typeof useCreateSourceDocumentProcessedContentMutation>;
export type CreateSourceDocumentProcessedContentMutationResult = Apollo.MutationResult<CreateSourceDocumentProcessedContentMutation>;
export type CreateSourceDocumentProcessedContentMutationOptions = Apollo.BaseMutationOptions<CreateSourceDocumentProcessedContentMutation, CreateSourceDocumentProcessedContentMutationVariables>;
export const StripeProductsDocument = gql`
    query StripeProducts {
  stripeProducts {
    id
    createdAt
    updatedAt
    active
    name
    metadata
    description
    images
    StripePrices {
      id
      unitAmount
      currency
      type
    }
  }
}
    `;

/**
 * __useStripeProductsQuery__
 *
 * To run a query within a React component, call `useStripeProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStripeProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStripeProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useStripeProductsQuery(baseOptions?: Apollo.QueryHookOptions<StripeProductsQuery, StripeProductsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StripeProductsQuery, StripeProductsQueryVariables>(StripeProductsDocument, options);
      }
export function useStripeProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StripeProductsQuery, StripeProductsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StripeProductsQuery, StripeProductsQueryVariables>(StripeProductsDocument, options);
        }
export type StripeProductsQueryHookResult = ReturnType<typeof useStripeProductsQuery>;
export type StripeProductsLazyQueryHookResult = ReturnType<typeof useStripeProductsLazyQuery>;
export type StripeProductsQueryResult = Apollo.QueryResult<StripeProductsQuery, StripeProductsQueryVariables>;
export const CreateStripeCheckoutSessionDocument = gql`
    mutation CreateStripeCheckoutSession($userId: ID!, $priceId: String!) {
  createStripeCheckoutSession(userId: $userId, priceId: $priceId)
}
    `;
export type CreateStripeCheckoutSessionMutationFn = Apollo.MutationFunction<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>;

/**
 * __useCreateStripeCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useCreateStripeCheckoutSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStripeCheckoutSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStripeCheckoutSessionMutation, { data, loading, error }] = useCreateStripeCheckoutSessionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      priceId: // value for 'priceId'
 *   },
 * });
 */
export function useCreateStripeCheckoutSessionMutation(baseOptions?: Apollo.MutationHookOptions<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>(CreateStripeCheckoutSessionDocument, options);
      }
export type CreateStripeCheckoutSessionMutationHookResult = ReturnType<typeof useCreateStripeCheckoutSessionMutation>;
export type CreateStripeCheckoutSessionMutationResult = Apollo.MutationResult<CreateStripeCheckoutSessionMutation>;
export type CreateStripeCheckoutSessionMutationOptions = Apollo.BaseMutationOptions<CreateStripeCheckoutSessionMutation, CreateStripeCheckoutSessionMutationVariables>;
export const UpgradeStripeSubscriptionDocument = gql`
    mutation UpgradeStripeSubscription($userId: ID!, $priceId: String!) {
  upgradeStripeSubscription(userId: $userId, priceId: $priceId)
}
    `;
export type UpgradeStripeSubscriptionMutationFn = Apollo.MutationFunction<UpgradeStripeSubscriptionMutation, UpgradeStripeSubscriptionMutationVariables>;

/**
 * __useUpgradeStripeSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpgradeStripeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpgradeStripeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upgradeStripeSubscriptionMutation, { data, loading, error }] = useUpgradeStripeSubscriptionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      priceId: // value for 'priceId'
 *   },
 * });
 */
export function useUpgradeStripeSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpgradeStripeSubscriptionMutation, UpgradeStripeSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpgradeStripeSubscriptionMutation, UpgradeStripeSubscriptionMutationVariables>(UpgradeStripeSubscriptionDocument, options);
      }
export type UpgradeStripeSubscriptionMutationHookResult = ReturnType<typeof useUpgradeStripeSubscriptionMutation>;
export type UpgradeStripeSubscriptionMutationResult = Apollo.MutationResult<UpgradeStripeSubscriptionMutation>;
export type UpgradeStripeSubscriptionMutationOptions = Apollo.BaseMutationOptions<UpgradeStripeSubscriptionMutation, UpgradeStripeSubscriptionMutationVariables>;
export const CancelStripeSubscriptionDocument = gql`
    mutation CancelStripeSubscription($userId: ID!) {
  cancelStripeSubscription(userId: $userId)
}
    `;
export type CancelStripeSubscriptionMutationFn = Apollo.MutationFunction<CancelStripeSubscriptionMutation, CancelStripeSubscriptionMutationVariables>;

/**
 * __useCancelStripeSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelStripeSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelStripeSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelStripeSubscriptionMutation, { data, loading, error }] = useCancelStripeSubscriptionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCancelStripeSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelStripeSubscriptionMutation, CancelStripeSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelStripeSubscriptionMutation, CancelStripeSubscriptionMutationVariables>(CancelStripeSubscriptionDocument, options);
      }
export type CancelStripeSubscriptionMutationHookResult = ReturnType<typeof useCancelStripeSubscriptionMutation>;
export type CancelStripeSubscriptionMutationResult = Apollo.MutationResult<CancelStripeSubscriptionMutation>;
export type CancelStripeSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelStripeSubscriptionMutation, CancelStripeSubscriptionMutationVariables>;
export const GenerateStripePortalLinkDocument = gql`
    query GenerateStripePortalLink {
  generateStripePortalLink
}
    `;

/**
 * __useGenerateStripePortalLinkQuery__
 *
 * To run a query within a React component, call `useGenerateStripePortalLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateStripePortalLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateStripePortalLinkQuery({
 *   variables: {
 *   },
 * });
 */
export function useGenerateStripePortalLinkQuery(baseOptions?: Apollo.QueryHookOptions<GenerateStripePortalLinkQuery, GenerateStripePortalLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateStripePortalLinkQuery, GenerateStripePortalLinkQueryVariables>(GenerateStripePortalLinkDocument, options);
      }
export function useGenerateStripePortalLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateStripePortalLinkQuery, GenerateStripePortalLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateStripePortalLinkQuery, GenerateStripePortalLinkQueryVariables>(GenerateStripePortalLinkDocument, options);
        }
export type GenerateStripePortalLinkQueryHookResult = ReturnType<typeof useGenerateStripePortalLinkQuery>;
export type GenerateStripePortalLinkLazyQueryHookResult = ReturnType<typeof useGenerateStripePortalLinkLazyQuery>;
export type GenerateStripePortalLinkQueryResult = Apollo.QueryResult<GenerateStripePortalLinkQuery, GenerateStripePortalLinkQueryVariables>;
export const SurveyDocument = gql`
    query Survey($id: ID!) {
  survey(id: $id) {
    id
    title
    metaData
    numOfSurveySubmits
    Character {
      id
      title
      metaData
      gender
      ImageS3File {
        key
        thumbnailSignedUrl(size: Medium)
      }
    }
  }
}
    `;

/**
 * __useSurveyQuery__
 *
 * To run a query within a React component, call `useSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSurveyQuery(baseOptions: Apollo.QueryHookOptions<SurveyQuery, SurveyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveyQuery, SurveyQueryVariables>(SurveyDocument, options);
      }
export function useSurveyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveyQuery, SurveyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveyQuery, SurveyQueryVariables>(SurveyDocument, options);
        }
export type SurveyQueryHookResult = ReturnType<typeof useSurveyQuery>;
export type SurveyLazyQueryHookResult = ReturnType<typeof useSurveyLazyQuery>;
export type SurveyQueryResult = Apollo.QueryResult<SurveyQuery, SurveyQueryVariables>;
export const SurveysDocument = gql`
    query Surveys($filterData: SurveysFilterInput!, $pagination: PaginationInput!) {
  surveys(filterData: $filterData, pagination: $pagination) {
    surveys {
      id
      title
      metaData
      Character {
        id
        title
        metaData
        gender
        ImageS3File {
          thumbnailSignedUrl(size: Medium)
        }
      }
    }
    pagination {
      total
    }
  }
}
    `;

/**
 * __useSurveysQuery__
 *
 * To run a query within a React component, call `useSurveysQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveysQuery({
 *   variables: {
 *      filterData: // value for 'filterData'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSurveysQuery(baseOptions: Apollo.QueryHookOptions<SurveysQuery, SurveysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveysQuery, SurveysQueryVariables>(SurveysDocument, options);
      }
export function useSurveysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveysQuery, SurveysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveysQuery, SurveysQueryVariables>(SurveysDocument, options);
        }
export type SurveysQueryHookResult = ReturnType<typeof useSurveysQuery>;
export type SurveysLazyQueryHookResult = ReturnType<typeof useSurveysLazyQuery>;
export type SurveysQueryResult = Apollo.QueryResult<SurveysQuery, SurveysQueryVariables>;
export const SurveySubmitsDocument = gql`
    query SurveySubmits($filterData: SurveySubmitsFilterInput!, $pagination: PaginationInput!) {
  surveySubmits(filterData: $filterData, pagination: $pagination) {
    surveySubmits {
      id
      metaData
      isFinished
      Conversation {
        id
      }
    }
    pagination {
      total
    }
  }
}
    `;

/**
 * __useSurveySubmitsQuery__
 *
 * To run a query within a React component, call `useSurveySubmitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSurveySubmitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSurveySubmitsQuery({
 *   variables: {
 *      filterData: // value for 'filterData'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSurveySubmitsQuery(baseOptions: Apollo.QueryHookOptions<SurveySubmitsQuery, SurveySubmitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SurveySubmitsQuery, SurveySubmitsQueryVariables>(SurveySubmitsDocument, options);
      }
export function useSurveySubmitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SurveySubmitsQuery, SurveySubmitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SurveySubmitsQuery, SurveySubmitsQueryVariables>(SurveySubmitsDocument, options);
        }
export type SurveySubmitsQueryHookResult = ReturnType<typeof useSurveySubmitsQuery>;
export type SurveySubmitsLazyQueryHookResult = ReturnType<typeof useSurveySubmitsLazyQuery>;
export type SurveySubmitsQueryResult = Apollo.QueryResult<SurveySubmitsQuery, SurveySubmitsQueryVariables>;
export const CreateSurveyDocument = gql`
    mutation CreateSurvey($inputData: SurveyInput!) {
  createSurvey(inputData: $inputData) {
    id
    title
  }
}
    `;
export type CreateSurveyMutationFn = Apollo.MutationFunction<CreateSurveyMutation, CreateSurveyMutationVariables>;

/**
 * __useCreateSurveyMutation__
 *
 * To run a mutation, you first call `useCreateSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSurveyMutation, { data, loading, error }] = useCreateSurveyMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useCreateSurveyMutation(baseOptions?: Apollo.MutationHookOptions<CreateSurveyMutation, CreateSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSurveyMutation, CreateSurveyMutationVariables>(CreateSurveyDocument, options);
      }
export type CreateSurveyMutationHookResult = ReturnType<typeof useCreateSurveyMutation>;
export type CreateSurveyMutationResult = Apollo.MutationResult<CreateSurveyMutation>;
export type CreateSurveyMutationOptions = Apollo.BaseMutationOptions<CreateSurveyMutation, CreateSurveyMutationVariables>;
export const CreateSurveyConversationDocument = gql`
    mutation CreateSurveyConversation($inputData: SurveyConversationInput!) {
  createSurveyConversation(inputData: $inputData) {
    id
    title
  }
}
    `;
export type CreateSurveyConversationMutationFn = Apollo.MutationFunction<CreateSurveyConversationMutation, CreateSurveyConversationMutationVariables>;

/**
 * __useCreateSurveyConversationMutation__
 *
 * To run a mutation, you first call `useCreateSurveyConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSurveyConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSurveyConversationMutation, { data, loading, error }] = useCreateSurveyConversationMutation({
 *   variables: {
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useCreateSurveyConversationMutation(baseOptions?: Apollo.MutationHookOptions<CreateSurveyConversationMutation, CreateSurveyConversationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSurveyConversationMutation, CreateSurveyConversationMutationVariables>(CreateSurveyConversationDocument, options);
      }
export type CreateSurveyConversationMutationHookResult = ReturnType<typeof useCreateSurveyConversationMutation>;
export type CreateSurveyConversationMutationResult = Apollo.MutationResult<CreateSurveyConversationMutation>;
export type CreateSurveyConversationMutationOptions = Apollo.BaseMutationOptions<CreateSurveyConversationMutation, CreateSurveyConversationMutationVariables>;
export const EditSurveyDocument = gql`
    mutation EditSurvey($id: ID!, $inputData: SurveyInput!) {
  editSurvey(id: $id, inputData: $inputData) {
    id
    title
  }
}
    `;
export type EditSurveyMutationFn = Apollo.MutationFunction<EditSurveyMutation, EditSurveyMutationVariables>;

/**
 * __useEditSurveyMutation__
 *
 * To run a mutation, you first call `useEditSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSurveyMutation, { data, loading, error }] = useEditSurveyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useEditSurveyMutation(baseOptions?: Apollo.MutationHookOptions<EditSurveyMutation, EditSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditSurveyMutation, EditSurveyMutationVariables>(EditSurveyDocument, options);
      }
export type EditSurveyMutationHookResult = ReturnType<typeof useEditSurveyMutation>;
export type EditSurveyMutationResult = Apollo.MutationResult<EditSurveyMutation>;
export type EditSurveyMutationOptions = Apollo.BaseMutationOptions<EditSurveyMutation, EditSurveyMutationVariables>;
export const FinishSurveySubmitDocument = gql`
    mutation FinishSurveySubmit($id: ID!) {
  finishSurveySubmit(id: $id) {
    id
  }
}
    `;
export type FinishSurveySubmitMutationFn = Apollo.MutationFunction<FinishSurveySubmitMutation, FinishSurveySubmitMutationVariables>;

/**
 * __useFinishSurveySubmitMutation__
 *
 * To run a mutation, you first call `useFinishSurveySubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishSurveySubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishSurveySubmitMutation, { data, loading, error }] = useFinishSurveySubmitMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFinishSurveySubmitMutation(baseOptions?: Apollo.MutationHookOptions<FinishSurveySubmitMutation, FinishSurveySubmitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinishSurveySubmitMutation, FinishSurveySubmitMutationVariables>(FinishSurveySubmitDocument, options);
      }
export type FinishSurveySubmitMutationHookResult = ReturnType<typeof useFinishSurveySubmitMutation>;
export type FinishSurveySubmitMutationResult = Apollo.MutationResult<FinishSurveySubmitMutation>;
export type FinishSurveySubmitMutationOptions = Apollo.BaseMutationOptions<FinishSurveySubmitMutation, FinishSurveySubmitMutationVariables>;
export const UserDocument = gql`
    query User($userId: ID!) {
  user(id: $userId) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
    query Users($filterData: UsersFilterInput, $pagination: PaginationInput!) {
  users(filterData: $filterData, pagination: $pagination) {
    users {
      id
      firstName
      lastName
    }
    pagination {
      from
      length
      total
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      filterData: // value for 'filterData'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useUsersQuery(baseOptions: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($updateUserId: ID!, $inputData: UpdateUserInput!) {
  updateUser(id: $updateUserId, inputData: $inputData) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      updateUserId: // value for 'updateUserId'
 *      inputData: // value for 'inputData'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const SetUserOpenAiTokenDocument = gql`
    mutation SetUserOpenAIToken($id: ID!, $token: String!) {
  setUserOpenAIToken(id: $id, token: $token)
}
    `;
export type SetUserOpenAiTokenMutationFn = Apollo.MutationFunction<SetUserOpenAiTokenMutation, SetUserOpenAiTokenMutationVariables>;

/**
 * __useSetUserOpenAiTokenMutation__
 *
 * To run a mutation, you first call `useSetUserOpenAiTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserOpenAiTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserOpenAiTokenMutation, { data, loading, error }] = useSetUserOpenAiTokenMutation({
 *   variables: {
 *      id: // value for 'id'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useSetUserOpenAiTokenMutation(baseOptions?: Apollo.MutationHookOptions<SetUserOpenAiTokenMutation, SetUserOpenAiTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserOpenAiTokenMutation, SetUserOpenAiTokenMutationVariables>(SetUserOpenAiTokenDocument, options);
      }
export type SetUserOpenAiTokenMutationHookResult = ReturnType<typeof useSetUserOpenAiTokenMutation>;
export type SetUserOpenAiTokenMutationResult = Apollo.MutationResult<SetUserOpenAiTokenMutation>;
export type SetUserOpenAiTokenMutationOptions = Apollo.BaseMutationOptions<SetUserOpenAiTokenMutation, SetUserOpenAiTokenMutationVariables>;