import React from 'react'

import { SubscriptionPlan } from '../../../generated/graphql'
import { OutOfCreditsModal } from './OutOfCreditsModal'
import { SubscriptionNeededModal } from './SubscriptionNeededModal'
import { SubscriptionUpgradeNeededModal } from './SubscriptionUpgradeNeededModal'

interface ISubscriptionValidityContext {
  openedSubscriptionWarningType: OpenedSubscriptionWarningType | null
  openSubscriptionWarningType: (
    type: OpenedSubscriptionWarningType | null,
    minimumPlan?: SubscriptionPlan
  ) => void
}

interface SubscriptionValidityProps {
  children: React.ReactNode
}

export enum OpenedSubscriptionWarningType {
  OutOfCredits,
  SubscriptionNeeded,
  UpgradeNeeded,
}

export interface ISubscriptionValidityData {
  openedSubscriptionWarningType: OpenedSubscriptionWarningType | null
  minimumPlan: SubscriptionPlan | null
}

const INITIAL_SUBSCRIPTION_VALIDITY: ISubscriptionValidityData = {
  openedSubscriptionWarningType: null,
  minimumPlan: null,
}

export const SubscriptionValidityContext = React.createContext<ISubscriptionValidityContext>({
  openedSubscriptionWarningType: null,
  openSubscriptionWarningType: () => {},
})

export const useSubscriptionValidityContext = () => React.useContext(SubscriptionValidityContext)

export const SubscriptionValidityProvider = ({ children }: SubscriptionValidityProps) => {
  const [state, setState] = React.useState(INITIAL_SUBSCRIPTION_VALIDITY)

  const contextValue: ISubscriptionValidityContext = React.useMemo(
    () => ({
      openedSubscriptionWarningType: state.openedSubscriptionWarningType,
      openSubscriptionWarningType: (type: OpenedSubscriptionWarningType | null, minimumPlan) =>
        setState(() => ({ openedSubscriptionWarningType: type, minimumPlan: minimumPlan ?? null })),
    }),
    [state.openedSubscriptionWarningType]
  )

  return (
    <SubscriptionValidityContext.Provider value={contextValue}>
      {children}
      <OutOfCreditsModal
        isOpen={state.openedSubscriptionWarningType === OpenedSubscriptionWarningType.OutOfCredits}
        onClose={() =>
          setState((prevState) => ({ ...prevState, openedSubscriptionWarningType: null }))
        }
      />
      <SubscriptionNeededModal
        minimumPlan={state.minimumPlan!}
        isOpen={
          state.openedSubscriptionWarningType === OpenedSubscriptionWarningType.SubscriptionNeeded
        }
        onClose={() =>
          setState((prevState) => ({ ...prevState, openedSubscriptionWarningType: null }))
        }
      />
      <SubscriptionUpgradeNeededModal
        minimumPlan={state.minimumPlan!}
        isOpen={state.openedSubscriptionWarningType === OpenedSubscriptionWarningType.UpgradeNeeded}
        onClose={() =>
          setState((prevState) => ({ ...prevState, openedSubscriptionWarningType: null }))
        }
      />
    </SubscriptionValidityContext.Provider>
  )
}
