import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
  ApolloProvider as Provider,
} from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { onError } from '@apollo/client/link/error'
import { uniq } from 'lodash'
import React from 'react'

// import { LocalStorageWrapper, CachePersistor } from 'apollo3-cache-persist'

export const GqlErrorCode = {
  InternalSeverError: 'InternalSeverError',
  Unauthorized: 'Unauthorized',
  NotAuthorized: 'NotAuthorized',
  SignUpEmailExists: 'SignUpEmailExists',
  InvalidSignUpUserName: 'InvalidSignUpUserName',
  LoginWrongCredentials: 'LoginWrongCredentials',
  NotFound: 'NotFound',
  RequestPwdResetEmailNotExists: 'RequestPwdResetEmailNotExists',
  ResetPwdTokenNotExist: 'ResetPwdTokenNotExist',
  ChangePwdWrongCredentials: 'ChangePwdWrongCredentials',
  CommunityPostDoesNotExist: 'CommunityPostDoesNotExist',
  TagAlreadyExists: 'TagAlreadyExists',
  TagDoesNotExist: 'TagDoesNotExist',
}

export interface IErrors {
  graphQlErrors: { [key in keyof typeof GqlErrorCode]: string }
  networkError: string
}

export const ERRORS: IErrors = {
  graphQlErrors: {
    InternalSeverError: 'Internal server error.',
    Unauthorized: 'Unauthorized',
    NotAuthorized: 'NotAuthorized',
    SignUpEmailExists: 'The email already exists',
    InvalidSignUpUserName: 'Invalid username',
    NotFound: 'Content not found.',
    LoginWrongCredentials: 'Invalid email or password',
    RequestPwdResetEmailNotExists: 'The email does not exist',
    ResetPwdTokenNotExist: 'Invalid token',
    ChangePwdWrongCredentials: 'Passwords do not match',
    CommunityPostDoesNotExist: 'Community post does not exist',
    TagAlreadyExists: 'the tag already exists',
    TagDoesNotExist: 'the tag does not exist',
  },
  networkError: 'Connection lost',
}

interface ApolloProviderProps {
  children: React.ReactNode
  beApiUrl: string
  onError: (error: string) => void
  onUnauthError?: () => void
}

export const ApolloProvider: React.FC<ApolloProviderProps> = ({
  beApiUrl,
  children,
  onError: handleError,
  onUnauthError,
}) => {
  const [client, setClient] = React.useState<ApolloClient<NormalizedCacheObject>>()
  // const [, setPersistor] = React.useState<CachePersistor<NormalizedCacheObject>>()

  React.useEffect(() => {
    function init() {
      const errorLink = onError(({ graphQLErrors }) => {
        if (graphQLErrors) {
          uniq(graphQLErrors.map(({ message }) => message)).map((message) =>
            handleError(
              ERRORS.graphQlErrors[message as keyof IErrors['graphQlErrors']] ?? 'Server error'
            )
          )

          const isUserUnauthorized = graphQLErrors.some(
            ({ message }) => message === ERRORS.graphQlErrors.Unauthorized
          )
          if (isUserUnauthorized) {
            onUnauthError?.()
          }
        }
      })

      const httpLink = new BatchHttpLink({
        uri: beApiUrl,
        credentials: 'include',
        batchInterval: 20,
        batchMax: 50,
        batchDebounce: true,
      })

      const link = ApolloLink.from([errorLink, httpLink])
      const cache = new InMemoryCache()
      // const newPersistor = new CachePersistor({
      //   cache,
      //   storage: new LocalStorageWrapper(window.localStorage),
      //   debug: true,
      //   trigger: 'write',
      // })
      // await newPersistor.restore()
      const newClient = new ApolloClient({
        cache,
        link,
        defaultOptions: {
          watchQuery: {
            fetchPolicy: 'cache-and-network',
            nextFetchPolicy: 'cache-and-network',
          },
        },
      })

      // TODO - do caching properly
      // setInterval(() => {
      //   void newPersistor.purge()
      // }, 5 * 60 * 1000) // every 5 mins, remove cache

      // setPersistor(newPersistor)
      setClient(newClient)
    }

    // eslint-disable-next-line no-console
    init() // .catch(console.error)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!client) {
    return null
  }

  return <Provider client={client}>{children}</Provider>
}
