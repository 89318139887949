import { Button, Modal, Stack, Text, Title, getDefaultZIndex, useMantineTheme } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import * as React from 'react'

import { SubscriptionPlan } from '../../../generated/graphql'
import { PricingModal } from '../../components/Pricing/PricingModal'
import { SUBSCRIPTION_PLANS } from '../../components/Pricing/constants'

interface Props {
  minimumPlan: SubscriptionPlan
  isOpen: boolean
  onClose: () => void
}

export const SubscriptionUpgradeNeededModal: React.FC<Props> = ({
  minimumPlan,
  isOpen,
  onClose,
}) => {
  const theme = useMantineTheme()
  const [opened, { open, close }] = useDisclosure(false)
  const plans = SUBSCRIPTION_PLANS.slice(SUBSCRIPTION_PLANS.indexOf(minimumPlan))

  return (
    <>
      <Modal
        opened={isOpen}
        size="auto"
        onClose={() => {
          onClose?.()
        }}
        title={<Text size="xl">Insufficient plan</Text>}
        overlayProps={{
          color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[1],
          opacity: 0.55,
          blur: 3,
        }}
        zIndex={getDefaultZIndex('max')}
      >
        <Stack>
          <Title order={4}>This feature is not available in your current plan.</Title>
          <Text>
            To use this feature upgrade your plan. You have to upgrate to {plans.join(' or ')}
          </Text>
          <Button
            variant="light"
            fullWidth
            color="teal"
            onClick={() => {
              open()
              onClose()
            }}
          >
            Choose plan
          </Button>
        </Stack>
      </Modal>
      <PricingModal plans={plans} isOpen={opened} onClose={close} />
    </>
  )
}
