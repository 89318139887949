import { Button, Card, Group, Stack, Text, Title, createStyles } from '@mantine/core'

import {
  ExtraCreditPlan,
  StripeProductsQuery,
  useStripeProductsQuery,
} from '../../../generated/graphql'
import { useUserSubscription } from '../../utils/useUserSubscription'
import { EXTRA_CREDIT_PLANS } from './constants'

const useStyles = createStyles((theme) => ({
  card: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
  },
}))

export function CreditPackage({
  product,
}: {
  product: StripeProductsQuery['stripeProducts'][number]
}) {
  const { classes } = useStyles()
  const userSubscription = useUserSubscription()
  const { credits } = (product?.metadata ? JSON.parse(product.metadata) : {}) as {
    credits: string
  }
  const stripePrice = product.StripePrices[0]
  return (
    <Card withBorder radius="md" className={classes.card} p="xs">
      <Group noWrap position="apart">
        <Stack spacing="0">
          <Text transform="uppercase" color="dimmed" weight={700} size="xs">
            Credits
          </Text>
          <Title order={3}>{Number(credits).toLocaleString()}</Title>
        </Stack>
        <Button
          onClick={() => userSubscription.handleCreateCheckoutSession(stripePrice.id)}
          loading={userSubscription.isGqlLoading}
        >
          Buy - $
          {((stripePrice.unitAmount ?? 0) / 100).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </Button>
      </Group>
    </Card>
  )
}

export function ExtraCreditPackages() {
  const productsQuery = useStripeProductsQuery()

  return (
    <Stack spacing="xs">
      <Title order={2}>Buy extra credit</Title>
      {EXTRA_CREDIT_PLANS.map((extraCreditPlan) => {
        const product = productsQuery.data?.stripeProducts.find(({ metadata }) => {
          const { plan } = (metadata ? JSON.parse(metadata) : {}) as { plan: ExtraCreditPlan }
          return plan === extraCreditPlan
        })

        return product && <CreditPackage key={extraCreditPlan} product={product} />
      })}
    </Stack>
  )
}
