export enum EmailTemplateId {
  SignIn = 'signin',
  ResetPassword = 'reset_password',
  EmailUpdate = 'emailupdate',
}

export const RoutesMap = {
  home: '/',
  dashboard: '/dashboard',
  characters: '/characters',
  conversations: '/conversations',
  conversation: '/conversation',
  matches: '/matches',
  requestPasswordReset: '/request-password-reset',
  onboarding: '/onboarding',
  passwordSignUp: '/password-sign-up',
  profile: '/profile',
  community: '/community',
  hashtags: '/hashtags',
  embedAdmin: '/embed-admin',
  embed: '/embed',
  bookmarkedMessages: '/bookmarked-messages',
  documents: '/documents',
  surveys: '/surveys',
}

export const DataLimit = {
  characters: 20,
  conversations: 20,
  conversationMessages: 20,
  users: 20,
  community: 20,
  comments: 20,
  hashtags: 20,
  characterImages: 20,
  surveySubmits: 20,
}

const getSystemCharacters = () => {
  if (process.env.NEXT_PUBLIC_APP_ENV === 'production') {
    return {
      homePage: 'f0b20721-38f8-4320-9db9-899b5c3142f8',
      general: '6321669f-bce0-480c-b16a-ce43925ea2de',
      aida: '22d00f36-73da-4f84-ba39-83bd27835f8d',
      mailReply: 'ee05c732-bcbf-4f27-a9ca-a45665a5414e',
      mailWrite: '4c9667ef-a802-4f22-927e-37287727f650',
      explainText: 'f5ca1920-3442-4185-abd4-8e877c3d2834',
      explainTextLike5: '7a2ad8f4-a930-462d-b09b-002779bc304b',
      summary: 'e033169d-4f89-4614-bc59-811551e2616d',
      paraphrase: '65bb717a-972e-42d8-9743-2a9f559ef6ea',
      essay: '8431840a-bddb-458a-8d1a-b90633bcb99f',
      ideas: 'f249368e-0db2-4ad0-9983-3528aa9eb05b',
      expandText: 'e9c03d1d-c6d9-4282-872e-fe4f64e685ec',
      shortText: '944b20b0-bc9b-4689-97cd-f8ac8c70ed29',
      creativeContent: '6a4c8d87-8dae-487c-b632-855e15a57329',
    }
  }
  if (process.env.NEXT_PUBLIC_APP_ENV === 'staging') {
    return {
      homePage: '5fd08046-2676-4732-9560-47ccc02a80c8',
      general: '33871c74-a84c-44f0-9c91-1d04b943bccb',
      aida: 'bc4fe3b9-22ce-4cff-9af2-a808961dd6be',
      mailReply: '76f0db71-9bf4-47de-a896-0141e73a3a48',
      mailWrite: '131746fc-5d55-4259-8797-72890fb4d620',
      explainText: 'cdbe0454-8f70-4ad6-a3e0-0177039045ec',
      explainTextLike5: '9958ed75-2b64-4efd-bd65-9dbe326128ad',
      summary: '0c146a44-3a01-403c-b76f-41a05db8dd70',
      paraphrase: 'd91a0807-23fb-44cc-939d-c697d055ffe6',
      essay: '519786a7-4bed-49b7-b94e-6b76e2f5e00f',
      ideas: '4e6acc9c-026b-4fad-90f8-a36942407314',
      expandText: '9a9f8d02-2bd6-4323-bc4a-9252ff67c50b',
      shortText: '77e903d4-b805-4665-a99c-fd5edc8cec2b',
      creativeContent: '0d37462a-d734-42ed-9cf7-6d02b0ff22f9',
    }
  }

  return {
    homePage: '76f90f6f-c7b2-4bce-9d8f-ba323bc89903',
    general: '4c68493b-2a24-42f6-975f-44a838823fa5',
    aida: '4c68493b-2a24-42f6-975f-44a838823fa5',
    mailReply: '4c68493b-2a24-42f6-975f-44a838823fa5',
    mailWrite: '4c68493b-2a24-42f6-975f-44a838823fa5',
    explainText: '4c68493b-2a24-42f6-975f-44a838823fa5',
    explainTextLike5: '4c68493b-2a24-42f6-975f-44a838823fa5',
    summary: '4c68493b-2a24-42f6-975f-44a838823fa5',
    paraphrase: '4c68493b-2a24-42f6-975f-44a838823fa5',
    essay: '4c68493b-2a24-42f6-975f-44a838823fa5',
    ideas: '4c68493b-2a24-42f6-975f-44a838823fa5',
    expandText: '4c68493b-2a24-42f6-975f-44a838823fa5',
    shortText: '4c68493b-2a24-42f6-975f-44a838823fa5',
    creativeContent: '76f90f6f-c7b2-4bce-9d8f-ba323bc89903',
  }
}
export const SYSTEM_CHARACTER = getSystemCharacters()
