import { Box, Button, Card, Group, List, Stack, Text, ThemeIcon, Title } from '@mantine/core'
import { IconCircleCheck, IconCircleMinus } from '@tabler/icons-react'
import * as React from 'react'

import { StripeProductsQuery, SubscriptionPlan } from '../../../generated/graphql'
import { useUserSubscription } from '../../utils/useUserSubscription'
import { SUBSCRIPTION_PLANS } from './constants'

interface Props {
  subscriptionPlan: SubscriptionPlan
  product: StripeProductsQuery['stripeProducts'][number]
}

export const SubscriptionFeaturesList: React.FC<Pick<Props, 'subscriptionPlan'>> = ({
  subscriptionPlan,
}) => {
  if (subscriptionPlan === SubscriptionPlan.Basic) {
    return (
      <List
        spacing="xs"
        size="sm"
        center
        icon={
          <ThemeIcon color="teal" size={24} radius="xl">
            <IconCircleCheck size="1rem" />
          </ThemeIcon>
        }
      >
        <List.Item>All public AI characters</List.Item>
        <List.Item>Bookmarked messages</List.Item>
        <List.Item>15,000 credits</List.Item>
        <List.Item
          icon={
            <ThemeIcon color="gray" size={24} radius="xl">
              <IconCircleMinus size="1rem" />
            </ThemeIcon>
          }
        >
          Create own characters
        </List.Item>
        <List.Item
          icon={
            <ThemeIcon color="gray" size={24} radius="xl">
              <IconCircleMinus size="1rem" />
            </ThemeIcon>
          }
        >
          Documents
        </List.Item>
        <List.Item
          icon={
            <ThemeIcon color="gray" size={24} radius="xl">
              <IconCircleMinus size="1rem" />
            </ThemeIcon>
          }
        >
          Custom OpenAI token
        </List.Item>
        <List.Item
          icon={
            <ThemeIcon color="gray" size={24} radius="xl">
              <IconCircleMinus size="1rem" />
            </ThemeIcon>
          }
        >
          New coming features
        </List.Item>
      </List>
    )
  }
  if (subscriptionPlan === SubscriptionPlan.Premium) {
    return (
      <List
        spacing="xs"
        size="sm"
        center
        icon={
          <ThemeIcon color="teal" size={24} radius="xl">
            <IconCircleCheck size="1rem" />
          </ThemeIcon>
        }
      >
        <List.Item>All public AI characters</List.Item>
        <List.Item>Bookmarked messages</List.Item>
        <List.Item>50,000 credits</List.Item>
        <List.Item>Create own characters</List.Item>
        <List.Item>Documents</List.Item>
        <List.Item
          icon={
            <ThemeIcon color="gray" size={24} radius="xl">
              <IconCircleMinus size="1rem" />
            </ThemeIcon>
          }
        >
          Custom OpenAI token
        </List.Item>
        <List.Item
          icon={
            <ThemeIcon color="gray" size={24} radius="xl">
              <IconCircleMinus size="1rem" />
            </ThemeIcon>
          }
        >
          New coming features
        </List.Item>
      </List>
    )
  }
  if (subscriptionPlan === SubscriptionPlan.Pro) {
    return (
      <List
        spacing="xs"
        size="sm"
        center
        icon={
          <ThemeIcon color="teal" size={24} radius="xl">
            <IconCircleCheck size="1rem" />
          </ThemeIcon>
        }
      >
        <List.Item>All public AI characters</List.Item>
        <List.Item>Bookmarked messages</List.Item>
        <List.Item>
          <strong>750,000</strong> credits or <strong>unlimited</strong> with your own OpenAI token
        </List.Item>
        <List.Item>Create own characters</List.Item>
        <List.Item>Documents</List.Item>
        <List.Item>Custom OpenAI token</List.Item>
        <List.Item>New coming features</List.Item>
      </List>
    )
  }

  return null
}
export const PricingCard: React.FC<Props> = ({ subscriptionPlan, product }) => {
  const userSubscription = useUserSubscription()

  const [price] = product.StripePrices
  const currentPlanIndex = SUBSCRIPTION_PLANS.findIndex((plan) => plan === subscriptionPlan)

  const actionButton = React.useMemo(() => {
    const {
      isGqlLoading,
      isActiveTrial,
      isSubscriptionActive,
      cancelAtDate,
      canUpdateSubscription,
      handleUpgradeSubscription,
      handleCancelSubscription,
      handleCreateCheckoutSession,
      userPlanIndex,
    } = userSubscription
    let label = ''
    let confirm = ''
    let color = 'green'
    let onClick: ((priceId: string) => void | Promise<void>) | undefined

    if (isActiveTrial || !isSubscriptionActive || !canUpdateSubscription) {
      label = 'Buy'
      color = 'green'
      onClick = handleCreateCheckoutSession
    } else if (currentPlanIndex < userPlanIndex) {
      label = 'Downgrade'
      confirm = 'Are you sure you want to downgrade your plan?'
      color = 'orange'
      onClick = handleUpgradeSubscription
    } else if (currentPlanIndex > userPlanIndex) {
      label = 'Upgrade'
      confirm = 'Are you sure you want to upgrade your plan?'
      color = 'green'
      onClick = handleUpgradeSubscription
    } else if (currentPlanIndex === userPlanIndex) {
      label = cancelAtDate ? 'Renew subscription' : 'Cancel subscription'
      confirm = cancelAtDate ? '' : 'Are you sure you want to cancel your subscription?'
      onClick = cancelAtDate ? handleUpgradeSubscription : handleCancelSubscription
      color = cancelAtDate ? 'green' : 'red'
    }

    return (
      <Button
        loading={isGqlLoading}
        variant="light"
        color={color}
        fullWidth
        mt="md"
        radius="md"
        disabled={!onClick}
        onClick={() => {
          if (!confirm || window.confirm(confirm)) {
            void onClick?.(price.id)
          }
        }}
      >
        {label}
      </Button>
    )
  }, [userSubscription, currentPlanIndex, price.id])

  return (
    <Box sx={{ position: 'relative' }}>
      <Card shadow="sm" padding="md" radius="md" withBorder maw="16rem">
        <Stack spacing="0.25rem">
          <Title order={3}>
            {subscriptionPlan === SubscriptionPlan.Basic && '🧑‍💻'}
            {subscriptionPlan === SubscriptionPlan.Premium && '😎'}
            {subscriptionPlan === SubscriptionPlan.Pro && '🚀'} {product.name}
          </Title>
          {/*<Text>{product.description}</Text>*/}
          <Group align="flex-end" spacing="0.25rem">
            <Title order={1}>
              <Text component="span" color="dimmed">
                $
              </Text>
              {((price?.unitAmount ?? 0) / 100).toFixed(2)}
            </Title>
            <Text color="dimmed">/ month</Text>
          </Group>
        </Stack>

        <SubscriptionFeaturesList subscriptionPlan={subscriptionPlan} />

        {actionButton}
      </Card>
    </Box>
  )
}
