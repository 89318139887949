import { Button, Modal, Stack, Text, TextInput } from '@mantine/core'
import React from 'react'

import { useSetUserOpenAiTokenMutation } from '../../generated/graphql'
import { useAuth } from './auth'
import { useClaimPublicConversations } from './usePublicConversationsWithLocalStorage'

export const OpenAiTokenModal: React.FC = () => {
  const [token, setToken] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const [hasToken, setHasToken] = React.useState(true)
  const {
    data: { User },
  } = useAuth()

  useClaimPublicConversations()

  const [setUserOpenAiTokenMutation] = useSetUserOpenAiTokenMutation({
    refetchQueries: 'active',
    awaitRefetchQueries: true,
  })

  const submitToken = React.useCallback(async () => {
    setIsLoading(true)
    try {
      await setUserOpenAiTokenMutation({ variables: { id: User.id!, token } })
    } finally {
      setIsLoading(false)
    }
  }, [token, User.id, setUserOpenAiTokenMutation])

  const { isLoggedIn } = useAuth()

  React.useEffect(() => {
    if (isLoggedIn) {
      setHasToken(!!User.openAIToken)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [User.openAIToken])

  return null
  return (
    <Modal opened={!hasToken} onClose={() => {}} title={<Text fz="xl">OpenAI token</Text>} centered>
      <Stack>
        <Text>In order to use the app we need your OpenAI token.</Text>

        <TextInput
          placeholder="Add token here"
          value={token}
          onChange={(event) => setToken(event.currentTarget.value)}
        />

        <Button radius="md" onClick={submitToken} loading={isLoading}>
          Save token
        </Button>
      </Stack>
    </Modal>
  )
}
