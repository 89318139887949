import { Carousel } from '@mantine/carousel'
import { useMantineTheme } from '@mantine/core'
import { useMediaQuery } from '@mantine/hooks'
import * as React from 'react'

import { SubscriptionPlan, useStripeProductsQuery } from '../../../generated/graphql'
import { PricingCard } from './PricingCard'
import { SUBSCRIPTION_PLANS } from './constants'

interface Props {
  plans?: SubscriptionPlan[]
}

export const Pricing: React.FC<Props> = ({ plans = SUBSCRIPTION_PLANS }) => {
  const theme = useMantineTheme()
  const productsQuery = useStripeProductsQuery()
  const isLtXs = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`)

  return (
    <Carousel slideSize="16rem" align={isLtXs ? undefined : 'start'} mx="auto" withControls={false}>
      {plans.map((subscriptionPlan, index) => {
        const product = productsQuery.data?.stripeProducts.find(({ metadata }) => {
          const { plan } = (metadata ? JSON.parse(metadata) : {}) as { plan: SubscriptionPlan }
          return plan === subscriptionPlan
        })
        return (
          <Carousel.Slide
            key={subscriptionPlan}
            sx={index === SUBSCRIPTION_PLANS.length - 1 ? undefined : { marginRight: '1rem' }}
          >
            {product && <PricingCard subscriptionPlan={subscriptionPlan} product={product} />}
          </Carousel.Slide>
        )
      })}
    </Carousel>
  )
}
