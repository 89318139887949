import { isBefore, parseISO } from 'date-fns'

import {
  useCancelStripeSubscriptionMutation,
  useCreateStripeCheckoutSessionMutation,
  useGenerateStripePortalLinkLazyQuery,
  useUpgradeStripeSubscriptionMutation,
} from '../../generated/graphql'
import { SUBSCRIPTION_PLANS } from '../components/Pricing/constants'
import { IAuthData, useAuth } from './auth'
import { getStripe } from './stripe-client'

const getCurrentSubscriptionPlan = (subscription: IAuthData['User']['Subscription']) => {
  const isBeforeCurrentPeriodEnd =
    !!subscription?.currentPeriodEnd &&
    isBefore(new Date(), parseISO(subscription.currentPeriodEnd))

  if (!isBeforeCurrentPeriodEnd) {
    return null
  }

  return subscription?.plan ?? null
}
export function useUserSubscription() {
  const {
    data: { User },
  } = useAuth()
  const { Subscription } = User

  const [createStripeCheckoutSessionMutation, createStripeCheckoutSessionMutationData] =
    useCreateStripeCheckoutSessionMutation()
  const [upgradeStripeSubscriptionMutation, upgradeStripeSubscriptionMutationData] =
    useUpgradeStripeSubscriptionMutation({
      refetchQueries: 'active',
      awaitRefetchQueries: true,
    })
  const [cancelStripeSubscriptionMutation, cancelStripeSubscriptionMutationData] =
    useCancelStripeSubscriptionMutation({
      refetchQueries: 'active',
      awaitRefetchQueries: true,
    })
  const [generateStripePortalLinkLazyQuery, generateStripePortalLinkData] =
    useGenerateStripePortalLinkLazyQuery()
  const generateStripePortalLink = async () => {
    const { data } = await generateStripePortalLinkLazyQuery()
    if (data?.generateStripePortalLink) {
      window.location.assign(data?.generateStripePortalLink)
    }
  }

  const isSubscriptionActive = Subscription?.status === 'active'

  // const cancelDate = User.Subscription.can
  const isGqlLoading =
    cancelStripeSubscriptionMutationData.loading ||
    upgradeStripeSubscriptionMutationData.loading ||
    createStripeCheckoutSessionMutationData.loading ||
    generateStripePortalLinkData.loading

  const isActiveTrial = !Subscription?.StripeProduct && isSubscriptionActive

  const userPlanIndex = SUBSCRIPTION_PLANS.findIndex((plan) => plan === Subscription?.plan)

  const cancelDateString =
    Subscription?.endedAt ?? Subscription?.cancelAt ?? Subscription?.canceledAt
  const cancelAtDate = Subscription?.cancelAt ? parseISO(Subscription?.cancelAt) : null
  const currentPeriodEndDate =
    Subscription?.currentPeriodEnd && isSubscriptionActive
      ? parseISO(Subscription.currentPeriodEnd)
      : null

  const canUpdateSubscription =
    !cancelDateString || isBefore(new Date(), parseISO(cancelDateString))
  const canUpgradeSubscription = !!cancelDateString || SUBSCRIPTION_PLANS.length - 1 > userPlanIndex

  const handleCreateCheckoutSession = async (priceId: string) => {
    const { data } = await createStripeCheckoutSessionMutation({
      variables: {
        userId: User.id!,
        priceId: priceId,
      },
    })

    const sessionId = data?.createStripeCheckoutSession

    if (sessionId) {
      const stripe = await getStripe()
      void stripe?.redirectToCheckout({ sessionId })
    }
  }

  const handleUpgradeSubscription = (priceId: string) => {
    void upgradeStripeSubscriptionMutation({
      variables: {
        userId: User.id!,
        priceId: priceId,
      },
    })
  }
  const handleCancelSubscription = () => {
    void cancelStripeSubscriptionMutation({
      variables: {
        userId: User.id!,
      },
    })
  }

  return {
    credits: Subscription?.credits ?? 0,
    isActiveTrial,
    userPlanIndex,
    isGqlLoading,
    isSubscriptionActive,
    cancelAtDate,
    canUpgradeSubscription,
    canUpdateSubscription,
    currentPeriodEndDate,
    Subscription,
    currentSubscriptionPlan: getCurrentSubscriptionPlan(Subscription),
    handleCreateCheckoutSession,
    handleUpgradeSubscription,
    handleCancelSubscription,
    generateStripePortalLink,
  }
}
