import { Box, Modal, Text, getDefaultZIndex, useMantineTheme } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import * as React from 'react'

import { SubscriptionPlan } from '../../../generated/graphql'
import { Pricing } from './Pricing'

interface Props {
  children: React.ReactElement<React.DOMAttributes<HTMLButtonElement>>
  isOpen: boolean
  plans?: SubscriptionPlan[]
  onOpen: () => void
  onClose: () => void
}

export const PricingModal: React.FC<Pick<Props, 'isOpen' | 'onClose' | 'plans'>> = ({
  plans,
  isOpen,
  onClose,
}) => {
  const theme = useMantineTheme()

  return (
    <Modal
      opened={isOpen}
      size="auto"
      onClose={onClose}
      title={<Text size="xl">Subscription plans</Text>}
      overlayProps={{
        color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[1],
        opacity: 0.55,
        blur: 3,
      }}
      zIndex={getDefaultZIndex('max')}
    >
      <Box mt="md">
        <Pricing plans={plans} />
      </Box>
    </Modal>
  )
}

export const PricingModalWithButton: React.FC<Partial<Props> & Pick<Props, 'children'>> = ({
  children,
  plans,
  onOpen,
  onClose,
}) => {
  const theme = useMantineTheme()
  const [opened, { open, close }] = useDisclosure(false)

  return (
    <>
      {React.cloneElement<React.DOMAttributes<HTMLButtonElement>>(children, {
        onClick: () => {
          onOpen?.()
          open()
        },
      })}
      <Modal
        opened={opened}
        size="auto"
        onClose={() => {
          onClose?.()
          close()
        }}
        title={<Text size="xl">Subscription plans</Text>}
        overlayProps={{
          color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[1],
          opacity: 0.55,
          blur: 3,
        }}
      >
        <Box mt="md">
          <Pricing plans={plans} />
        </Box>
      </Modal>
    </>
  )
}
