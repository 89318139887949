import { ExtraCreditPlan, SubscriptionPlan } from '../../../generated/graphql'

export const SUBSCRIPTION_PLANS: SubscriptionPlan[] = [
  SubscriptionPlan.Basic,
  SubscriptionPlan.Premium,
  SubscriptionPlan.Pro,
]
export const EXTRA_CREDIT_PLANS: ExtraCreditPlan[] = [
  ExtraCreditPlan.Small,
  ExtraCreditPlan.Medium,
  ExtraCreditPlan.Large,
]
