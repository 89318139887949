import { ColorScheme, ColorSchemeProvider, MantineProvider, getDefaultZIndex } from '@mantine/core'
import { useHotkeys, useLocalStorage } from '@mantine/hooks'
import { Notifications, notifications } from '@mantine/notifications'
import { useRouter } from 'next/router'
import React from 'react'

import { RoutesMap } from '../constants'
import { ApolloProvider } from './ApolloProvider'
import { OpenAiTokenModal } from './OpenAiTokenModal'
import { SubscriptionValidityProvider } from './SubscriptionValidity'
import { AuthProvider } from './auth'

interface Props {
  children: React.ReactNode
}

export const AppProviders: React.FC<Props> = ({ children }) => {
  const router = useRouter()
  const [lastForcedUnauthRender, setLastForcedUnauthRender] = React.useState(Date.now())

  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'mantine-color-scheme',
    defaultValue: 'light',
    getInitialValueInEffect: true,
  })

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'))

  useHotkeys([['mod+J', () => toggleColorScheme()]])

  return (
    <ApolloProvider
      onError={(error) => notifications.show({ message: error, color: 'red' })}
      onUnauthError={() => {
        // TODO
        void router.replace(RoutesMap.home)
        setLastForcedUnauthRender(Date.now())
      }}
      key={lastForcedUnauthRender}
      beApiUrl={`${process.env.NEXT_PUBLIC_APP_URL}/api/graphql`}
    >
      <AuthProvider>
        <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
          <MantineProvider
            theme={{ colorScheme, fontFamily: `'Roboto', sans-serif` }}
            withGlobalStyles
            withNormalizeCSS
          >
            <SubscriptionValidityProvider>{children}</SubscriptionValidityProvider>
            <Notifications position="top-right" zIndex={getDefaultZIndex('max')} />
            <OpenAiTokenModal />
          </MantineProvider>
        </ColorSchemeProvider>
      </AuthProvider>
    </ApolloProvider>
  )
}
