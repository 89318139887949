import React from 'react'

import { useClaimConversationsMutation } from '../../generated/graphql'
import { useAuth } from './auth'

const CONVERSATIONS_LOCAL_STORAGE_KEY = 'noLimitAiPublicConversationIds'

const getConversationIds = () => {
  let conversationIds: string[] = []
  const conversationIdsJson = window.localStorage.getItem(CONVERSATIONS_LOCAL_STORAGE_KEY)
  try {
    conversationIds = (JSON.parse(conversationIdsJson ?? '') ?? []) as string[]
  } catch {}

  return conversationIds
}
export function useStorePublicConversation({ id }: { id?: string }) {
  const {
    data: { User },
  } = useAuth()

  React.useEffect(() => {
    if (id && !User.id) {
      window.localStorage.setItem(
        CONVERSATIONS_LOCAL_STORAGE_KEY,
        JSON.stringify([...getConversationIds(), id])
      )
    }
  }, [id, User.id])
}

export function useClaimPublicConversations() {
  const {
    data: { User },
  } = useAuth()

  const [claimConversationsMutation] = useClaimConversationsMutation()

  React.useEffect(() => {
    const conversationIds = getConversationIds()
    if (User.id && conversationIds.length > 0) {
      void claimConversationsMutation({
        variables: {
          inputData: {
            conversationIds,
            userId: User.id,
          },
        },
        refetchQueries: 'active',
      })
      window.localStorage.setItem(CONVERSATIONS_LOCAL_STORAGE_KEY, JSON.stringify([]))
    }
  }, [User.id, claimConversationsMutation])
}
