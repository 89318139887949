import { Button, Modal, Stack, Text, Title, getDefaultZIndex, useMantineTheme } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import * as React from 'react'

import { ExtraCreditPackages } from '../../components/Pricing/ExtraCreditPackages'
import { PricingModal } from '../../components/Pricing/PricingModal'
import { useUserSubscription } from '../../utils/useUserSubscription'

interface Props {
  isOpen: boolean
  onClose: () => void
}

export const OutOfCreditsModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { isActiveTrial } = useUserSubscription()
  const theme = useMantineTheme()
  const [opened, { open, close }] = useDisclosure(false)

  return (
    <>
      <Modal
        opened={isOpen}
        size="auto"
        onClose={() => {
          onClose?.()
        }}
        title={<Text size="xl">Insufficient credit</Text>}
        overlayProps={{
          color: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[1],
          opacity: 0.55,
          blur: 3,
        }}
        zIndex={getDefaultZIndex('max')}
      >
        {isActiveTrial ? (
          <Stack>
            <Title order={4}>You ran out of credits.</Title>
            <Text>To continue using the app you need to have subscription plan</Text>
            <Button variant="light" fullWidth color="teal" onClick={open}>
              Choose plan
            </Button>
          </Stack>
        ) : (
          <Stack>
            <Title order={4}>You ran out of credits.</Title>
            <Text>To continue using the app you need to buy extra credits</Text>
            <ExtraCreditPackages />
          </Stack>
        )}
      </Modal>
      <PricingModal isOpen={opened} onClose={close} />
    </>
  )
}
