import * as React from 'react'

import { SubscriptionPlan } from '../../../generated/graphql'
import { useAuth } from '../auth'
import { useUserSubscription } from '../useUserSubscription'
import {
  OpenedSubscriptionWarningType,
  useSubscriptionValidityContext,
} from './SubscriptionValidityProvider'

export function useSubscriptionValidity() {
  const {
    data: { User },
  } = useAuth()
  const userSubscription = useUserSubscription()
  const subscriptionValidityContext = useSubscriptionValidityContext()

  const { currentSubscriptionPlan, credits } = userSubscription

  const validate = React.useCallback(
    ({
      creditsNeeded = false,
      plansAllowed = [],
    }: {
      creditsNeeded?: boolean
      plansAllowed?: SubscriptionPlan[]
    }) => {
      if (plansAllowed.length > 0) {
        if (currentSubscriptionPlan === null) {
          subscriptionValidityContext.openSubscriptionWarningType(
            OpenedSubscriptionWarningType.SubscriptionNeeded,
            plansAllowed[0]
          )
          return false
        } else if (!plansAllowed.includes(currentSubscriptionPlan)) {
          subscriptionValidityContext.openSubscriptionWarningType(
            OpenedSubscriptionWarningType.UpgradeNeeded,
            plansAllowed[0]
          )
          return false
        }
      }

      if (creditsNeeded && credits <= 0 && !User.openAIToken) {
        subscriptionValidityContext.openSubscriptionWarningType(
          OpenedSubscriptionWarningType.OutOfCredits
        )
        return false
      }

      return true
    },
    [currentSubscriptionPlan, credits, subscriptionValidityContext, User.openAIToken]
  )

  const value = React.useMemo(() => ({ validate }), [validate])

  return value
}
